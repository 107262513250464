import React from "react";
import { BsFillBookmarkFill } from "react-icons/bs";
// Funcao para formatar data do Pedido:

export const getFormatData = (data) => {
	// Aqui, separamos em strings onde possui o caracter -, obtendo ("YYYY", "MM", "DD")
	//    Depois revertemos a ordem, obtendo ("DD", "MM", "YYYY") e depois juntamos as strings adicionando /
	data = data.split('-').reverse().join('/');
	return data
}


export const getStatus = (status) => {
	if (status === 0) {
		return  <>
			<BsFillBookmarkFill style={{color: '#e5e619', marginRight: '10px'}}/>
				Em Orçamento
			</> 
      
	} else if (status === 1) {
		return  <>
			<BsFillBookmarkFill style={{color: '#dfe152', marginRight: '10px'}}/>
				Aguardando Aprovação
			</>  
	} else if (status === 2) {
      return  <>
				<BsFillBookmarkFill style={{color: '#fb8c00', marginRight: '10px'}}/>
					Aguardando envio
			</>  
	} else if (status === 3) {
		return  <>
			<BsFillBookmarkFill style={{color: '#ec407a', marginRight: '10px'}}/>
				Em processamento
		</> 
	} else if (status === 4) {
		return  <>
			<BsFillBookmarkFill style={{color: '#1a73e8', marginRight: '10px'}}/>
				Em lote
		</>   
	} else if (status === 5) {
		return  <>
			<BsFillBookmarkFill style={{color: '#4caf50', marginRight: '10px'}}/>
				Finalizado
		</>
	} else if (status === 6) {
		return  <>
			<BsFillBookmarkFill style={{color: 'red', marginRight: '10px'}}/>
				Cancelado
		</>
	} else if (status === 7) {
		return  <>
			<BsFillBookmarkFill style={{color: 'yellow', marginRight: '10px'}}/>
				Aguardando Informações
		</>
    }
  }