import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBIcon
} from 'mdbreact'
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';

import GruposService from '../../../services/produtos/grupos/grupos';
import FamiliasService from '../../../services/produtos/familias/familias';
import { GetAll, GetAllPrincipalComponent } from '../../GetAll';

import Table from './Table';
import SubGruposService from '../../../services/produtos/subgrupos/subgrupos'

import Masks from "../../Masks/Masks";

import ReactLoading from 'react-loading';

import '../styles.css';

export default function CrudSubGrupos() {
  const [isLoading, setIsLoading] = useState(true)
  const familiasService = new FamiliasService()
  const gruposService = new GruposService()
  const subGruposService = new SubGruposService()
  const mask = new Masks()
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [modalCreateGrupo, setModalCreateGrupo] = useState(false)
  const [modalCreateFamilia, setModalCreateFamilia] = useState(false)
  const [subGrupos, setSubGrupos] = useState([])
  const [subGrupo, setSubGrupo] = useState(null)
  const [error, setError] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)

  // Bloco de variáveis para Subgrupo
  const [id, setId] = useState('')
  const [nome, setNome] = useState('') 
  
  // Informações para select da grupo
  const [nomeGrupo, setNomeGrupo] = useState('')
  const [idGrupo, setIdGrupo] = useState('')
  const [grupos, setGrupos] = useState([])
  const showGrupos = grupos.map((data, idx) => {
    
    return (
      <option key={idx} value={data.id}>{data.nome}</option>
    )
  })

  // Informações para select da família
  const [nomeFamilia, setNomeFamilia] = useState('')
  const [idFamilia, setIdFamilia] = useState('')
  const [familias, setFamilias] = useState([])
  const showFamilias = familias.map((data, idx) => {
    // setIdFamilia(data.id)
    return (
      <>
      <option key={idx} value={data.id}>{data.nome}</option>
      </>
    )
  })

  // Toggles
  const toggle = () => { 
    setModal(!modal) 
  }

  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  const toggleCreateGrupo = () => {
    setModalCreateGrupo(!modalCreateGrupo)
  }

  const toggleCreateFamilia = () => {
    setModalCreateFamilia(!modalCreateFamilia)
  }

  // Create Grupo
  const handleSubmitGrupo = () => {
    const grupo = {
      nome: nomeGrupo,
      idFamilia: idFamilia,
    }
    try {
      gruposService.create(grupo)
      .then(res => {
        if (res.status === 200) {
          console.log(res)
          alert("Grupo criado com suceso!!")
          GetAll(setGrupos, setError, gruposService)
          toggleCreateGrupo()
          toggle()
        }
      })
    } catch (erro) {
      console.log(erro)
      setError(erro)
    }
  }


  // Create Familia
  const handleSubmitFamilia = () => {
    const familia = {
      nome: nomeFamilia,
    }
    try {
      familiasService.create(familia)
      .then(res => {
        if (res.status === 200) {
          console.log(res)
          alert("Familia criado com suceso!!")
          GetAll(setFamilias, setError, familiasService)
          toggleCreateGrupo()
          toggleCreateFamilia()
          
        }
      })
    } catch (erro) {
      console.log(erro)
      setError(erro)
    }
  }

  // Create
  const handleSubmit = () => {
    const subGrupo = {
      nome: nome,
      idGrupo: idGrupo,
      nomeGrupo: nomeGrupo,
    }
    try {
      subGruposService.create(subGrupo)
      .then(res => {
        console.log(res)
        alert("SubGrupo criado com suceso!!")
        toggle()
        GetAllPrincipalComponent(setSubGrupos, setError, subGruposService, setIsLoading)
      })
    } catch (erro) {
      console.log(erro)
      setError(erro)
    }
  }
  
  // Update
  const handleUpdate = (subGrupo) => {
    setIsUpdate(true)

    setId(subGrupo.id)
    setNome(subGrupo.nome)
    setIdGrupo(subGrupo.grupo.id)
    setNomeGrupo(subGrupo.grupo.nome)

    toggle()

  }

  // Verificando o update:
  const confirmUpdate = () => {
    const subGrupo = {
      id: id,
      nome: nome,
      idGrupo: idGrupo,
      nomeGrupo: nomeGrupo
    }
    try {
      subGruposService.update(subGrupo.id, subGrupo)
      .then(res => {
        if (res.status === 200) {
          alert("subGrupo atualizado com sucesso!!") 
          toggle()
          GetAllPrincipalComponent(setSubGrupos, setError, subGruposService, setIsLoading)
        }
      })
    } catch(error) {
      console.log(error)
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }
  }

  // Delete:
  const handleDelete = (idSubGrupo) => {
    subGruposService.delete(idSubGrupo)
    .then(res =>{
      console.log(res)
      if (res.status === 200) {
        GetAllPrincipalComponent(setSubGrupos, setError, subGruposService, setIsLoading)
        toggleDelete()
        alert("SubGrupo apagado com sucesso!!")
        }
    })
  }

  const checkValuesSubGrupo = () => {

    if (
      nome=="" || 
      idGrupo=="" ||
      idGrupo=="Selecione o Grupo"
    ) {
      return true
    } else {
      return false
    }
  } 


  const clearState = () => {
    setNome("")
    setIdGrupo("")
    setNomeGrupo("")
  }

  const checkValuesGrupo = () => {

    if (
      nomeGrupo=="" || 
      idFamilia=="" ||
      idFamilia=="Selecione a Família"
    ) {
      return true
    } else {
      return false
    }
  } 


  const clearStateGrupo = () => {
    setNomeGrupo("")
    setIdFamilia("")
    setNomeFamilia("")
  }

  const checkValuesFamilia = () => {

    if (
      nomeFamilia=="" 
    ) {
      return true
    } else {
      return false
    }
  } 


  const clearStateFamilia = () => {
    setIdFamilia("")
    setNomeFamilia("")
  }

  useEffect(() => {
    GetAllPrincipalComponent(setSubGrupos, setError, subGruposService, setIsLoading)
    GetAll(setGrupos, setError, gruposService) 
    GetAll(setFamilias, setError, familiasService)
  }, [])


  return(
    <div>

      <MDBModal 
        isOpen={modal} 
        toggle={toggle}
        disableBackdrop
      >

        <MDBRow center>
          {isUpdate ? (
            <MDBModalHeader className="modal-header">Informações sobre o subgrupo</MDBModalHeader>
          ) : (
            <MDBModalHeader className="modal-header">Cadastre novo subgrupo</MDBModalHeader>
          )}
        </MDBRow>

        <MDBModalBody>
          
          <div className="info-form">
            <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
                <p className="p-form">SubGrupo</p>
              </GridItem>
            </GridContainer>
            
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={12}>
                <MDBInput 
                  label="Nome do SubGrupo *" 
                  type="text" validate 
                  error="wrong" success="right"
                  value={nome}
                  onChange={input => setNome(mask.charAndText(input.target.value))}
                />
              </GridItem>
            </GridContainer>
          </div>

          <div className="info-form">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel shrink className="mt-4 mb-4">
                  Grupo *
                </InputLabel>
                <NativeSelect
                  defaultValue={idGrupo}
                  value={idGrupo}
                  onChange={e => setIdGrupo(e.target.value)}
                >
                  <option>Selecione o Grupo</option>
                  {showGrupos}
                </NativeSelect>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}> 
                <div className="icon-add">
                Novo grupo
                  <MDBIcon className="icon" icon="plus" 
                    onClick={() => { 
                      clearStateGrupo()
                      toggle()
                      toggleCreateGrupo()}}>
                  </MDBIcon>
                </div>
              </GridItem>

            </GridContainer>
          </div>
        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>

          <MDBModalFooter>
            {isUpdate ? (
              <Button disabled={checkValuesSubGrupo()} variant="contained" onClick={confirmUpdate}>
                Atualizar SubGrupo
              </Button>
            ): (        
              <Button disabled={checkValuesSubGrupo()} variant="contained" onClick={handleSubmit}>
                Registrar SubGrupo
              </Button>
            )} 
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>

      {/* Modal de criar grupo */}
      {modalCreateGrupo ? (
        <MDBModal 
          isOpen={modalCreateGrupo}
          toggle={toggleCreateGrupo}
          disableBackdrop
        >
          <MDBModalBody>
            <div className="info-form">
              <GridContainer >
                <GridItem xs={12} sm={12} md={4}>
                  <p className="p-form">Grupo</p>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem className="grid-item" xs={12} sm={12} md={12}>
                  <MDBInput 
                    label="Nome do Grupo *" 
                    type="text" validate 
                    error="wrong" success="right"
                    value={nomeGrupo}
                    onChange={input => setNomeGrupo(mask.charAndText(input.target.value))}
                  />
                </GridItem>
              </GridContainer>
            </div>

            <div className="info-form">

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel shrink className="mt-2 mb-2">
                    Família *
                  </InputLabel>
                  <NativeSelect
                    defaultValue={idFamilia}
                    value={idFamilia}
                    onChange={e => setIdFamilia(e.target.value)}
                  >
                    <option>Selecione a Família</option>
                    {showFamilias}
                  </NativeSelect>
                </GridItem>

                <GridItem xs={12} sm={12} md={6}>
                  <div className="icon-add">
                    Nova Família
                    <MDBIcon className="icon" icon="plus" onClick={() => { 
                      clearStateFamilia()
                      toggleCreateGrupo()
                      toggleCreateFamilia()}} />
                  </div>
                </GridItem>
              </GridContainer>
            </div>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={() => {
                toggleCreateGrupo()
                toggle()}}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>     
              <Button disabled={checkValuesGrupo()} variant="contained" onClick={handleSubmitGrupo}>
                Registrar Grupo
              </Button> 
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>
        

      ) : (null)}


      {/* Modal de criar família */}
      {modalCreateFamilia ? (
        <MDBModal 
          isOpen={modalCreateFamilia}
          toggle={toggleCreateFamilia}
          disableBackdrop
        >
          
          <MDBModalBody>
            <div className="info-form">
              <GridContainer >
                <GridItem xs={12} sm={12} md={4}>
                  <p className="p-form">Família</p>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem className="grid-item" xs={12} sm={12} md={12}>
                  <MDBInput 
                    label="Nome da Família *" 
                    type="text" validate 
                    error="wrong" success="right"
                    value={nomeFamilia}
                    onChange={input => setNomeFamilia(mask.charAndText(input.target.value))}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={() => {
                toggleCreateFamilia() 
                toggleCreateGrupo()}}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>     
              <Button disabled={checkValuesFamilia()} variant="contained" onClick={handleSubmitFamilia}>
                Registrar Família
              </Button> 
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>
        

      ) : (null)}      
      
      {/* Modal de verificação do delete */}
      {modalDelete ? (
        <MDBModal
          isOpen={modalDelete}
          toggle={toggleDelete}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir esse subgrupo?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Nome do SubGrupo: {subGrupo.nome}
                  </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDelete}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelete(subGrupo.id)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}

      {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ) : (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className="header-table" color="primary">
                <h4 className="title-table">Lista de SubGrupos dos produtos</h4>
                <p className="p">
                    Adicione e edite os SubGrupos dos produtos.
                </p>
              </CardHeader>
              <CardBody>
                <Table
                modelos={subGrupos}
                handleUpdate={handleUpdate}
                toggleDelete={toggleDelete}
                setModelo={setSubGrupo}
                />

                <Button 
                  variant="contained"
                  style={{marginTop: '-80px'}}
                  onClick={() => {
                  setIsUpdate(false)
                  clearState()
                  toggle()}}>
                  Adicionar SubGrupo
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
  </div>
       
  )
}