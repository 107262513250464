import axiosInstance from '../../auth/AuthUserService.js'

export default class ItensPedidoService {
  create(data) {
    const url = '/pedidos/itensPedido/adicionar';
    return axiosInstance.post(url, data);
  }

  update(id, data) {
    const url = `/pedidos/itensPedido/atualizar/${id}`;
    return axiosInstance.put(url, data);
  }

  delete(id) {
    const url = `/pedidos/itensPedido/excluir/${id}`;
    return axiosInstance.delete(url);
  }

}