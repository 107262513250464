import React from 'react';

// Elemento Read:
import CrudFornecedores from '../../components/Fornecedores/index';

export default function Fornecedores() {
  return (
    <div>
      <CrudFornecedores />
    </div>
  )
};
