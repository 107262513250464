import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import * as FaIcons from 'react-icons/fa';
import SidebarData from "./SidebarData.js";
import { useAuthDataContext } from '../../services/auth/AuthContext';
import SubMenu from './SubMenu.js';

import { NavIcon, SidebarNav, SidebarWrap} from './SidebarStyles';

export default function Sidebar(open) {
  const datas = new SidebarData()

  const { userGroup } = useAuthDataContext()

  const [sidebar, setSidebar] = useState(false)
  
  const showSidebar = () => setSidebar(!sidebar)
  const [mobileOpen, setMobileOpen] = useState(false)

  const getMobileOpen = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    } else {
      setMobileOpen(true)
    }
  }

  useEffect(() => {
    getMobileOpen()
  }, [])
  
  return (
    
    <>
    {mobileOpen ? (
      <>
        <NavIcon style={{color: "black"}} to="#">
          <FaIcons.FaBars onClick={showSidebar} />
        </NavIcon> 
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap>
            <NavIcon style={{color: "white"}} to="#">
              <AiIcons.AiOutlineClose onClick={showSidebar} />
            </NavIcon>
            <div 
            style={{
              height: "60px",
              paddingLeft: "1rem",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              fontSize: "20px",
              color: "#e1e9fc",
              fontWeight: "bold"
            }}> 
            Perspectiva Consultoria
          </div>
            {userGroup=="Orcamento" ? (
              datas.dataOrcamentos.map((item, index) => {
                return <SubMenu item={item} key={index} />;
              })
              ) : (
                datas.dataDefault.map((item, index) => {
                  return <SubMenu item={item} key={index} />;
                })
            )}
            
          </SidebarWrap>
        </SidebarNav>
      </>
    ) :  (
      <SidebarNav >
        <SidebarWrap>
          <div 
            style={{
              height: "60px",
              paddingLeft: "1rem",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              fontSize: "20px",
              color: "#e1e9fc",
              fontWeight: "bold"
            }}> 
            Perspectiva Consultoria
          </div>
          
          {userGroup=="Orcamento" ? (
            datas.dataOrcamentos.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })
          ) : (
            datas.dataDefault.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })
          )}
        </SidebarWrap>
      </SidebarNav>   
    )}
           
    </>
  );
};
  