import axiosInstance from '../auth/AuthUserService.js'

export default class ConsultoresService {

  getAll() {
    const url = '/consultores/listar';
    return axiosInstance.get(url);
  }

  getConsultorByUser(data) {
    const url = '/consultor/by/user/listar';
    return axiosInstance.post(url, data);
  }

  get(id) {
    const url = `/consultores/${id}`;
    return axiosInstance.get(url);
  }

  create(data) {
    const url = '/consultores/adicionar';
    return axiosInstance.post(url, data, {'Content-Type': 'multipart/form-data', 
  });
  }

  update(id, data) {
    const url = `/consultores/atualizar/${id}`;
    return axiosInstance.put(url, data, {'Content-Type': 'multipart/form-data', 
  });
  }

  delete(id) {
    const url = `/consultores/excluir/${id}`;
    return axiosInstance.delete(url);
  }

}