import React, { useState, useEffect } from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import logo from '../../../assets/img/logo_pconsultoria.jpeg';

const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingHorizontal: 5,
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    fontFamily: "Helvetica",
    fontWeight: "600",
    color: '#fff',
    paddingTop: "2%",
    paddingBottom: 5,
    paddingLeft: "14%",
  },
  text: {
    margin: 12,
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    alignSelf: "left",
    width: "100px",
    height: "50px",
    paddingLeft: "5px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: '#B19B80',
  },
  table: {
    marginTop: "10px",
    marginBottom: "20px",
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderColor: "#B19B80",
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeaderMin: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    color: "#fff",
    backgroundColor: "#B19B80",
    borderColor: "#B19B80",
    textAlign: "center",
  },
  tableColHeader: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    color: "#fff",
    backgroundColor: "#B19B80",
    borderColor: "#B19B80",
    textAlign: "center",
  },
  tableCol: {
    width: "33.33%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#B19B80",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableColMin: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#B19B80",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: 'bold',
  },
  tableCell: {
    margin: 5,
    fontSize: 9,
  },
});

export default function ReportPDFFile(props) {
  const { pedidos } = props;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const [currentDateTime, setCurrentDateTime] = useState('');
  const [totalPedidos, setTotalPedidos] = useState(0);
  const [totalValorBruto, setTotalValorBruto] = useState(0);
  const [totalValorLiquido, setTotalValorLiquido] = useState(0);

  const getClientAlias = (razao_social) => {
    let razaoSocialAlias = razao_social.split(' ')[0] + ' ' +razao_social.split(' ')[1]
    return razaoSocialAlias
  }

  const getConsultantAlias = (consultants) => {
    return consultants.map(consultant => {
      const nameParts = consultant.nome.trim().split(' ');
      const alias = nameParts.slice(0, 2).join(' ');
      return alias;
  });
  }

  const calculateValorTotal = (valorBruto, percentual) => {
    let formatPercentual = percentual.replace('%', '')
    let percentualComplemento = (100 - parseFloat(formatPercentual))/100;
    let valueTotal = parseFloat((valorBruto * percentualComplemento).toFixed(2))
    let formatTotal = formatCurrency(valueTotal)

    return formatTotal;
  }

  useEffect(() => {
    const date = new Date();
    const formattedDate = date.toLocaleDateString('pt-BR');
    const formattedTime = date.toLocaleTimeString('pt-BR');
    setCurrentDateTime(`${formattedDate} às ${formattedTime}`);

    // Calcular o total de pedidos, valor bruto e valor líquido
    if (pedidos && pedidos.length > 0) {
      let pedidosCount = 0;
      let valorBrutoTotal = 0;
      let valorLiquidoTotal = 0;

      pedidos.forEach((pedido) => {
        pedidosCount++;
        valorBrutoTotal += pedido.valor_total;
        valorLiquidoTotal += pedido.valor_final;
      });

      setTotalPedidos(pedidosCount);
      setTotalValorBruto(valorBrutoTotal);
      setTotalValorLiquido(valorLiquidoTotal);
    }
  }, [pedidos]);

  const renderSummaryTable = () => (
    <View style={{ ...styles.table, marginBottom: 20 }}>
      <View style={styles.tableRow}>
        <View style={{ ...styles.tableColHeader, width: "100%" }}>
          <Text style={styles.tableCellHeader}>RESUMO DOS DADOS</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>VALOR BRUTO TOTAL (R$)</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>VALOR LÍQUIDO TOTAL (R$)</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCellHeader}>QUANTIDADE DE PEDIDOS</Text>
        </View>
      </View>
      <View style={styles.tableRow}>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{formatCurrency(totalValorBruto)}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{formatCurrency(totalValorLiquido)}</Text>
        </View>
        <View style={styles.tableCol}>
          <Text style={styles.tableCell}>{totalPedidos}</Text>
        </View>
      </View>
    </View>
  );

  const renderTableHeader = () => (
    <View style={styles.tableRow} fixed>
      <View style={styles.tableColHeaderMin}>
        <Text style={styles.tableCellHeader}>DATA</Text>
      </View>
      <View style={styles.tableColHeaderMin}>
        <Text style={styles.tableCellHeader}>Nº PEDIDO</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>CLIENTE</Text>
      </View>
      <View style={styles.tableColHeaderMin}>
        <Text style={styles.tableCellHeader}>COND. PGTO</Text>
      </View>
      <View style={styles.tableColHeaderMin}>
        <Text style={styles.tableCellHeader}>VALOR BRUTO (R$)</Text>
      </View>
      <View style={styles.tableColHeaderMin}>
        <Text style={styles.tableCellHeader}>VALOR{'\n'} TOTAL (%)</Text>
      </View>
      <View style={styles.tableColHeaderMin}>
        <Text style={styles.tableCellHeader}>VALOR{'\n'} FINAL (R$)</Text>
      </View>
      <View style={styles.tableColHeader}>
        <Text style={styles.tableCellHeader}>CONSULTOR</Text>
      </View>
    </View>
  );

  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.row}>
          <Image style={styles.image} src={logo} />
          <Text style={styles.title} fixed>RELATÓRIO DE PEDIDOS</Text>
        </View>
        {renderSummaryTable()}
        <View style={styles.table}>
          {renderTableHeader()}
          {pedidos.map((pedido, i) => (
            <View style={styles.tableRow} key={i} wrap={false}>
              <View style={styles.tableColMin}>
                <Text style={styles.tableCell}>{formatDate(pedido.data_pedido)}</Text>
              </View>
              <View style={styles.tableColMin}>
                <Text style={styles.tableCell}>{pedido.n_pedido}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{getClientAlias(pedido.cliente.razao_social)}</Text>
              </View>
              <View style={styles.tableColMin}>
                <Text style={styles.tableCell}>{pedido.cond_pgto.prazo}</Text>
              </View>
              <View style={styles.tableColMin}>
                <Text style={styles.tableCell}>{formatCurrency(pedido.valor_total)}</Text>
              </View>
              <View style={styles.tableColMin}>
                <Text style={styles.tableCell}>{calculateValorTotal(pedido.valor_total, pedido.cond_pgto.desconto_comercial)}</Text>
              </View>
              <View style={styles.tableColMin}>
                <Text style={styles.tableCell}>{formatCurrency(pedido.valor_final)}</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{getConsultantAlias(pedido.consultor)}</Text>
              </View>
            </View>
          ))}
        </View>
        <Text style={styles.pageNumber}>
          Emitido em {currentDateTime}
        </Text>
      </Page>
    </Document>
  );
}
