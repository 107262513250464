import axiosInstance from '../auth/AuthUserService.js'
import Cookies from 'js-cookie';


export default class UserService {

    getAll() {
        const url = 'user/list/';
        return axiosInstance.get(url).then(response => response.data);
    }
    getAllByURL(link) {
        const url = `user/list/${link}`;
        return axiosInstance.get(url).then(response => response.data);
    }
    get(pk) {
        const url = `user/detail/${pk}`;
        return axiosInstance.get(url).then(response => response.data);
    }
    delete(user) {
        const url = `user/detail/${user.pk}`;
        return axiosInstance.delete(url);
    }
    create(user) {
        const url = `user/create/`;
        return axiosInstance.post(url, user);
    }
    update(user) {
        const url = `user/detail/${user.pk}`;
        return axiosInstance.put(url, user);
    }

    async login(user) {
        let res = {}
        await axiosInstance.post('/token/obtain/', {
            username: user.username,
            password: user.password
        }).then(data => {
            axiosInstance.defaults.headers['Authorization'] = "JWT " + data.data.access;
            localStorage.setItem('access_token', data.data.access);
            localStorage.setItem('refresh_token', data.data.refresh);
            Cookies.set("__session", data.data.access)
            res = data;
        })
            .catch(err => {
                res = err.response
            })
        return res
    }
}