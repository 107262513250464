import React, { useState } from 'react';
import { 
    MDBModal,
    MDBRow,
    MDBModalBody,
    MDBModalHeader,
    MDBInput,
    MDBModalFooter
} from 'mdbreact';

import Card from '../../../Card/Card';

import { BiSolidAddToQueue } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import { Button } from '@mui/material'
import GridItem from '../../../Grid/GridItem';
import GridContainer from '../../../Grid/GridContainer';
import NativeSelect from '@mui/material/NativeSelect';

import { useAuthDataContext } from '../../../../services/auth/AuthContext';
import ModalFilterClientByField from '../../../Clientes/Modal/filterClientByField';

import ClientesService from '../../../../services/clientes/clientes';
import '../../styles.css';

export default function ModalAddClientePedido(props) {
	// Dados retornados para o pedido
	const { 
		idCliente,
		setIdCliente,
		cnpjCliente,
		razaoSocialCliente,
		enderecoEntrega,
		idCondPgto,
		setIdCondPgto,
		descontoComercial,
		condicaoPgto,
		prazoPgto,
		statusPedido,
		setStatusPedido,
		isUpdate,
		togglePedido
	} = props;

	// Services
	const clientesService = new ClientesService()
	// User
	const { user, userGroup } = useAuthDataContext()
	// Errors	
	const [error, setError] = useState('')

	// Variáveis de controle do Modal
	const [modal, setModal] = useState(false);
	const toggleCliente = () => { 
			setModal(!modal) 
	}
	// Campos de clientes
	const [cliente, setCliente] = useState('')
	const [clientes, setClientes] = useState([])
	const [condsPgto, setCondsPgto] = useState([])
	const [isLoadingCliente, setIsLoadingCliente] = useState(false)
	const [isViewTableCliente, setIsViewTableCliente] = useState(false)

	// Determina opcoes de cliented
	const showClientes = clientes.map((data, idx) => {
		return (
			<option key={idx} value={data.id}>{data.razao_social}</option>
		)
	})

	// Quando altera o cliente:
	const handleChangeCliente = (idx, value) => {
		descontoComercial("")
		condicaoPgto("")
		prazoPgto("")
		if (value!="Selecione o Cliente") {    
			const selectedCliente = clientes[idx - 1]
			setIdCliente(selectedCliente.id)
			cnpjCliente(selectedCliente.cnpj)
			razaoSocialCliente(selectedCliente.razao_social)
			enderecoEntrega(selectedCliente.endereco_entrega.logradouro+", "+selectedCliente.endereco_entrega.numero+" - "+selectedCliente.endereco_entrega.bairro+"/"+selectedCliente.endereco_entrega.municipio)
			// setIsCorpCliente(selectedCliente.is_corp)
			getCondsPgto(selectedCliente.id)
		} 
	} 
	
	// Pega condicoes para o cliente
		const getCondsPgto = (idCliente) => {
			clientesService.getCondPgtoByCliente(idCliente)
			.then(response => {
				if (response.status === 200) {
					setCondsPgto(response.data) 
				} else {
					setError('Erro aos listar condições de Pagamento!!')
				}
			})
			.catch(error => {
				setError(error)
			}) 
		}

		// Quando altera a condicao
		const handleChangeCondPgto = (idx, value) => {
			descontoComercial("")
			condicaoPgto("")
			prazoPgto("")
			if (value!="Selecione a Condição de Pagamento") {    
				const selectedCondPgto = condsPgto[idx - 1]
				var desconto = selectedCondPgto.desconto_comercial.replace('%', '')
				var descontoFormatted = desconto+'%'
				descontoComercial(descontoFormatted)
				condicaoPgto(getCondPgto(selectedCondPgto.condicao))
				prazoPgto(selectedCondPgto.prazo)
			} 
	
			// if ((itensPedido.length > 0) && (ipi!='') && (percIpi!='')) {
			// 	setValorFinal(getItensPromoAndMecanismo(descontoInitial, ipi, percIpi, itensPedido))
			// } 
		}

		// Pegando condição de pagamento formatada (padrao/especial):
		const getCondPgto = (condPgto) => {
			if (condPgto === 1) {
				return "Antecipado"      
			} 
			if (condPgto === 2) {
				if (!isUpdate) {
					if (user.is_superuser) {
						setStatusPedido("7")
					}
					//  else {
						// if (isAprove==false) {
							// setStatusPedido('1')
						// }
					// }   
				}
				return "Especial"
			}
		}

		// Pega condicoes para a cond pgto
		const showCondsPgto = condsPgto.map((cond, idx) => {
			let condName = 'Padrão'
			if (cond.condicao === 2){
				condName = 'Especial'
			}
			return (
				<option key={idx} value={cond.id}>Condição: {condName} - Prazo: {cond.prazo} - Desconto: {cond.desconto_comercial}</option>
			)
		})
    // Valida os campos antes de permitir o filtro
    const checkValues = () => {
			if (
				idCliente == "" ||
				idCondPgto == "" ||
				clientes.length <= 0 ||
				condsPgto == []
			) {
					return true
			} else {
					return false
			}
    }

   
    // Limpa os campos
    const clearState = () => {
			setCliente('')
			setClientes([])
			setCondsPgto([])
			setIsLoadingCliente(false)
			setIsViewTableCliente(false)
    }

    return (
			<>
				<MDBModal
					isOpen={modal}
					toggle={toggleCliente}
					size='lg'
					disableBackdrop
				>
					<MDBModalBody>
						<GridItem center 
							className="title-subinfo"
							style={{ 
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'row',
								alignItems: 'center',
								backgroundColor: '#ADC5B4', 
								color: 'white', 
								fontSize: '22px', 
								marginLeft: '0', 
								marginRight: '0', 
								fontWeight: '600', 
								marginBottom: '10px',
								marginTop: '10px'
							}}>
							{userGroup == 'Orcamento' ? (
								<>
									Cliente do Orçamento 
								</>
							):(
								<>
									Cliente do Pedido
								</>
							)}
							{<ModalFilterClientByField
								onClick={() => setClientes([])}
								clienteService={clientesService}
								setClientes={setClientes}
								setIsLoading={setIsLoadingCliente}
								setIsViewTable={setIsViewTableCliente}
							/>}
						</GridItem>
						
						{clientes.length > 0 ? (
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									<NativeSelect
										value={cliente}
										onChange={e => {
											setCliente(e.target.value)
											setIdCondPgto("")
											handleChangeCliente(e.target.options.selectedIndex, e.target.value)
										}}
									>
										<option>Selecione o Cliente</option>
											{showClientes}
										</NativeSelect>
								</GridItem>

								<GridItem xs={12} sm={12} md={6}>
									<NativeSelect
										disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
										value={cliente}
										onChange={e => {
											setIdCondPgto(e.target.value)
											handleChangeCondPgto(e.target.options.selectedIndex, e.target.value)
										}}
									>
										<option>Selecione a Condição de Pagamento</option>
										{showCondsPgto}
									</NativeSelect>
								</GridItem>
							</GridContainer>
						):(null)}
					</MDBModalBody>

					<MDBRow end style={{marginRight: '0px'}}>
						<MDBModalFooter>
							<Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleCliente}>Cancelar</Button>
						</MDBModalFooter>

						<MDBModalFooter>
							<Button disabled={checkValues()} variant="contained" 
							onClick={() => {
								toggleCliente()
							}}
							>
								{isUpdate ? (
									<>
										Alterar
									</>
								):(
									<>
										Adicionar
									</>
								)}
							</Button>
						</MDBModalFooter>
					</MDBRow>
				</MDBModal>

        
				{/* Botão para filtro */}
				{isUpdate || idCliente != '' ? (
				<CiEdit 
					title='Adicionar Cliente'
						className="btn-manage"
						onClick={() => {
							toggleCliente()
							clearState()
						}}
						style={{ height: '24px', color: '#F6EDD2', width: '24px' }}
				/>
				):(
					<BiSolidAddToQueue 
					title='Adicionar Cliente'
						className="btn-manage"
						onClick={() => {
							clearState()
							toggleCliente()
							
						}}
						style={{ height: '24px', color: '#F6EDD2', width: '24px' }}
					/>
				)}
				
		</>
	)
}