import React, { useEffect, useState } from 'react';
import { MDBDataTableV5, MDBIcon } from 'mdbreact';

import './TableStyles.css'
export default function Table(props) {

  // Definindo modelo que virá do component Read de cada modelo:
  const { modelos, handleUpdate, toggleDelete, setModelo, getRevestimentos } = props;
 
  // Definindo linhas e colunas para a tabela:  
  const [columns, setColumns] = useState('');
  const [rows, setRows] = useState('');
  
  const getPadronagem = (statusPadronagem) => {
    if (statusPadronagem === "fabrica") {
      return "Fábrica"
    } else if (statusPadronagem === "especial"){
      return "Especial"
    } else if (statusPadronagem === "complementos") {
      return "Complementos"
    }
  }

  const getCorpAndActive = (value) => {
    if (value==false) {
      return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 1792 1792">
              <path fill="#dd4646" d="M1277 1122q0-26-19-45l-181-181 181-181q19-19 19-45 0-27-19-46l-90-90q-19-19-46-19-26 0-45 19l-181 181-181-181q-19-19-45-19-27 0-46 19l-90 90q-19 19-19 46 0 26 19 45l181 181-181 181q-19 19-19 45 0 27 19 46l90 90q19 19 46 19 26 0 45-19l181-181 181 181q19 19 45 19 27 0 46-19l90-90q19-19 19-46zm387-226q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
            </svg>
    }

    if (value==true) {
      return <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 1792 1792">
              <path fill="#70bf2b" d="M1412 734q0-28-18-46l-91-90q-19-19-45-19t-45 19l-408 407-226-226q-19-19-45-19t-45 19l-91 90q-18 18-18 46 0 27 18 45l362 362q19 19 45 19 27 0 46-19l543-543q18-18 18-45zm252 162q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"/>
            </svg>
    }
  }

  // Definindo map para se obter as colunas referentes a tabela:
  const getColumns = () => {
    var dataHeader = 
    [ 
      {
        label: 'ID',
        field: 'id',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'ID',
        },
      },
      {
        label: 'Ativo',
        field: 'ativo',
        width: 100,
      },
      {
        label: 'Corp',
        field: 'corp',
        width: 100,
      },
      {
        label: 'Imagem',
        field: 'imagem',
        width: 150,
      },
      {
        label: 'Nome',
        field: 'nome',
        width: 200,
      },
      {
        label: 'Padronagem',
        field: 'padronagem',
        width: 100,
      },
      {
        label: 'Largura',
        field: 'largura',
        width: 100,
      },
      {
        label: 'Altura',
        field: 'altura',
        width: 100,
      },
      {
        label: 'Profundidade',
        field: 'profundidade',
        width: 100,
      },
      {
        label: "Ações",
        field: 'acoes',
        width: 150,
      }
    ]
    setColumns(dataHeader)     
  }    
    
  // Definindo map para se obter as linhas referentes a tabela:
  const getRows = () => {
  
    let dataBody = modelos.map((modelo, key)=>{ 
      if (modelo.imagem==undefined) {
        return {
          id: modelo.id,
          nome: modelo.nome,
          ativo: getCorpAndActive(modelo.is_active),
          corp: getCorpAndActive(modelo.is_corp),
          padronagem: getPadronagem(modelo.padronagem),
          largura: modelo.largura,
          profundidade: modelo.profundidade,
          altura: modelo.altura,
          acoes:<div key={key}> 
                  <div className="acoes">
                    <MDBIcon onClick={() =>{
                      handleUpdate(modelo)
                      getRevestimentos(modelo.id)
                    }} 
                    icon="pen"/> 
                  </div> 
  
                  <div className="acoes">
                    <MDBIcon onClick={() => {
                      toggleDelete() 
                      setModelo(modelo)
                    }} 
                    icon="trash"/>
                  </div>                     
                </div>  
        }
      } else {
        return {
          id: modelo.id,
          nome: modelo.nome,
          ativo: getCorpAndActive(modelo.is_active),
          corp: getCorpAndActive(modelo.is_corp),
          padronagem: getPadronagem(modelo.padronagem),
          largura: modelo.largura,
          profundidade: modelo.profundidade,
          altura: modelo.altura,
          imagem: <>
                    <img className="logo-a" src={modelo.imagem} />
                  </>,
          acoes:<div key={key}> 
                  <div className="acoes">
                    <MDBIcon onClick={() =>{
                      handleUpdate(modelo)
                      getRevestimentos(modelo.id)
                    }} 
                    icon="pen"/> 
                  </div> 
  
                  <div className="acoes">
                    <MDBIcon onClick={() => {
                      toggleDelete() 
                      setModelo(modelo)
                    }} 
                    icon="trash"/>
                  </div>                     
                </div>  
        }
      }
      
      
    })
    setRows(dataBody)
    } 
  
  // Definindo useEffect para se obter as informações referentes a tabela,
  //    sejam linhas ou colunas, e que tem como dependência o modelo referenciado
  //    em props;
  useEffect(() => {
    getColumns();
    getRows();        
  }, [modelos]);

  // Retornando tabela
  return (
    <MDBDataTableV5 responsive
      hover
      entriesOptions={[5, 10, 15]}
      entries={5}
      pagesAmount={4}
      data={{ columns: columns, rows: rows }}
      pagingTop
      searchTop
      searchBottom={false}
      barReverse
    />
  );
}