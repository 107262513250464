import React from 'react';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

export const formatMedidas = (medida) => {
  if (medida!=null){
    return medida.toFixed(2)
  }
}

// Pega a padronagem e determina o ícone que o representa quando o pedido já cadastrado
export const getEspecialByPadr = (padr) => {
  if (padr!=="especial") {
    return  <CancelIcon />
  }
  if (padr==="especial") {
    return  <CheckCircleIcon />
  }
}

// Pega o isEspecial e determina o ícone que o representa sem que o pedido esteja criado
export const getEspecialByIsEspecial = (esp) => {
  if (esp!==true) {
    return  <CancelIcon />
  }

  if (esp===true) {
    return  <CheckCircleIcon />
  }
}

export const redirectToProduct = () => {
  window.open('/dashboard/produtos/arquivos', '_blank');
};

export const getPromo = (item) => {
	if (
		item.isMecanismo == true ||
		item.revestimento == "PROMO"
		) {
			return "S"
		} else {
			return "N"
		}
}