import React from 'react';

// Elemento Read:
import CrudComissoes from '../../components/Comissoes';

export default function Comissoes() {
  return (
    <div>
      <CrudComissoes />
    </div>
  )
};
