import axiosInstance from '../../auth/AuthUserService.js'

export default class GruposService {

  getAll() {
    const url = '/produtos/grupos/listar';
    return axiosInstance.get(url);
  }

  get(id) {
    const url = `/produtos/grupos/${id}`;
    return axiosInstance.get(url);
  }

  getForIdFamilias(data) {
    const url = 'produtos/familias/grupos/listar';
    return axiosInstance.post(url, data);
  }

  create(data) {
    const url = '/produtos/grupos/adicionar';
    return axiosInstance.post(url, data);
  }

  update(id, data) {
    const url = `/produtos/grupos/atualizar/${id}`;
    return axiosInstance.put(url, data);
  }

  delete(id) {
    const url = `/produtos/grupos/excluir/${id}`;
    return axiosInstance.delete(url);
  }

}