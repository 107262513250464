export default class Masks{
  
	cpfAndCnpj (value) {	
		if (value.length <= 14){
			return value
				.replace(/\D/g, '') 
				.replace(/(\d{3})(\d)/, '$1.$2') 
				.replace(/(\d{3})(\d)/, '$1.$2')
				.replace(/(\d{3})(\d{1,2})/, '$1-$2')
				.replace(/(-\d{2})\d+?$/, '$1')
		} else {
			return value
			.replace(/\D/g, '') 
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1/$2')
			.replace(/(\d{4})(\d{2})/, '$1-$2')
			.replace(/(-\d{2})\d+?$/, '$1')
		}
	}

  cnpj (value) {
		return value
			.replace(/\D/g, '') 
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1/$2')
			.replace(/(\d{4})(\d{2})/, '$1-$2')
			.replace(/(-\d{2})\d+?$/, '$1')
	}
    
  ie (value) {
		return value
			.replace(/\D/g, '') 
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1.$2')
			.replace(/(.\d{3})\d+?$/, '$1')
	}

	cpf (value) {
    return value
      .replace(/\D/g, '') 
      .replace(/(\d{3})(\d)/, '$1.$2') 
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1') 
  }
    
  cep (value) {
		return value
			.replace(/\D/g, '') 
			.replace(/(\d{2})(\d)/, '$1.$2')
			.replace(/(\d{3})(\d)/, '$1-$2')
			.replace(/(-\d{3})\d+?$/, '$1')
	}
    
  uf (value) {
		return value
			.toUpperCase()
			.replace(/(\d{2})\d+?$/, '$1')
	}
     
  telefone (value) {
		return value
			.replace(/\D/g, '') 
			.replace(/(\d{2})(\d)/, '($1) $2')
			.replace(/(\d{4})(\d)/, '$1-$2')
			.replace(/(-\d{4})\d+?$/, '$1')
	}
    
  celular (value) {
		return value
		.replace(/\D/g, '') 
		.replace(/(\d{2})(\d)/, '($1) $2')
		.replace(/(\d)(\d{4})/, '$1 $2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(-\d{4})\d+?$/, '$1')
	}

	charAndText (value) {
		return value
			.toUpperCase()
	}

	email (value) {
		return value
			.toLowerCase()
	}

	formatDescontoAndComissao (value) {
		return value
			.replace(',','.')
			.replace('/', '') 
			.replace(/[a-z]/,'')
			.replace(/[A-Z]/,'')
	}

	number (value) {
		return value
		 .replace(/[^0-9]/g,'')
	}
}
