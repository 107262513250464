import React, { useState, useEffect } from "react";
import { Page, Text, Image, Document, StyleSheet, View } from "@react-pdf/renderer";
import { TableItens, TableClientes, TableFornecedores } from './Table'
import PedidosService from "../../services/pedidos/pedidos";
import logo from '../../assets/img/logo_pconsultoria.jpeg'

import getFormatData from '../Pedidos/GetData'

const styles = StyleSheet.create({
  body: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingHorizontal: 5,
  },
  title: {
    fontSize: 12,
    textAlign: "center",
		fontFamily: "Times-Roman",
		fontWeight: "bold",
    paddingBottom: 2,
  },
  text: {
    margin: 12,
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },	
  image: {  
    alignSelf: "left",
    width: "120px",
    height: "50px",
  },
  header: {
    fontSize: 10,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
	titleItens: {
		margin: 12,
		fontSize: 12,
		fontFamily: "Times-Roman",
		textAlign: "center",
	},
  pageNumber: {
    position: "absolute",
    fontSize: 10,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    paddingBottom: 2,
  },
});

export default function PDFFile (props) {
	console.log("pdffile")
    console.log(props)
    const { pedido, itens, valor, valorDesconto, valorIpi, valorFinalSeparado, tipo, userGroup }	= props;
	
  if (tipo=="integral"){
		return (
		<Document>
      <Page style={styles.body}>
        <Text style={styles.title} fixed>
          {userGroup == 'Orcamento' || pedido.status == 0 ? (
            <>ORÇAMENTO DE COMPRA INTEGRAL</>):(
            <>PEDIDO DE COMPRA INTEGRAL</>
            )}
          </Text>
        <View style={styles.row}>
          <Image style={styles.image} src={logo} />
          <TableFornecedores data={pedido}  userGroup={userGroup}/>   
        </View>
				<TableClientes data={pedido}/>
				<Text style={styles.titleItens} fixed>ITENS</Text>
				<TableItens 
					data={itens} 
					valor={valor}
					valorDesconto={valorDesconto}
					valorIpi={valorIpi}
					valorFinalSeparado={valorFinalSeparado}
					modelo={pedido} 
					tipo={tipo}
				/>
				<Text
          style={styles.pageNumber}
        > 
        {userGroup == 'Orcamento' || pedido.status == 0? (
            <>ORÇAMENTO {pedido.n_pedido} - OC{pedido.oc_cliente} - {getFormatData(pedido.data_pedido)}</>
          ):(
            <>PEDIDO {pedido.n_pedido} - OC{pedido.oc_cliente} - {getFormatData(pedido.data_pedido)}</>
          )}
					
				</Text>
      </Page>
    </Document> 
  )}

	if (tipo=="liquido"){
		return (
		<Document>
      <Page style={styles.body}>
        <Text style={styles.title} fixed>
          {userGroup == 'Orcamento' || pedido.status == 0? (
            <>ORÇAMENTO DE COMPRA LIQUIDO</>):(
            <>PEDIDO DE COMPRA LIQUIDO</>
            )}
        </Text>
        <View style={styles.row}>
          <Image style={styles.image} src={logo} />
          <TableFornecedores data={pedido} userGroup={userGroup}/>   
        </View>
				<TableClientes data={pedido}/>
				<Text style={styles.titleItens} fixed>ITENS</Text>
				<TableItens 
					data={itens} 
					valor={valor}
					valorDesconto={valorDesconto}
					valorIpi={valorIpi}
					valorFinalSeparado={valorFinalSeparado}
					modelo={pedido} 
					tipo={tipo}
				/>
				<Text
          style={styles.pageNumber}
        >
          {userGroup == 'Orcamento' || pedido.status == 0 ? (
            <>ORÇAMENTO {pedido.n_pedido} - OC{pedido.oc_cliente} - {getFormatData(pedido.data_pedido)}</>
          ):(
            <>PEDIDO {pedido.n_pedido} - OC{pedido.oc_cliente} - {getFormatData(pedido.data_pedido)}</>
          )}
				</Text>
      </Page>
    </Document> 
  )
	}
	
}