import React, { createContext, useState, useEffect, useMemo, useContext } from "react";
import Cookies from 'js-cookie';
import UserService from '../user/User.js';

export const userService = new UserService();
export const AuthDataContext = createContext(null);

export default function AuthDataProvider(props) {

    const [isAuthenticating, setIsAuthenticating] = useState(true)
    const [hasAuthenticated, setHasAuthenticated] = useState(false)
    const [user_id, setUserId] = useState(null)
    const [user, setUser] = useState({})
    const [userGroup, setUserGroup] = useState("")
    const [userEmail, setUserEmail] = useState("")

    const getSession = () => {
        const jwt = Cookies.get('__session')
        let session
        try {
            if (jwt) {
                const base64Url = jwt.split('.')[1]
                const base64 = base64Url.replace('-', '+').replace('_', '/')
                session = JSON.parse(window.atob(base64))
            }
        } catch (error) {
            console.log(error)
        }
        if (session) {
            const { user_id } = session
            setUserId(user_id)
            getUserInfo(user_id)
            setHasAuthenticated(true);
            setIsAuthenticating(false);
            return {session, user_id, hasAuthenticated}
        } else {
            setIsAuthenticating(false);
            return null
        }
    }

    useEffect(() => {
        getSession();
    }, [])
    
    const getUserInfo = async (user_id) => {
        await userService.get(user_id)
        
            .then(res => {
                setUser({...user, ...res})
                console.log(res)
                if (res.groups.length > 0) {
                    setUserGroup(res.groups)
                    setUserEmail(res.email)
                } else {
                    setUserGroup("")
                    setUserEmail("")
                }
            })

            .catch(err => {
                console.log(err)
            })

             return {user, userGroup, userEmail}
     }

    const onLogout = () => {
        Cookies.remove('__session')
        setUserId(null)
        setUserGroup(null)
        window.location.reload()
    }

    const authDataValue = useMemo(() => ({ getSession, user_id, user, userGroup, userEmail, setUser, setUserGroup, setUserEmail, onLogout, 
         hasAuthenticated, isAuthenticating }), [getSession, user_id, user, userGroup, userEmail, setUser, setUserGroup, setUserEmail,
            onLogout, hasAuthenticated, isAuthenticating]);

    return <AuthDataContext.Provider value={authDataValue} {...props} />    
};

export const useAuthDataContext = () => useContext(AuthDataContext);
// export default AuthDataProvider;