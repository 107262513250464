import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import AuthDataProvider from '../services/auth/AuthContext';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import LoginScreen from '../pages/Login/LoginScreen';
import Dashboard from '../pages/Dashboard/Dashboard';

export default function Routes() {
  return (
    <AuthDataProvider>
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted={true} component={LoginScreen} path="/" exact />
          <PrivateRoute 
            component={Dashboard} 
            path="/dashboard"
          />
        </Switch>
      </BrowserRouter>
    </AuthDataProvider>
  )
}