import axiosInstance from '../../auth/AuthUserService.js'

export default class SubGruposService {

  getAll() {
    const url = '/produtos/subgrupos/listar';
    return axiosInstance.get(url);
  }

  get(id) {
    const url = `/produtos/subgrupos/${id}`;
    return axiosInstance.get(url);
  }

  getForIdGrupos(data) {
    const url = 'produtos/grupos/subgrupos/listar';
    return axiosInstance.post(url, data);
  }

  create(data) {
    const url = '/produtos/subgrupos/adicionar';
    return axiosInstance.post(url, data);
  }

  update(id, data) {
    const url = `/produtos/subgrupos/atualizar/${id}`;
    return axiosInstance.put(url, data);
  }

  delete(id) {
    const url = `/produtos/subgrupos/excluir/${id}`;
    return axiosInstance.delete(url);
  }

}