import React from "react";
import * as AiIcons from 'react-icons/ai';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs'
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';


import Clientes from '../../pages/Clientes/Clientes';
import Consultores from '../../pages/Consultores/Consultores';
import Fornecedores from '../../pages/Fornecedores/Fornecedores';
import Pedidos from '../../pages/Pedidos/Pedidos';
import Permissoes from "../../pages/Permissoes/Permissoes";
import Orcamento from "../../pages/Permissoes/Orcamentos/Orcamentos";
import Produtos from '../../pages/Produtos/Produtos';
import Familias from '../../pages/Produtos/Familia/Familia.js'
import Grupos from '../../pages/Produtos/Grupo/Grupo.js'
import SubGrupos from "../../pages/Produtos/SubGrupo/SubGrupo.js";
import DashboardPage from '../Dashboard/index';
import Comissoes from '../../pages/Comissoes/Comissoes.js';
import Arquivos from '../../pages/Produtos/Arquivos/index.js'

export default class SidebarData {
  dataDefault = [
    {
      title: "Dashboard",
      path: "/dashboard",
      icon: <AiIcons.AiFillAppstore />,
      component: DashboardPage,
      layout: "/dashboard"
    },
    {
      title: "Clientes",
      path: "/clientes",
      icon: <AiIcons.AiFillHome />,
      component: Clientes,
      layout: "/dashboard",
    },
    {
        title: "Comissoes",
        path: "/comissoes",
        icon: <FaIcons.FaMoneyBillWave />,
        component: Comissoes,
        layout: "/dashboard",
      },
    {
      title: "Consultores",
      path: "/consultores",
      icon: <BsIcons.BsPersonFill />,
      component: Consultores,
      layout: "/dashboard",
    },
    {
      title: "Fornecedores",
      path: "/fornecedores",
      icon: <BsIcons.BsBuilding />,
      component: Fornecedores,
      layout: "/dashboard",
    },
    {
      title: "Pedidos",
      path: "/pedidos",
      icon: <BsIcons.BsClipboard />,
      component: Pedidos,
      layout: "/dashboard",
    },
    {
      title: "Produtos",
      path: "/produtos",
      icon: <BsIcons.BsCart2 />,
      layout: "/dashboard",
      component: Produtos,
      iconClosed: <RiIcons.RiArrowDownSFill />,
      iconOpened: <RiIcons.RiArrowUpSFill />,
      subNav: [
        {
          title: "Arquivos",
          path: "/produtos/arquivos",
          icon: <FaIcons.FaFileAlt />,
          component: Arquivos,
          layout: "/dashboard",
        },
        {
          title: "Famílias",
          path: "/produtos/familias",
          icon: <RiIcons.RiCommunityLine />,
          component: Familias,
          layout: "/dashboard",
        },
        {
          title: "Grupos",
          path: "/produtos/grupos",
          icon: <HiIcons.HiUserGroup />,
          component: Grupos,
          layout: "/dashboard",
        },
        {
          title: "SubGrupos",
          path: "/produtos/subgrupos",
          icon: <FaIcons.FaUserTag />,
          component: SubGrupos,
          layout: "/dashboard",
        },
      ],
    }
  ];
  
  dataOrcamentos = [
    {
      title: "Orçamentos",
      path: "/pedidos",
      icon: <BsIcons.BsClipboard />,
      component: Pedidos,
      layout: "/dashboard",
    }
  ]
}

// export default SidebarData;

