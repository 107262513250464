import React, { useState } from 'react';
import { 
    MDBModal,
    MDBRow,
    MDBModalBody,
    MDBModalHeader,
    MDBInput,
    MDBModalFooter
} from 'mdbreact';
import { AiOutlineFileSearch } from "react-icons/ai";
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';

export default function ModalFilterProdutoByField(props) {
    // Variáveis para comunicação com Produto:
    const { produtosService, setProdutos, setIsLoading, setIsViewTable } = props;
    // Variáveis de controle do Modal
    const [modal, setModal] = useState(false);
    const toggle = () => { 
        setModal(!modal) 
    }
    // Variáveis para filtro
    const [valueField, setValuefield] = useState('')

    // Valida os campos antes de permitir o filtro
    const checkValuesFilterProduto = () => {
        if (valueField == "") {
            return true
        } else {
            return false
        }
    }

    // Realiza o filtro por pedidos na api
    const handleSubmit = () => {
        setIsLoading(true)
        const fieldToFilter = {
            valueField: valueField
        }
        try {
            produtosService.getProdutoByField(fieldToFilter)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data)
                    setProdutos(res.data)
                    toggle()
                    setIsLoading(false)
                    setIsViewTable(true)
                }
            })
        } catch(error) {
            alert("Ocorreu algum erro: "+error+", verifique e tente novamente!!")
        }
    }
    // Limpa os campos
    const clearState = () => {
        setValuefield("")
    }


    return (
        <>
            {/* Modal de Filtro */}
            <MDBModal
                isOpen={modal}
                toggle={toggle}
                size='lg'
                disableBackdrop
            >
                <MDBRow center>
                    <MDBModalHeader className="modal-header">Dados para filtrar Produto</MDBModalHeader>
                </MDBRow>

                <MDBModalBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <MDBInput 
                                validate
                                required
                                label="Valor do Nome do produto (ex. Hassan)"
                                style={{marginTop: '-24px'}}
                                type="text"  
                                hint={valueField}
                                value={valueField}
                                onChange={input => setValuefield(input.target.value)}
                            />
                        </GridItem>
                    </GridContainer>
                </MDBModalBody>

                <MDBRow end style={{marginRight: '0px'}}>
                    <MDBModalFooter>
                        <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
                    </MDBModalFooter>

                    <MDBModalFooter>
                        <Button disabled={checkValuesFilterProduto()} variant="contained" onClick={handleSubmit} >
                            Filtrar Produtos
                        </Button>
                    </MDBModalFooter>
                </MDBRow>
            </MDBModal>

            
            {/* Botão para filtro */}
            <Button
                style={{ backgroundColor: '#F6EDD2', color: '#B19B80', fontSize: '12px', height: '55px'}}
                variant="contained"
                onClick={() => {
                    toggle()
                    clearState()
                }}

            >
                Filtrar Produto
                <AiOutlineFileSearch 
                    style={{ height: '40px', width: '40px', paddingLeft: '20px'}}
                />
              </Button>
        </>
    )
}