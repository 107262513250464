import React from 'react';

export const getFrete = (frete) => {
	if (frete=='cif') {
		return "CIF"
	}
	if (frete=='fob') {
		return "FOB"
	}
}


export const getIpi = (ipi, percIpi) => {
  var percFormat = percIpi.replace('%', '')
  percFormat = percFormat+"%"
  if(ipi=='sim'){
    return percFormat
  } 
  
  if(ipi=='nao'){
    return 'Não Possui' 
  }

}