import axiosInstance from '../auth/AuthUserService.js'

export default class PedidosService {

  getAll(startDate, endDate) {
    const url = `/pedidos/listar/?startDate=${startDate}&endDate=${endDate}`;
    return axiosInstance.get(url);
  }
  getLast() {
    const url = '/pedidos/listar/last';
    return axiosInstance.get(url);
  }
  getAllByPage(page) {
    const url = `/pedidos/listar/?page=${page}`;
    return axiosInstance.get(url);
  }
  get(id) {
    const url = `/pedidos/${id}`;
    return axiosInstance.get(url);
  }
  getLastsOrcamentos(data) {
    const url = '/pedidos/orcamento/last/listar';
    return axiosInstance.post(url, data);
  }
  getByOrcamentos(data) {
    const url = '/pedidos/by/orcamentos/listar';
    return axiosInstance.post(url, data);
  }
  getByAguardandoAprovacao(data) {
    const url = '/pedidos/by/aguardando-aprovacao/listar';
    return axiosInstance.post(url, data);
  }

  getByStatus(data) {
    const url = '/pedidos/by/status/listar';
    return axiosInstance.post(url, data);
  }

  getPedidosByUser(data) {
    const url = '/pedidos/by/user/listar';
    return axiosInstance.post(url, data);
  }

  getPedidoByField(data) {
    const url = '/pedidos/filter/by/field';
    return axiosInstance.post(url, data);
  }

  create(data) {
    const url = '/pedidos/adicionar';
    return axiosInstance.post(url, data);
  }

  update(id, data) {
    const url = `/pedidos/atualizar/${id}`;
    return axiosInstance.put(url, data);
  }

  delete(id) {
    const url = `/pedidos/excluir/${id}`;
    return axiosInstance.delete(url);
  }

  getAnexosByPedido(id) {
    const url = `/pedidos/anexos/listar/${id}`;
    return axiosInstance.post(url);
  }

  addAnexo(id, data) {
    const url = `/pedidos/anexos/add/${id}`;
    return axiosInstance.post(url, data);
  }

  deleteAnexo(id, data) {

    console.log(data)
    const url = `/pedidos/anexos/excluir/${id}`;
    return axiosInstance.delete(url, data);
  }

  senderEmail(id, data) {
    const url = `pedidos/sendEmail/${id}`;
    return axiosInstance.post(url, data, {'Content-Type': 'multipart/form-data'});
  }
}