import React, { useEffect, useState } from 'react';

import { MDBDataTableV5 } from 'mdbreact';
import { BsFillBookmarkFill } from "react-icons/bs";

import getFormatData from './GetData';

// import './TableStyles.css'
export default function Table(props) {

  // Definindo modelo que virá do component Read de cada modelo:
  const { modelos } = props;
 
  // Definindo linhas e colunas para a tabela:  
  const [columns, setColumns] = useState('');
  const [rows, setRows] = useState('');

  const getStatus = (status) => {
    if (status === "0") {
      return  <>
                <BsFillBookmarkFill style={{color: '#e5e619', marginRight: '10px'}}/>
                Em Orçamento
              </> 
      
    } else if (status === "1") {
      return  <>
                <BsFillBookmarkFill style={{color: '#fb8c00', marginRight: '10px'}}/>
                Aguardando envio
              </>  
    }
    else if (status === "2") {
      return  <>
                <BsFillBookmarkFill style={{color: '#ec407a', marginRight: '10px'}}/>
                Em processamento
              </> 
    } else if (status === "3") {
      return  <>
                <BsFillBookmarkFill style={{color: '#1a73e8', marginRight: '10px'}}/>
                Em lote
              </>   
    } else if (status === "4") {
      return  <>
                <BsFillBookmarkFill style={{color: '#4caf50', marginRight: '10px'}}/>
                Finalizado
              </>
    } else if (status === "5") {
      return  <>
                <BsFillBookmarkFill style={{color: 'red', marginRight: '10px'}}/>
                Cancelado
              </>
    }
  }
  
  // Definindo map para se obter as colunas referentes a tabela:
  const getColumns = () => {
    var dataHeader = 
    [ {
        label: 'Nº do Pedido',
        field: 'numeroPedido',
        width: 200,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'ID',
          },
      },
      {
        label: 'Data Pedido',
        field: 'dataPedido',
        width: 150,
      },
      {
        label: 'OC ',
        field: 'ocCliente',
        width: 150,
      },
			{
        label: 'Status do Pedido',
        field: 'statusPedido',
        width: 150,
      },
      {
        label: 'Criado por',
        field: 'criadoPor',
        width: 200,
      },
      {
        label: 'Cliente',
        field: 'cliente',
        width: 200,
      },
      {
        label: 'Fornecedor',
        field: 'fornecedor',
        width: 200,
      },
    ]
    setColumns(dataHeader)     
  }    
    
  // Definindo map para se obter as linhas referentes a tabela:
  const getRows = () => {
  
    let dataBody = modelos.map((modelo, key)=>{ 
    return {

			dataPedido: getFormatData(modelo.data_pedido),
			statusPedido: getStatus(modelo.status),          
			numeroPedido: modelo.id,
			ocCliente: modelo.oc_cliente,
			cliente: modelo.cliente.razao_social,
			fornecedor: modelo.fornecedor.razao_social,
			criadoPor: modelo.criado_por
		}
		})   
    
    setRows(dataBody)
    } 
  
  // Definindo useEffect para se obter as informações referentes a tabela,
  //    sejam linhas ou colunas, e que tem como dependência o modelo referenciado
  //    em props;
  useEffect(() => {
    getColumns();
    getRows();        
  }, [modelos]);

  // Retornando tabela
  return (
    <MDBDataTableV5 responsive
      hover
      entriesOptions={[5, 10, 15]}
      entries={5}
      pagesAmount={4}
      data={{ columns: columns, rows: rows }}
      pagingTop
      searchTop
      searchBottom={false}
      barReverse
    />
  );
}