import React, { useEffect, useState, useRef } from 'react';

import {
  MDBContainer,
  MDBModal,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
} from 'mdbreact';
import { AiOutlineFileSync } from "react-icons/ai";
import { BiSolidAddToQueue } from "react-icons/bi";
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';

// Imports para campos de pedido que vem de fornecedores, clienpedidossettes e consultores:
import ConsultoresService from "../../services/consultores/consultores"
import ModalFilterPedidoByField from './Modal/filterPedidoByField';
import ReportOrderPrint from './Report/order';
import { useAuthDataContext } from '../../services/auth/AuthContext';

// Import para composição da página pedidos
import Table from './Table';
import { GetAll, GetLastPrincipalComponents, GetPedidosByAguardandoAprovacao, GetPedidosByUser } from '../GetAll';
import PedidosService from "../../services/pedidos/pedidos";
import Masks from "../Masks/Masks";

import ReactLoading from 'react-loading'

import  CreatePDFs from './createPdfs';
import ModalAddClientePedido from './Modal/addPedido/addClientePedido';
import ModalAddConsultorPedido from './Modal/addPedido/addConsultorPedido';
import ModalAddFornecedorPedido from './Modal/addPedido/addFornecedorPedido';
import CrudItemPedido from './Modal/addPedido/addItemPedido';

import './styles.css';


export default function CrudPedidos(props) {
  const { tipo } = props;
  const mask = new Masks()
  const [isLoading, setIsLoading] = useState(false)
	const [isViewTable, setIsViewTable] = useState(false)
  const { user, userGroup } = useAuthDataContext()

  /////////// PEDIDO ///////////
  // Informações para form Pedido:
  const pedidosService = new PedidosService()
  // Modais e informações para pedido:
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [pedidos, setPedidos] = useState([])
  const [pedido, setPedido] = useState(null)
  const [error, setError] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  // Bloco de variáveis para Pedido:
  const [id, setId] = useState('')
  const [statusPedido, setStatusPedido] = useState('')
  const [isAprove, setIsAprove] = useState(false)
  const [dataPedido, setDataPedido] = useState('')
  const [numeroPedido, setNumeroPedido] = useState('')
  const [ocCliente, setOcCliente] = useState('')
  const [valorTotal, setValorTotal] = useState('')
  const [valorFinal, setValorFinal] = useState('')
  const [notaFiscal, setNotaFiscal] = useState('')
  const [numConfFabrica, setNumConfFabrica] = useState('')
  const [observacoes, setObservacoes] = useState('')
  const [observacoesConsultor, setObservacoesConsultor] = useState('')
  const [percIpi, setPercIpi] = useState('')
  const [criadoPor, setCriadoPor] = useState('')
  const [autorizadoPor, setAutorizadoPor] = useState('')

  const [listaConsultores, setListaConsultores] = useState([])
  // Variáves de pedido que vem de consultores:
  const [consultor, setConsultor] = useState('')
 
  const [idConsultores, setIdConsultores] = useState('')
  const [quantConsultor, setQuantConsultor] = useState(0)
  
  // Variáves de pedido da condicao de pagamento do cliente
  const [idCondPgto, setIdCondPgto] = useState('')
  const [condicaoPagamento, setCondicaoPagamento] = useState('')
  const [prazoPagamento, setPrazoPagamento] = useState('')
  const [descontoComercial, setDescontoComercial] = useState('')
  const [hasCondPgto, setHasCondPgto] = useState(false)
  // Variáves de pedido que vem de clientes:
  const [idCliente, setIdCliente] = useState('')
  const [razaoSocialCliente, setRazaoSocialCliente] = useState('')
  const [cnpjCliente, setCnpjCliente] = useState('')
  const [enderecoEntrega, setEnderecoEntrega] = useState('')
  const [isCorpCliente, setIsCorpCliente] = useState(false)
  // Pegando condição de pagamento formatada:
  const getCondPgto = (condPgto) => {
    if (condPgto === 1) {
      return "Antecipado"      
    } 
    if (condPgto === 2) {
      if (!isUpdate) {
        if (user.is_superuser) {
          setStatusPedido("7")
        } else {
          if (isAprove==false) {
              setStatusPedido('1')
          }
        }   
      }
      return "Especial"
    }
  }
 
  // Variáves de pedido que vem de fornecedores:
  const [idFornecedor, setIdFornecedor] = useState('')
  const [razaoSocialFornecedor, setRazaoSocialFornecedor] = useState('')
  const [cnpjFornecedor, setCnpjFornecedor] = useState('')
  const [prazoEntregaDias, setPrazoEntregaDias] = useState('')
  const [idPrazoEntrega, setIdPrazoEntrega] = useState('')
  const [prazoEntregaObs, setPrazoEntregaObs] = useState('')
  const [frete, setFrete] = useState('')
  const [ipi, setIpi] = useState('')

  // Pegando se possui ou não o ipi
  const getIpi = (value) => {
    if (value==='sim'){
      return 'Sim'
    } else {
      return 'Não'
    }
  }

  // Toggles 
  const toggle = () => { 
    setModal(!modal) 
  }
  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  // Create
  const handleSubmit = () => {
    if(statusPedido==="3") {
      if (numConfFabrica=="") {
        alert("Favor preencher o campo 'Nº de Confirmação da Fábrica'")
      } else {
        if (isUpdate) {
          confirmUpdate()
        } else {
          handleCreate()
        }
      }
    } else if(statusPedido==="4") {
      if (notaFiscal==""){
        alert("Favor preencher o campo 'Nota Fiscal'")
      } else {
        if (isUpdate) {
          confirmUpdate()
        } else {
          handleCreate()
        }
      }
    } else if(statusPedido=='0') {
      if (idCliente=="") {
        alert("Favor adicionar CLIENTE")
      } else if (listaConsultores.length<=0) {
        alert("Favor adicionar CONSULTORES")
      } else if (idFornecedor=="") {
        alert("Favor adicionar FORNECEDOR")
      } else if (dataPedido=="") {
        alert("Favor preencher o campo 'Data'")
      } else if (statusPedido=="Selecione o Status") {
        alert("Favor preencher o campo 'Status'")
      } else if (ocCliente=="") {
        alert("Favor preencher o campo 'OC Cliente'")
      } else if (itensPedido.length<=0) {
        alert("Favor adicionar PRODUTOS")
      } else {
        if (isUpdate) {
          confirmUpdate()
        } else {
          handleCreate()
        }
      }
    }  
    else {
      if (idCliente=="") {
        alert("Favor adicionar CLIENTE")
      } else if (idCondPgto=="") {
        alert("Favor adicionar a Condição de Pagamento do Pedido")
      } else if (listaConsultores.length<=0) {
        alert("Favor adicionar CONSULTORES")
      } else if (idFornecedor=="") {
        alert("Favor adicionar FORNECEDOR")
      } else if (idPrazoEntrega=="") {
        alert("Favor adicionar o Prazo de Entrega do Pedido")
      } else if (dataPedido=="") {
        alert("Favor preencher o campo 'Data'")
      } else if (statusPedido=="Selecione o status") {
        alert("Favor preencher o campo 'Status'")
      } else if (ocCliente=="") {
        alert("Favor preencher o campo 'OC Cliente'")
      } else if (itensPedido.length<=0) {
        alert("Favor adicionar PRODUTOS")
      } else {
        if (isUpdate) {
          confirmUpdate()
        } else {
          handleCreate()
        }
      }
    }
  }
  

  const handleCreate = () => {
    let form_data = new FormData()
    for (let i = 0; i < fileList.length; i++) {
      form_data.append('file-'+i, fileList[i])
    }

    let consultoresFormat = []
    listaConsultores.forEach((consultor) => {
      consultoresFormat.push(consultor.value)
    })
    setIdConsultores(consultoresFormat)
    form_data.append('dataPedido', dataPedido)
    form_data.append('itensPedido', JSON.stringify(itensPedido))
    form_data.append('numeroPedido', numeroPedido)
    form_data.append('statusPedido', statusPedido)
    form_data.append('notaFiscal', notaFiscal)
    form_data.append('percIpi', percIpi)
    form_data.append('numConfFabrica', numConfFabrica)
    form_data.append('ocCliente', ocCliente)
    form_data.append('consultores', consultoresFormat)
    form_data.append('cliente', idCliente)
    form_data.append('fornecedor', idFornecedor)
    form_data.append('valorTotal', valorTotal)
    form_data.append('valorFinal', valorFinal)
    form_data.append('condPgto', idCondPgto)
    form_data.append('prazoEntrega', idPrazoEntrega)
    form_data.append('observacoes', observacoes)
    form_data.append('observacoesConsultor', observacoesConsultor)
    form_data.append('criadoPor', criadoPor)
    form_data.append('autorizadoPor', autorizadoPor)
    form_data.append('isAprove', isAprove)
    try {
      pedidosService.create(form_data)
      .then(res => {      
        if (res.status === 200) {
        alert("Pedido criado com sucesso!!")
        toggle()
        setPedidos([])
        setIsLoading(true)
				setIsViewTable(false)
        getPedidos()     
      }})
    } catch (erro) {
      setError(erro)
    }
  }
  // Update:
  const handleUpdate = (modelo) => {
    const pedido = modelo.pedido
	  setPedido(pedido)
    
    getItensPedidos(pedido.id)
    getAnexosByPedido(pedido.id)
    setIsUpdate(true)
    setHasCreatedPedido(true)
    
    setId(pedido.id)
    setCriadoPor(pedido.created_by)
    setAutorizadoPor(pedido.updated_by)
    setIsAprove(pedido.is_aprove)
    setDataPedido(pedido.data_pedido)
    setNumeroPedido(pedido.n_pedido)
   
    setNotaFiscal(pedido.nota_fiscal)
    setNumConfFabrica(pedido.num_conf_fabrica)
    setOcCliente(pedido.oc_cliente)
    setConsultor(pedido.consultor)

    setValorTotal(pedido.valor_total)
    setValorFinal(pedido.valor_final)
    setObservacoes(pedido.obs)
    setObservacoesConsultor(pedido.obs_consultor)
    setPercIpi(pedido.percentual_ipi)
    setIpi(getIpi(pedido.ipi))

    // pegar consultores retornados
    setListaConsultores(modelo.consultores)
    setIdConsultores(modelo.consultores)

    setIdCliente(pedido.cliente.id)
    setCnpjCliente(pedido.cnpj_cliente)
    setRazaoSocialCliente(pedido.cliente.razao_social)
    setEnderecoEntrega(pedido.cliente.endereco_entrega.logradouro+", "+pedido.cliente.endereco_entrega.numero+" - "+pedido.cliente.endereco_entrega.bairro+"/"+pedido.cliente.endereco_entrega.municipio)
    if (pedido.cond_pgto != null) {
      setIdCondPgto(pedido.cond_pgto.id)
      var desconto = pedido.cond_pgto.desconto_comercial.replace('%', '')
      desconto = desconto+'%'
      setDescontoComercial(desconto)
      setCondicaoPagamento(getCondPgto(pedido.cond_pgto.condicao))
      setPrazoPagamento(pedido.cond_pgto.prazo)
      setHasCondPgto(true)
    } else {
      setHasCondPgto(false)
    }
    console.log(pedido)
    if (pedido.prazo_entrega != null) {
      setIdPrazoEntrega(pedido.prazo_entrega.id)
      setPrazoEntregaDias(pedido.prazo_entrega.prazo)
      setPrazoEntregaObs(pedido.prazo_entrega.obs)
    } 
    

    setIdFornecedor(pedido.fornecedor.id)
    setCnpjFornecedor(pedido.cnpj_fornecedor)
    setRazaoSocialFornecedor(pedido.fornecedor.razao_social)
    setFrete(pedido.fornecedor.frete_total.nome)

    toggle()
  }

  // Verificando o update:
  const confirmUpdate = () => {

    let form_data = new FormData()
    for (let i = 0; i < fileList.length; i++) {
      form_data.append('file-'+i, fileList[i].file)
    }

    let consultoresFormat = []
    listaConsultores.forEach((consultor) => {
      consultoresFormat.push(consultor.value)
    })

    form_data.append('id', id)
    form_data.append('dataPedido', dataPedido)
    form_data.append('itensPedido', JSON.stringify(itensPedido))
    form_data.append('numeroPedido', numeroPedido)
    form_data.append('statusPedido', statusPedido)
    form_data.append('notaFiscal', notaFiscal)
    form_data.append('numConfFabrica', numConfFabrica)
    form_data.append('ocCliente', ocCliente)
    form_data.append('consultores', consultoresFormat)
    form_data.append('cliente', idCliente)
    form_data.append('fornecedor', idFornecedor)
    form_data.append('valorTotal', valorTotal)
    form_data.append('valorFinal', valorFinal)
    form_data.append('condPgto', idCondPgto)
    form_data.append('prazoEntrega', idPrazoEntrega)
    form_data.append('observacoes', observacoes)
    form_data.append('observacoesConsultor', observacoesConsultor)
    form_data.append('criadoPor', criadoPor)
    form_data.append('autorizadoPor', autorizadoPor)
    form_data.append('isAprove', isAprove)
    try {    
      pedidosService.update(id, form_data)
      .then(res => {
        if (res.status === 200) {
          alert("Pedido atualizado com sucesso!!") 
          toggle()
          setPedidos([])
          setIsLoading(true)
					setIsViewTable(false)
          getPedidos()           
        }
      })
    } catch(error) {
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }
  }
  
  // Delete
  const handleDelete = (idPedido) => {
    pedidosService.delete(idPedido)
    .then(res =>{
      if (res.status === 200) {       
        toggleDelete()
        alert("Pedido apagado com sucesso!!")
        setPedidos([])
        setIsLoading(true)
        setIsViewTable(false)
        getPedidos()
      }
    })
  }

  // Função que checa valores de pedido antes de habilitar o botão de criar ou atualizar:
  const checkValuesPedido = () => {
    if(statusPedido==="3") {
      if (numConfFabrica=="") {
        return true
      } else {
        return false
      }
    } else if(statusPedido==="4") {
      if (notaFiscal==""){
        return true
      } else {
        return false
      }
    } else if(statusPedido=='0') {
      if (
        dataPedido=="" || 
        ocCliente=="" ||
        listaConsultores.length<=0  ||
        valorFinal=='NaN' ||
        valorTotal=='NaN' ||
        valorFinal=='' ||
        valorTotal=='' ||
        itensPedido.length==0
        
      ) {
        return true
      } else {
        return false
      }
    }  
    else {
      if (
        
        dataPedido=="" || 
        ocCliente=="" ||
        statusPedido=="" ||
        statusPedido=="Selecione o status" ||
        listaConsultores.length<=0  ||
        valorFinal=='NaN' ||
        valorTotal=='NaN' ||
        valorFinal=='' ||
        valorTotal=='' ||
        itensPedido.length==0
        
      ) {
        return true
      } else {
        return false
      }
    }
      
  } 
  // Cálculo do valor total dos itens, sem desconto comercial:
  const getValorTotal = (itens) => {
      if (itens.length >=1) {
        var montante = 0.0
        itens.map((item, idx) => {     
          if (item.valor) {
            montante += roundNumber(item.valor * item.quantidade)
          } else {
            montante += roundNumber(item.valorUnidade * item.quantidade);
          }     
        })
        montante = montante.toFixed(2)
        return montante
      }       
  }

  // Função para calcular valor dos itens da tabela integral e o devido desconto sobre eles, 
  //// separadamente dos itens promocionais ou mecanismo:
  function roundNumber (rnum) {
    return Math.round(rnum*Math.pow(10,2))/Math.pow(10,2);
  }

  const getItensPromoAndMecanismo = (descontoComercial, ipi, percIpi, itensPedido) => {
    // calculando valor do itens em 3 categorias(integral/promo e mecanismo/acessorio(ALEXA))
    var montanteIntegral = 0.0
    var montantePromoEMecanismo = 0.0
    var montanteAlexa = 0.0

    itensPedido.map((item) => {
      if (item.valor) {
        if (
            item.isMecanismo == true ||
            item.nomeRevestimento == "PROMO"
          ) {
            montantePromoEMecanismo += roundNumber(item.valor * item.quantidade)
          } else {
            if(item.nome.indexOf('ALEXA') != -1) {
              montanteAlexa += roundNumber(item.valor * item.quantidade)
            } else {
              montanteIntegral += roundNumber(item.valor * item.quantidade)
            } 
          }
      } 
      else {
        if (
          item.isMecanismo == true ||
          item.nomeRevestimento == "PROMO"
        ) {
          montantePromoEMecanismo += roundNumber(item.valorUnidade * item.quantidade)
        } else {
          if((item.nome.indexOf('ALEXA') != -1)) {
            montanteAlexa += roundNumber(item.valorUnidade * item.quantidade)
          } else {
            montanteIntegral += roundNumber(item.valorUnidade * item.quantidade)
          }
        }
      }
    })

    montantePromoEMecanismo = montantePromoEMecanismo.toFixed(2)
    montanteIntegral = montanteIntegral.toFixed(2)
    montanteAlexa = montanteAlexa.toFixed(2)

    // Obtendo o desconto:
    var percDesc = 0.0
    var getPercDesc = descontoComercial
    . replace('%', ' ')

    percDesc = getPercDesc/=100
    
    // Aplicando o desconto comercial nos montantes Integral:
    var valorDescontoIntegral = 0.0
    var montanteIntegralWithDesconto = 0.0

    valorDescontoIntegral = (montanteIntegral * percDesc)
    valorDescontoIntegral = valorDescontoIntegral.toFixed(2)
    montanteIntegralWithDesconto = roundNumber(montanteIntegral) - roundNumber(valorDescontoIntegral)
   
    // O ipi sera obtido em cima do resultado de (montanteintegral+MontantePromo)
    var valorCalculoIpi = roundNumber(montanteIntegralWithDesconto) + roundNumber(montantePromoEMecanismo)

    var valorFinalPedido = 0.0
    if (ipi=='sim' || ipi=='Sim'){
      // Calculando o valor do ipi em decimal/valor final
      var valorIpi = 0.0
      var formatPercIpi = 0.0

      var getPercIpi = percIpi
      . replace('%', ' ')

      formatPercIpi = getPercIpi/=100

      valorIpi = valorCalculoIpi * formatPercIpi
      valorIpi = roundNumber(valorIpi) + roundNumber(valorCalculoIpi)

      var valor = roundNumber(montanteAlexa) + roundNumber(valorIpi)

      valor = valor.toFixed(2)
      return valor
      
    } else {
      valorFinalPedido = roundNumber(montanteAlexa) + roundNumber(montanteIntegralWithDesconto) + roundNumber(montantePromoEMecanismo)
      var valor = valorFinalPedido.toFixed(2)
      return valor
    }
    
  }


  // Lógica para adicionar anexos: 
  const [isConfirmation, setIsConfirmation] = useState(false)
  const [modalDeleteFile, setModalDeleteFile] = useState(false)
  const toggleDeleteFile = () => {
    setModalDeleteFile(!modalDeleteFile)
  }
  // Logica button
  const hiddenFileInput = useRef(null)
  const handleClick = event => {
    hiddenFileInput.current.click()
  }
  // Arquivos
  const [fileForDel, setFileForDel] = useState("")
  const [file, setFile] = useState('')
  const [fileList, setFileList] = useState([])
  // const [addFile, setAddFile] = useState([])
  // Add item a lista
  const handleAddFile = () => {
    const newFile = {
      file: file,
      isConfirmation: isConfirmation
    }
    setFileList([...fileList, newFile])  // Adiciona o novo arquivo com a propriedade
    setFile('') // Limpa o arquivo selecionado
    setIsConfirmation(false) // Reseta o checkbox após adicionar
  }
  // Remover item da lista:
  const handleDelFile = (file) =>{
    if (file['id']){
      let form_data = new FormData()
      form_data.append('id', id)
      pedidosService.deleteAnexo(file['id'])
      .then(res =>{
        if (res.status === 200) {     
          getAnexosByPedido(id)  
          toggleDeleteFile()
          toggle()
        }
      })
    } else {
      fileList.splice(fileList.indexOf(file), 1)
      toggleDeleteFile()
      toggle()
    } 
    
  }
  
  // Lógica para listagem de anexos;
  const getAnexosByPedido = (idPedido) => {
    pedidosService.getAnexosByPedido(idPedido)
    .then(response => {
      if (response.status === 200) {
        setFileList(response.data)
      } else {
        setError('Erro aos listar anexos!!')
      }
    })
    .catch(error => {
      setError(error)
    }) 
  }

   // Limpa os estados ao clickar no botão adicionar Pedido
   const clearState = () => {
    setId("")
    setStatusPedido("")
    setIsAprove(false)
    setDataPedido("")
    setNumeroPedido("")
    setOcCliente("")
    setValorTotal("")
    setValorFinal("")
    setNumConfFabrica("")
    setObservacoes("")
    setObservacoesConsultor("")
    setPercIpi("")
    setCriadoPor(user.username)
    setAutorizadoPor("")

    setListaConsultores([])
    setConsultor("")
    setIdConsultores("")
    setQuantConsultor(0)

    setIdCondPgto("")
    setCondicaoPagamento("")
    setPrazoPagamento("")
    setDescontoComercial("")
    setHasCondPgto(false)

    setIdCliente("")
    setCnpjCliente("")
    setRazaoSocialCliente("")
    setEnderecoEntrega("")
    setIsCorpCliente(false)

    setIdFornecedor("")
    setCnpjFornecedor("")
    setIdPrazoEntrega("")
    setPrazoEntregaDias("")
    setPrazoEntregaObs("")
    setRazaoSocialFornecedor("")
    setFrete("")
    setIpi("")
    
    setIsUpdate(false)

    setItensPedidos("") 
    setAddItem([])
      
    setHasCreatedPedido(false)
    setItensPedidos([])
    setAddItem([])
    setHasItemEspecial(false)

    // setAddFile([])
    setFileList([])
  }

  const getTitle = (page) => {
    if (page == 'loader') {
      if (userGroup == 'Orcamento') {
        return 'Carregar Orçamento'
      } else {
        return 'Carregar Pedido'
      } 
    } else if (page == 'add') {
      if (userGroup == 'Orcamento') {
        return 'Adicionar Orçamento'
        } else {
        return 'Adicionar Pedido'
      }
    }
  }

  
  
  const [hasCreatedPedido, setHasCreatedPedido] = useState(false)
  const [itensPedido, setItensPedidos] = useState([])
  const [addItem, setAddItem] = useState([])
  const [hasItemEspecial, setHasItemEspecial] = useState(false)

  // Lógica de montagem de uma lista para visualização dos itens do pedido quando o mesmo já foi criado ():
  const getItensPedidos = (idPedido) => {
    pedidosService.get(idPedido)
    .then(response => {
      if (response.status === 200) {
        setItensPedidos(response.data) 
        if (isUpdate===true) {
          setValorTotal(getValorTotal(response.data))
          setValorFinal(getItensPromoAndMecanismo(descontoComercial, ipi, percIpi, response.data))
        }
      } else {
        setError('Erro aos listar!!')
      }
    })
    .catch(error => {
      setError(error)
    }) 
  }

  // Função que pega pedidos segundo o userGroup e o tipo de renderização:
  const getPedidos = () => {
    if (user.is_superuser) {
      GetLastPrincipalComponents(setPedidos, setError, pedidosService, setIsLoading, setIsViewTable)
    }
    if (userGroup == 'Orcamento') {
      GetPedidosByUser(setPedidos, setError, pedidosService, setIsLoading, user, setIsViewTable)
    }
    
  }

  function downloadBlob(blob, filename) {
    // Cria uma URL para o blob
    const url = window.URL.createObjectURL(blob);
    // Cria um elemento de link
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
  
    // Anexa o link ao DOM
    document.body.appendChild(a);
    a.click();
  
    // Remove o link do DOM
    document.body.removeChild(a);
    // Libera a URL do blob
    window.URL.revokeObjectURL(url);
  }

  return(
    <div>

      {/* Container de inserção de informação (Create/Update/Read Individual)*/}
      <MDBModal 
        isOpen={modal} 
        toggle={toggle} 
        size="lg" 
        disableBackdrop 
      >
        <MDBRow center>
            {isUpdate ? (
             userGroup == 'Orcamento' ? (
              <MDBModalHeader className="modal-header">Informações sobre o orçamento</MDBModalHeader>
            ):(
              <MDBModalHeader className="modal-header">Informações sobre o pedido</MDBModalHeader>
            )
            
          ) : (
            userGroup == 'Orcamento' ? (
              <MDBModalHeader className="modal-header">Cadastre novo orçamento</MDBModalHeader>
            ):(
              <MDBModalHeader className="modal-header">Cadastre novo pedido</MDBModalHeader>
            )
            
          )}
        </MDBRow>
        
        <MDBModalBody>

          {/* Informações sobre o Cliente */}
          <div className="info-form">
            <div>
              <GridItem center 
                className="title-subinfo"
                style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: '#ADC5B4', 
                  color: 'white', 
                  fontSize: '22px', 
                  marginLeft: '0', 
                  marginRight: '0', 
                  fontWeight: '600', 
                  marginBottom: '10px',
                  marginTop: '10px'
                }}>
                  {isUpdate ? (
                    <>Dados do Cliente selecionado</>
                  ):(
                    idCliente == '' ? (
                      <>Adicionar o Cliente*</>
                    ):(
                      <>Alterar o Cliente*</>
                    )
                  )}
                  <ModalAddClientePedido 
                    idCliente={idCliente}
                    setIdCliente={setIdCliente}
                    cnpjCliente={setCnpjCliente}
                    razaoSocialCliente={setRazaoSocialCliente}
                    enderecoEntrega={setEnderecoEntrega}
                    idCondPgto={idCondPgto}
                    setIdCondPgto={setIdCondPgto}
                    descontoComercial={setDescontoComercial}
                    condicaoPgto={setCondicaoPagamento}
                    prazoPgto={setPrazoPagamento}
                    statusPedido={statusPedido}
                    setStatusPedido={setStatusPedido}
                    isUpdate={isUpdate}
                    togglePedido={toggle}
                  />
                
              </GridItem>
            </div>

            <GridContainer id="cliente" 
              style={idCliente == "" ? ({display: 'none'}):(null)}
              >
              <GridItem xs={12} sm={12} md={6} >
                  <MDBInput 
                    label="Razão Social" 
                    value={razaoSocialCliente}
                    disabled
                  />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="Endereço de Entrega" 
                  value={enderecoEntrega}
                  disabled
                />
              </GridItem>
              
              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="CNPJ" 
                  value={cnpjCliente}
                  disabled
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Cond. PGTO." 
                  value={condicaoPagamento}
                  disabled
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Prazo PGTO." 
                  value={prazoPagamento}
                  disabled
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Desconto" 
                  value={descontoComercial}
                  disabled
                />
              </GridItem>

             
            </GridContainer>

            <div>
              <GridItem center 
                className="title-subinfo"
                style={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: '#ADC5B4', 
                  color: 'white', 
                  fontSize: '22px', 
                  marginLeft: '0', 
                  marginRight: '0', 
                  fontWeight: '600', 
                  marginBottom: '10px',
                  marginTop: '10px'
                }}>
                  {isUpdate ? (
                    <>Dados dos Consultores selecionados</>
                  ):(
                    listaConsultores.length == 0 ? (
                      <>Adicionar Lista de Consultores*</>
                    ):(
                      <>Alterar os Consultores*</>
                    )
                  )}
                  <ModalAddConsultorPedido 
                    listaConsultores={listaConsultores}
                    setListaConsultores={setListaConsultores}
                    idCondPgto={idCondPgto}
                    isUpdate={isUpdate}
                    quantidade={quantConsultor}
                    setQuantidade={setQuantConsultor}
                    setIdConsultores={setIdConsultores}
                  />
                
              </GridItem>
            </div>
            {listaConsultores.length > 0 ? (
              listaConsultores.map((consultor, idx) => {
                return(
                  <GridContainer key={consultor.value} >
                    <GridItem className="grid-item" xs={12} sm={12} md={2}>
                      <MDBInput 
                        label="" 
                        value={('Consultor '+(idx+1))}
                        disabled
                      />
                      </GridItem>
                    <GridItem className="grid-item" xs={12} sm={12} md={5}>
                      <MDBInput 
                        label="Nome" 
                        value={consultor.label}
                        disabled
                      />
                      </GridItem>
                      <GridItem className="grid-item" xs={12} sm={12} md={5}>
                        <MDBInput 
                          label="Email" 
                          value={consultor.email}
                          disabled
                        />
                      </GridItem>
                  </GridContainer>
                )
              })
              ):(null)}

            <GridItem center 
              className="title-subinfo"
              style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px',
                marginTop: '10px'
              }}>
                {isUpdate ? (
                    <>Dados do Fornecedor selecionado</>
                  ):(
                    idFornecedor == '' ? (
                      <>Adicionar o Fornecedor*</>
                    ):(
                      <>Alterar o Fornecedor*</>
                    )
                  )}
              <ModalAddFornecedorPedido 
                idFornecedor={idFornecedor}
                setIdFornecedor={setIdFornecedor}
                cnpjFornecedor={setCnpjFornecedor}
                razaoSocialFornecedor={setRazaoSocialFornecedor}
                frete={setFrete}
                ipi={setIpi}
                percIpi={setPercIpi}
                statusPedido={statusPedido}
                setStatusPedido={setStatusPedido}
                isUpdate={isUpdate}
                togglePedido={toggle}
                idPrazoEntrega={idPrazoEntrega}
                setIdPrazoEntrega={setIdPrazoEntrega}
                prazoEntregaDias={setPrazoEntregaDias}
                prazoEntregaObs={setPrazoEntregaObs}
              />
            </GridItem>

  
            <GridContainer id="fornecedor" style={idFornecedor == "" ? ({display: 'none'}):(null)}>
              <GridItem xs={12} sm={12} md={6}>
                  <MDBInput 
                    label="Razão Social" 
                    value={razaoSocialFornecedor}
                    disabled
                  />
              </GridItem>
              
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="CNPJ" 
                  value={cnpjFornecedor}
                  disabled
                />
              </GridItem>
              <GridItem className="grid-item" xs={12} sm={12} md={4}>
                <MDBInput 
                  label="Prazo Entrega" 
                  value={prazoEntregaDias}
                  disabled
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={4}>
                <MDBInput 
                  label="OBS Prazo Entrega" 
                  value={prazoEntregaObs}
                  disabled
                />
              </GridItem>
              <GridItem className="grid-item" xs={12} sm={12} md={4}>
                <MDBInput 
                  label="Percentual Ipi " 
                  value={percIpi}
                  disabled
                />
              </GridItem>
            </GridContainer>

            {<CrudItemPedido
              id={id}
              setId={setId}
              statusPedido={statusPedido}
              setStatusPedido={setStatusPedido}
              isUpdate={isUpdate}
              ipi={ipi}
              percIpi={percIpi}
              descontoComercial={descontoComercial}
              setIsCorpCliente={setIsCorpCliente}
              setValorTotal={setValorTotal}
              setValorFinal={setValorFinal}
              getValorTotal={getValorTotal}
              getItensPromoAndMecanismo={getItensPromoAndMecanismo}
              setError={setError}
              toggle={toggle}
              pedidosService={pedidosService}
              getItensPedidos={getItensPedidos}
              itensPedido={itensPedido}
              setItensPedidos={setItensPedidos}
              hasCreatedPedido={hasCreatedPedido}
              setHasCreatedPedido={setHasCreatedPedido}
            />}

            <GridItem center 
              className="title-subinfo"
              style={{ 
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px',
                marginTop: '10px'
              }}>
                {userGroup == 'Orcamento' ? (
                  <>
                    Dados do Orçamento
                  </>
                ):(
                  <>
                    Dados do pedido
                  </>
                )}
            </GridItem>

            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <InputLabel>
                  Data *
                </InputLabel>

                <MDBInput 
                  style={{marginTop: '-24px'}}
                  type="date" validate 
                  error="wrong" success="right"
                  placeholder="dd, mmm, yyyy"
                  value={dataPedido}
                  disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
                  onChange={input => setDataPedido(input.target.value)}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Número" 
                  value={numeroPedido}
                  disabled
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <InputLabel>
                  Status *
                </InputLabel>

                {userGroup == 'Orcamento' ? (
                  isUpdate ? (
                    <NativeSelect
                      value={statusPedido}
                      disabled
                      >                   
                      <option>Selecione o status</option>
                      <option value="1">Aguardando aprovação</option>
                      <option value="0">Orçamento</option>
                      <option value="7">Aguardando Informações</option>
                      <option value="2">Aguardando envio</option>
                      <option value="3">Em processamento</option>
                      <option value="4">Em lote</option>
                      <option value="5">Finalizado</option>
                      <option value="6">Cancelado</option>
                  </NativeSelect>
                  ):(
                    <NativeSelect
                      value={statusPedido}
                      onChange={e => 
                          setStatusPedido("0")
                      }
                      disabled
                      >                   
                      <option value="0">Orçamento</option>
                  </NativeSelect>
                  )
                ):( user.is_superuser ? (
                    <NativeSelect
                      value={statusPedido}
                      onChange={e => 
                          setStatusPedido(e.target.value)
                      }
                      >                   
                      <option>Selecione o status</option>
                      <option value="1">Aguardando aprovação</option>
                      <option value="0">Orçamento</option>
                      <option value="7">Aguardando Informações</option>
                      <option value="2">Aguardando envio</option>
                      <option value="3">Em processamento</option>
                      <option value="4">Em lote</option>
                      <option value="5">Finalizado</option>
                      <option value="6">Cancelado</option>
                  </NativeSelect>
                    ):(  condicaoPagamento=='Especial' || hasItemEspecial==true ? (
                          isAprove ? (
                            <NativeSelect
                            value={statusPedido}
                            onChange={e => 
                                setStatusPedido(e.target.value)
                              }>                   
                                  <option>Selecione o status</option>
                                  <option value="1">Aguardando aprovação</option>
                                  <option value="0">Orçamento</option>
                                  <option value="7">Aguardando Informações</option>
                                  <option value="2">Aguardando envio</option>
                                  <option value="3">Em processamento</option>
                                  <option value="4">Em lote</option>
                                  <option value="5">Finalizado</option>
                                  <option value="6">Cancelado</option>
                              </NativeSelect>
                          ):(
                            <NativeSelect
                              value={statusPedido}
                              onChange={e => 
                                setStatusPedido("1")
                              }
                              disabled
                              >                   
                              <option value="1">Aguardando aprovação</option>
                          </NativeSelect>
                        )) :
                        (
                        <NativeSelect
                          value={statusPedido}
                          onChange={e => 
                              setStatusPedido(e.target.value)
                          }
                          >                   
                          <option>Selecione o status</option>
                          <option value="1">Aguardando aprovação</option>
                          <option value="0">Orçamento</option>
                          <option value="7">Aguardando Informações</option>
                          <option value="2">Aguardando envio</option>
                          <option value="3">Em processamento</option>
                          <option value="4">Em lote</option>
                          <option value="5">Finalizado</option>
                          <option value="6">Cancelado</option>

                      </NativeSelect>
                    )
                                            
                  ))}
                
                
              </GridItem>              

              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Criado Por" 
                  value={criadoPor}
                  disabled
                />  
              </GridItem>

            </GridContainer>

            <GridContainer>
            <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="OC Cliente *" 
                  type="text" required 
                  error="wrong" success="right"
                  disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
                  value={ocCliente}
                  onChange={input => setOcCliente(mask.charAndText(input.target.value))}
                />
              </GridItem>
              
              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Autorizado Por" 
                  value={autorizadoPor}
                  disabled
                />  
              </GridItem>

              <GridItem className="grid-item" 
                xs={12} 
                sm={12} 
                md={3}
                style={statusPedido==="3" || statusPedido==="4" ? (null) : ({display: "none"})}
              >
                <MDBInput 
                  label="Nº de Confirmação da Fábrica *" 
                  type="text"  
                  error="wrong" success="right"
                  value={numConfFabrica}
                  disabled={statusPedido==="4" ? (true) : (false)}
                  onChange={input => setNumConfFabrica(input.target.value)}
                />
              </GridItem>
                
              <GridItem className="grid-item" xs={12} sm={12} md={3} 
              style={statusPedido==="4" ? (null) : ({display: "none"})}>
                <MDBInput 
                  label="Nota Fiscal" 
                  type="text"
                  error="wrong" success="right"
                  value={notaFiscal}
                  onChange={input => setNotaFiscal(input.target.value)}
                />
              </GridItem>
            </GridContainer>
          {/* Outras informações do Pedido */}             
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="Valor" 
                  type="text" validate 
                  error="wrong" success="right"
                  value={valorTotal}
                  disabled
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="Valor Final" 
                  type="text" validate 
                  error="wrong" success="right"
                  value={valorFinal}
                  disabled
                />
              </GridItem>
            </GridContainer>
            
            {userGroup == 'Orcamento' ? (
              <GridContainer>
                <GridItem className="grid-item" xs={12} sm={12} md={12}>
                  <MDBInput 
                    label="OBS do Consultor" 
                    type="textarea" validate 
                    value={observacoesConsultor}
                    onChange={input => setObservacoesConsultor(mask.charAndText(input.target.value))}
                    disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
                  />
                </GridItem>
              </GridContainer>
            ):(
              <GridContainer>
                <GridItem className="grid-item" xs={12} sm={12} md={12}>
                  <MDBInput 
                    label="OBS do Consultor" 
                    type="textarea" validate 
                    value={observacoesConsultor}
                    onChange={input => setObservacoesConsultor(mask.charAndText(input.target.value))}
                    disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
                  />
                </GridItem>
                <GridItem className="grid-item" xs={12} sm={12} md={12}>
                  <MDBInput 
                    label="Notas" 
                    type="textarea" validate 
                    value={observacoes}
                    onChange={input => setObservacoes(mask.charAndText(input.target.value))}
                    disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
                  />
                </GridItem>
              </GridContainer>
            )}      
              
						{hasCreatedPedido ? (
						<>
              <GridItem center 
                xs={12} sm={12} md={12}
                className="title-subinfo"
                style={{ 
                  backgroundColor: '#ADC5B4', 
                  color: 'white', 
                  fontSize: '22px', 
                  marginLeft: '0', 
                  marginRight: '0', 
                  fontWeight: '600', 
                  marginBottom: '10px',
                  marginTop: '10px'
                }}>
                  {userGroup == 'Orcamento' ? (
                  <>
                    PDF's do Orçamento
                  </>
                ):(
                  <>
                    PDF's do Pedido
                  </>
                )}                
              </GridItem>
              <GridContainer>
								<span style={{marginTop: "15px", marginLeft: '18px', overflow: "auto", display: "flex", fontSize: "12px",
									color: "rgb(255, 162, 26)"}}
									>
									ATENÇÃO: Clique ao lado para gerar os PDF's!</span>

                
							</GridContainer>

              {hasCondPgto ? (null):(
                <GridContainer>
								  <span style={{marginTop: "15px", marginLeft: '18px', overflow: "auto", display: "flex", fontSize: "12px",
                    color: "rgb(255, 162, 26)"}}
                    >
                    ATENÇÃO: Necessário Adicionar Condição de Pagamento!</span>
							  </GridContainer>   
              )}
							<MDBRow end style={{marginRight: '0px'}}>
							{<CreatePDFs
								pedido={pedido}
								itens={itensPedido}
								togglePedido={toggle}
								pedidosService={pedidosService}
                hasCondPgto={hasCondPgto}
								/>}
							</MDBRow>
						</>):(null)}  

            <GridItem center 
              xs={12} sm={12} md={12}
              className="title-subinfo"
              style={{ 
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px',
                marginTop: '10px'
              }}>
                {userGroup == 'Orcamento' ? (
                  <>
                    Anexo do  Orçamento
                  </>
                ):(
                  <>
                    Anexo do Pedido
                  </>
                )}
              
            </GridItem>       
            {userGroup == 'Orcamento' ? (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <span style={{marginTop: "15px", marginLeft: '5px', overflow: "auto", display: "flex", fontSize: "12px",
                    color: "rgb(255, 162, 26)"}}
                    >
                    ATENÇÃO: Outros anexos sem considerar o PDF do Orçamento!</span>
                  </GridItem>
                </GridContainer>
                ):(
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <span style={{marginTop: "15px", marginLeft: '5px', overflow: "auto", display: "flex", fontSize: "12px",
                      color: "rgb(255, 162, 26)"}}
                      >
                      ATENÇÃO: Outros anexos sem considerar o PDF do Pedido!</span>
                    </GridItem>
                  </GridContainer>
                )}                 
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={4}>
                <div>
                  <button className='btn' onClick={handleClick}> Selecionar Arquivo</button>
                  <input 
                    ref={hiddenFileInput}
                    style={{display: 'none'}}
                    id='input-file' 
                    type='file' 
                    name='myfile'
                    onChange={(e) => {
                      setFile(e.target.files[0])
                    }}  
                  />                
                </div>
              </GridItem>
              
              <GridItem className="grid-item" xs={12} sm={12} md={6} style={{marginTop: '12px', marginLeft: '-50px', marginRight: '50px'}}>
                {file !== "" && (
                  <>
                    <span id='file-name' >{file.name}</span>
                    {/* Checkbox para marcar se o arquivo é de confirmação */}
                    <div>
                      <label>
                        <input 
                          type="checkbox" 
                          checked={isConfirmation} 
                          onChange={(e) => setIsConfirmation(e.target.checked)} 
                        />
                        É um arquivo de confirmação?
                      </label>
                    </div>
                  </>
                )}
              </GridItem>
              
              <GridItem className="grid-item" xs={12} sm={12} md={1} style={{marginTop:'-14px'}}>
                <Button 
                  className='btn-email'
                  variant="contained" 
                  size="sm" 
                  disabled={file === ''} 
                  style={{backgroundColor: 'green', margin: '20px', marginBottom: '-45px'}} 
                  onClick={handleAddFile}
                >
                  <MDBIcon icon="plus" className='icon-email'/>
                </Button> 
              </GridItem>   
            </GridContainer>

            {fileList.length > 0 ? (
  <>
    <GridItem
      center
      className="title-subinfo"
      style={{
        backgroundColor: '#ADC5B4',
        color: 'white',
        fontSize: '22px',
        marginLeft: '0',
        marginRight: '0',
        fontWeight: '600',
        marginBottom: '10px',
        marginTop: '10px',
      }}
    >
      Anexos Adicionados
    </GridItem>
    <MDBContainer style={{ marginTop: '-30px' }}>
      {fileList.map((file, idx) => (
        <ul key={idx}>
          <li>
            <MDBRow style={{ marginTop: '20px', marginLeft: '7px' }}>
              {isUpdate && file.anexo ? (
                // Caso seja um arquivo vindo do banco de dados
                <>
                  <MDBCol md={8}>
                    <div className="row">
                      <a
                        className="link-anexo"
                        href="#"
                        onClick={async (e) => {
                          e.preventDefault();
                          const response = await fetch(file.anexo);
                          const blob = await response.blob();
                          downloadBlob(blob, file.filename); // Usa a função de download
                        }}
                      >
                        {file.filename}
                      </a>
                    </div>
                  </MDBCol>
                  <MDBCol md={2}>
                    <div className="row">Tipo: {file.type}</div>
                  </MDBCol>
                  <MDBCol md={1}>
                    <Button
                      size="sm"
                      variant="contained"
                      style={{ backgroundColor: 'orangered' }}
                      onClick={() => {
                        toggle();
                        setFileForDel(file);
                        toggleDeleteFile();
                      }}
                    >
                      <MDBIcon icon="trash" />
                    </Button>
                  </MDBCol>
                </>
              ) : (
                // Caso seja um arquivo novo adicionado na tela
                <>
                  <MDBCol md={8}>
                    <div className="row">
                      <span>{file.file.name}</span>
                    </div>
                  </MDBCol>
                  <MDBCol md={2}>
                    <div className="row">
                      <input
                        type="checkbox"
                        checked={file.isConfirmation || false}
                        onChange={(e) => {
                          const updatedFile = { ...file, isConfirmation: e.target.checked };
                          const newFileList = [...fileList];
                          newFileList[idx] = updatedFile;
                          setFileList(newFileList);
                        }}
                      />
                      Confirmação
                    </div>
                  </MDBCol>
                  <MDBCol md={1}>
                    <Button
                      size="sm"
                      variant="contained"
                      style={{ backgroundColor: 'orangered' }}
                      onClick={() => {
                        toggle();
                        setFileForDel(file);
                        toggleDeleteFile();
                      }}
                    >
                      <MDBIcon icon="trash" />
                    </Button>
                  </MDBCol>
                </>
              )}
            </MDBRow>
          </li>
        </ul>
      ))}
    </MDBContainer>
  </>
) : null}

          
          </div>
        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>

          <MDBModalFooter>
            {isUpdate ? (
               userGroup == 'Orcamento' ? (
                <Button disabled={checkValuesPedido()} variant="contained" onClick={confirmUpdate} >
                  Atualizar Orçamento
                </Button>
              ):(
                <Button disabled={checkValuesPedido()} variant="contained" onClick={confirmUpdate} >
                  Atualizar Pedido
                </Button>
              )
              
            ): (   
              userGroup == 'Orcamento' ? (
                <Button variant="contained" onClick={handleSubmit}>
                  Registrar Orçamento
                </Button>
              ):(
                <Button variant="contained" onClick={handleSubmit}>
                  Registrar Pedido
                </Button>
              )     
             
            )} 
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>

      {/* Modal de verificação do delete do pedido*/}
      {modalDelete ? (
        <MDBModal
          isOpen={modalDelete}
          toggle={toggleDelete}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir esse pedido?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Número do Pedido: {pedido.n_pedido}
                  </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Data do Pedido: {pedido.data_pedido}
                  </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Consultor: {pedido.consultor.nome}
                  </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                <span>
                  Fornecedor: {pedido.fornecedor.razao_social}
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                <span>
                  Cliente: {pedido.cliente.razao_social}
                </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDelete}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelete(pedido.id)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}
      
      {/* Modal de apagar anexo */}
      {/* Modal de verificação do delete do email*/}
      {modalDeleteFile ? (
        <MDBModal
          isOpen={modalDeleteFile}
          toggle={toggleDeleteFile}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir esse arquivo?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                  Arquivo: {fileForDel.anexo ? (fileForDel.filename):(fileForDel.name)}
                  </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={() => 
                {toggleDeleteFile() 
                toggle()}}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelFile(fileForDel)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}

        <CardHeader 
          className="header-table" 
          style={{ backgroundColor: '#B19B80', color: 'white' }}>
          {userGroup == 'Orcamento' ? (  
            <h4 className="title-table">Gerencie seus Orçamentos</h4>
          ):(
            <h4 className="title-table">Gerencie seus Pedidos</h4>             
          )}

        <div style={{alignContent: 'end', display: 'flex', flexDirection: 'row'}}>
          <AiOutlineFileSync 
            title={getTitle('loader')}
            className="btn-manage"
            style={{ height: '24px', color: '#F6EDD2', width: '24px', marginLeft: '10px' }}
            onClick={() => {
              if (user!==""){
                if (user.is_superuser || userGroup=='Orcamento'){
                  getPedidos()
                }
              }
              setIsLoading(true)  
            }}
          />

          { <ModalFilterPedidoByField 
            pedidosService={pedidosService}
            setPedidos={setPedidos}
            setIsLoading={setIsLoading}
            setIsViewTable={setIsViewTable}
          /> }

          <BiSolidAddToQueue 
            title={getTitle('add')}
            className="btn-manage"
              onClick={() => {
                toggle()
                clearState()
              }}
            style={{ height: '24px', color: '#F6EDD2', width: '24px', marginLeft: '10px' }}
          />
          {userGroup == 'Orcamento' ? (  
            null
          ):(
            <>             
            {user.is_superuser ? (
              <>
                <ReportOrderPrint /> 
              </>
              ):(
                null
              )}
            </>
          )}
        </div>
        </CardHeader>
      				
      {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ):(null)}
			{isViewTable ? (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
              {userGroup == 'Orcamento' ? (
                <CardHeader className="header-table" style={{ backgroundColor: '#ADC5B4', color: 'white' }}>
                <h4 className="title-table">Lista de Orçamentos</h4>
                <p className="p">
                  Adicione e edite os orçamentos.
                </p>
                </CardHeader>
              ):(
                <CardHeader className="header-table" style={{ backgroundColor: '#ADC5B4', color: 'white' }}>
                <h4 className="title-table">Lista de Pedidos</h4>
                  <p className="p">
                    Adicione e edite os pedidos.
                  </p>
                </CardHeader>
              )}
            <CardBody>
              <Table
              modelos={pedidos}
              handleUpdate={handleUpdate}
              toggleDelete={toggleDelete}
              setModelo={setPedido}
              setPedidos={setPedidos}
              pedidosService={pedidosService}
              tipo={tipo}
              getAnexosByPedido={getAnexosByPedido}
              getPedidos={getPedidos}
              isLoadingPedidos={setIsLoading}
              setStatusPedido={setStatusPedido}
              />
						</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
			):(null)}
    </div>
  )
}