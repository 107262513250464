import React, { useEffect, useState } from 'react';
import {
  MDBContainer,
  MDBModal,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
} from 'mdbreact';
import { Button } from '@mui/material'
import ReactLoading from 'react-loading';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';
import Table from './Table'

import ComissoesService from '../../services/comissoes/comissoes'
import { GetAllPrincipalComponent } from '../GetAll';

import './styles.css'

export default function CrudComissoes() {
	const [isLoading, setIsLoading] = useState(false)
	const comissaoService = new ComissoesService()

	const [comissoes, setComissoes] = useState([])
	const [error, setError] = useState('')   

	function getComissoes(){
    GetAllPrincipalComponent(setComissoes, setError, comissaoService, setIsLoading)
	}
	console.log(comissoes)

	return (
		<div>

			<GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
              
						<Button 
							variant="contained"
							onClick={() => {
								getComissoes()
								setIsLoading(true) 
							}}>
								Carregar Comissões
						</Button>
          </Card>
        </GridItem>
      </GridContainer>
				
      {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ):(
				<GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader className="header-table" color='warning'>
              <h4 className="title-table">Lista de Comissões</h4>
            </CardHeader>
            <CardBody>
							<Table 
								modelos={comissoes}
							/>
						</CardBody>
          </Card>
        </GridItem>
      </GridContainer>
			)}
		</div>
	)
}