import React from 'react';

// Elemento Read:
import CrudConsultores from '../../components/Consultores/index';

export default function Consultores() {
  return (
    <div>
      <CrudConsultores />
    </div>
  )
};
