import React from 'react';

export const formatMonetaryValue = (value) => {
    // Se o valor for igual a 0, retornar '0,00'
    if (value === 0) {
        return '0,00';
    }
  
    value =  parseFloat(value)
    let formattedValue = value.toLocaleString('pt-BR', { 
        style: 'currency', 
        currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
    return formattedValue
  };