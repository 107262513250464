import React from 'react';

// Elemento Read:
import FilesForProducts from '../../../components/Produtos/Arquivos/index.js';

export default function Arquivos() {
  
  return (
    <div>
      <FilesForProducts />
    </div>
  )
};
