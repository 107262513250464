import React, { useState } from 'react';

import {
  MDBContainer,
  MDBModal,
  MDBCol,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
} from 'mdbreact';

import { Button } from '@mui/material';
import { ImInfo } from "react-icons/im";
import { CiEdit } from "react-icons/ci";
import { BiSolidAddToQueue } from "react-icons/bi";
import { FaTrashAlt } from "react-icons/fa";
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import ReactLoading from 'react-loading'

import GridItem from '../../../Grid/GridItem';
import GridContainer from '../../../Grid/GridContainer';
import Card from '../../../Card/Card';
import Masks from "../../../Masks/Masks";
import { useAuthDataContext } from '../../../../services/auth/AuthContext';

import { 
  formatMedidas, 
  getEspecialByPadr, 
  getEspecialByIsEspecial,
  redirectToProduct,
} from '../../../Formatter/produtos';

import { 
  formatMonetaryValue
} from '../../../Formatter/monetaryValues';

import ModalFilterProdutoByField from '../../../Produtos/Modals/filterProdutoByField';

import ProdutosService from '../../../../services/produtos/produtos';
import RevestimentosService from '../../../../services/revestimentos/revestimentos';
import ItensPedidoService from '../../../../services/pedidos/itenspedido/itenspedido';
import TableProdutos from '../../TableProdutos';

export default function CrudItemPedido(props) {
  const {
    id,
		setId,
		statusPedido,
		setStatusPedido,
		isUpdate,
		ipi,
		percIpi,
		descontoComercial,
		setIsCorpCliente,
		setValorTotal,
		setValorFinal,
		getValorTotal,
		getItensPromoAndMecanismo,
		setError,
		toggle,
		pedidosService,
		getItensPedidos,
		itensPedido,
		setItensPedidos,
    hasCreatedPedido,
    setHasCreatedPedido
  } = props;
	const mask = new Masks()
  const { user, userGroup } = useAuthDataContext();

  /////////// ITEM DO PEDIDO ///////////  
  // Informações para form itemPedido:
  const produtosService = new ProdutosService()
  const revestimentosService = new RevestimentosService()
  const itensPedidoService = new ItensPedidoService()

  const [isLoadingProduto, setIsLoadingProduto] = useState(false)
	const [isViewTableProduto, setIsViewTableProduto] = useState(false)
  // Modais e informações para item pedido:
  const [isUpdateItemPedido, setIsUpdateItemPedido] = useState(false)
  const [modalDeleteItemPedido, setModalDeleteItemPedido] = useState(false)
  const [modalItemPedido, setModalItemPedido] = useState(false)
  const [itemPedido, setItemPedido] = useState('')
  const [addItem, setAddItem] = useState([])
  const [hasItemEspecial, setHasItemEspecial] = useState(false)
  // Bloco de variáveis para Itens Pedido 
  const [idItemPedido, setIdItemPedido] = useState('')
  const [idProduto, setIdProduto] = useState('')
  const [idRevestimento, setIdRevestimento] = useState('')
  const [acabamentoPrim, setAcabamentoPrim] = useState('')
  const [acabamentoSec, setAcabamentoSec] = useState('')
  const [acabamentoTer, setAcabamentoTer] = useState('')
  const [quantidade, setQuantidade] = useState('')
  const [valorUnidade, setValorUnidade] = useState('')
  const [valorBase, setValorBase] = useState('')
  const [idxItemPedido, setIdxItemPedido] = useState('')
  // Variáveis de item pedido que vem de produto para preenchimento ao selecionar um produto:
  const [nomeProduto, setNomeProduto] = useState('')
  const [largura, setLargura] = useState('')
  const [profundidade, setProfundidade] = useState('')
  const [altura, setAltura] = useState('')
  const [imagem, setImagem] = useState('')
  const [tecido, setTecido] = useState('')
  const [isPromo, setIsPromo] = useState(false)
  // const [isCorpProduto, setIsCorpProduto] = useState(false)
  const [isEspecial, setIsEspecial] = useState(true)
  const [isMecanismo, setIsMecanismo] = useState(false)
  const [padronagem, setPadronagem] = useState('')
  const [observacoesItemPedido, setObservacoesItemPedido] = useState('')

  const [produto, setProduto] = useState('')
  const [produtos, setProdutos] = useState([])

  // Conexão entre produtos e seus revestimentos:
  const [revestimentos, setRevestimentos] = useState([])
  const [nomeRevestimento, setNomeRevestimento] = useState('')
  
  // Pegando revestimentos conforme produto selecionado:
  const getRevestimentos = (id) => {  
    let form_data = new FormData()
    form_data.append('id', id)
    revestimentosService.getForIdProdutos(form_data)
      .then(res => {
        setRevestimentos(res.data)
      })
  }
  const showRevestimentos = revestimentos.map((data, idx) => {
    return (
      <option key={idx} value={data.id}>{data.nome}</option>
    )
  })

  // Setando o valor da unidade:
  const getValorUnidade = (idx, value) => {
    const selectedRevestimento = revestimentos[idx - 1]
    if ( 
      value != "Selecione o Revestimento"
      ) {
      setNomeRevestimento(selectedRevestimento.nome)
      setValorUnidade(selectedRevestimento.valor)
      setValorBase(selectedRevestimento.valor)
    }
  }

  // Lógica para visualizar ou não os campos de produto:
  const handleChangeProduto = (modelo) => {
    setIsEspecial(false)
    setIdRevestimento("")
    // setIsCorpProduto("")
    setQuantidade("")
    setValorUnidade("")
    setValorBase("")
    setAcabamentoPrim("")
    setAcabamentoSec("")
    setAcabamentoTer("")
    setObservacoesItemPedido("")
    setAltura("")

    if (modelo!="") {
      if (modelo.padronagem=='especial') {
          document.getElementById("special").style.display = "none"
        } else {
          document.getElementById("special").style.display = null
        }
    }
    if (modelo!="") { 
      setIdProduto(modelo.id)
      setNomeProduto(modelo.nome)
      setLargura(modelo.largura)
      setProfundidade(modelo.profundidade)
      setAltura(modelo.altura)
      setPadronagem(modelo.padronagem)
      setTecido(modelo.tecido)
      setImagem(modelo.imagem)
      setIsPromo(modelo.is_promo)
      setIsMecanismo(modelo.is_mecanismo)
      // setIsCorpProduto(modelo.is_corp)
      if (modelo.obs==null) {
        setObservacoesItemPedido("")
      } else {
        setObservacoesItemPedido(modelo.obs)
      }
      
      document.getElementById('produto').style.display = null
    } else {
      setIdProduto("")
      setNomeProduto("")
      setLargura("")
      setProfundidade("")
      setTecido("")
      setAltura("")
      setImagem("")
      setIsPromo("")
      setIsMecanismo("")
      // setIsCorpProduto("")
      setPadronagem("")
      setObservacoesItemPedido("")
      document.getElementById('produto').style.display = 'none'
    }   
  } 

  // Toggle:
  const toggleItemPedido = () => {
    setModalItemPedido(!modalItemPedido)
  }
  const toggleDeleteItemPedido = () => {
    setModalDeleteItemPedido(!modalDeleteItemPedido)
  }

  // Update Item pedido com o pedido já criado:
  const handleUpdateItemPedidoWithPedidoCreated = (item) => {
    getRevestimentos(item.idProduto)
    setIdProduto(item.idProduto)
    setId(item.idPedido)
    setIdItemPedido(item.idItemPedido)
    setIdRevestimento(item.revestimento)
    setNomeRevestimento(item.nomeRevestimento)
    setAcabamentoPrim(item.acabamento1)
    setAcabamentoSec(item.acabamento2)
    setAcabamentoTer(item.acabamento3)
    setQuantidade(item.quantidade)
    setValorBase(item.valor)
    setValorUnidade(item.valor)

    setNomeProduto(item.nome)
    setLargura(item.largura)
    setProfundidade(item.profundidade)
    setAltura(item.altura)
    setImagem(item.imagem)
    setTecido(item.tecido)
    setIsPromo(item.isPromo)
    setIsCorpCliente(item.isCorp)
    setIsEspecial(false)
    setIsMecanismo(item.isMecanismo)
    setPadronagem(item.padronagem)
    setObservacoesItemPedido(item.obs)

    toggleItemPedido()
  }

  const confirmUpdateItemPedidoWithPedidoCreated = () => {
    const itemPedido = {
      "idProduto": idProduto,
      "idPedido": id,
      "idItemPedido": idItemPedido,
      "nome": nomeProduto,
      "largura": largura,
      "altura": altura,
      "tecido": tecido,
      "profundidade": profundidade,
      "isPromo": isPromo,
      "isMecanismo": isMecanismo,
      "isEspecial": isEspecial,
      // "isCorp": isCorpProduto,
      "imagem": imagem,
      "revestimento": idRevestimento,
      "nomeRevestimento": nomeRevestimento,
      "acabamento1": acabamentoPrim,
      "acabamento2": acabamentoSec,
      "acabamento3": acabamentoTer,
      "quantidade": quantidade,
      "valorUnidade": valorUnidade, 
      "padronagem": padronagem,
      "observacoes": observacoesItemPedido,
    }
    try {    
      itensPedidoService.update(itemPedido.idItemPedido, itemPedido)
      .then(res => {
        if (res.status === 200) {
          alert("Item atualizado com sucesso!!") 
          toggleItemPedido()
          getItensPedidos(itemPedido.idPedido)
        }
      })
    } catch(error) {
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }
  }

  // Update Item pedido sem o pedido já criado:
  const handleUpdateItemPedido = (item, idx) => {
    getRevestimentos(item.idProduto)
    
    setIdxItemPedido(idx)
    setIdProduto(item.idProduto)
    setIdRevestimento(item.revestimento)
    setNomeRevestimento(item.nomeRevestimento)
    setAcabamentoPrim(item.acabamento1)
    setAcabamentoSec(item.acabamento2)
    setAcabamentoTer(item.acabamento3)
    setQuantidade(item.quantidade)
    setValorUnidade(item.valorUnidade)

    setNomeProduto(item.nome)
    setLargura(item.largura)
    setProfundidade(item.profundidade)

    setAltura(item.altura)
    setImagem(item.imagem)
    setTecido(item.tecido)
    setIsPromo(item.isPromo)
    setIsEspecial(item.isEspecial)
    setIsMecanismo(item.isMecanismo)
    setIsCorpCliente(item.isCorp)
    setPadronagem(item.padronagem)
    setObservacoesItemPedido(item.observacoes)
    toggleItemPedido()
  }

  const confirmUpdateItemPedido = () => {
    const newItemPedido = {
      "idProduto": idProduto,
      "nome": nomeProduto,
      "largura": largura,
      "altura": altura,
      "tecido": tecido,
      "profundidade": profundidade,
      "isPromo": isPromo,
      "isMecanismo": isMecanismo,
      "isEspecial": isEspecial,
      // "isCorp": isCorpProduto,
      "imagem": imagem,
      "revestimento": idRevestimento,
      "nomeRevestimento": nomeRevestimento,
      "acabamento1": acabamentoPrim,
      "acabamento2": acabamentoSec,
      "acabamento3": acabamentoTer,
      "quantidade": quantidade,
      "valorUnidade": valorUnidade, 
      "valorBase": valorBase,
      "padronagem": padronagem,
      "observacoes": observacoesItemPedido,
    }
    itensPedido[idxItemPedido] = newItemPedido

    setValorTotal(getValorTotal(itensPedido))
   
    toggleItemPedido()    
  }
  // Create  
  // Lógica de montagem de uma lista criar um pedido em situações em que o pedido já foi criado:
  const handleSubmitItemPedidoWithCreatedPedido = (idPedido) => {
    const itemPedido = {
      "idPedido": idPedido,
      "idProduto": idProduto,
      "nome": nomeProduto,
      "largura": largura,
      "altura": altura,
      "tecido": tecido,
      "profundidade": profundidade,
      "isPromo": isPromo,
      "isMecanismo": isMecanismo,
      "isEspecial": isEspecial,
      // "isCorp": isCorpProduto,
      "imagem": imagem,
      "revestimento": idRevestimento,
      "nomeRevestimento": nomeRevestimento,
      "acabamento1": acabamentoPrim,
      "acabamento2": acabamentoSec,
      "acabamento3": acabamentoTer,
      "quantidade": quantidade,
      "valorUnidade": valorUnidade,
      "padronagem": padronagem,
      "observacoes": observacoesItemPedido,
    }
    try {
      itensPedidoService.create(itemPedido)
      .then(res => {
        // alert("Item Pedido criado com sucesso!!")
        toggleItemPedido()
        getItensPedidos(itemPedido.idPedido)
      })
    } catch (erro) {
      setError(erro)
    }

  }

  // Lógica de montagem de uma lista para visualização ao clickar no botão adicionar pedido:
  const handleAddItemPedido = () => {
    const newItemPedido = {
      "idProduto": idProduto,
      "nome": nomeProduto,
      "largura": largura,
      "altura": altura,
      "tecido": tecido,
      "profundidade": profundidade,
      "isPromo": isPromo,
      "isMecanismo": isMecanismo,
      "isEspecial": isEspecial,
      // "isCorp": isCorpProduto,
      "imagem": imagem,
      "revestimento": idRevestimento,
      "nomeRevestimento": nomeRevestimento,
      "acabamento1": acabamentoPrim,
      "acabamento2": acabamentoSec,
      "acabamento3": acabamentoTer,
      "quantidade": quantidade,
      "valorUnidade": valorUnidade, 
      "padronagem": padronagem,
      "observacoes": observacoesItemPedido,
    }
    addItem.push(newItemPedido)
    if (isEspecial && !user.is_superuser) {
      setHasItemEspecial(true); // Atualiza o estado para true
      setStatusPedido("1")
    }
    if (userGroup == 'Orcamento') {
      setStatusPedido('0')
    }
    // Alteranto valor final e total ao alterar um item:
    setValorTotal(getValorTotal(addItem))
    if ((addItem.length > 0) && (ipi!='') && (percIpi!='')) {
      setValorFinal(getItensPromoAndMecanismo(descontoComercial, ipi, percIpi, addItem))
    } 
    return(addItem)
  }
   
  // Delete:
  // Apaga o item de um pedido em situações em que o mesmo já foi criado, ou seja quando ele está cadastrado no backend:
  const handleDeleteItemPedido = (itemPedido) => {
    itensPedidoService.delete(itemPedido.idItemPedido)
    .then(res =>{
      if (res.status === 200) {
        toggleDeleteItemPedido()
        // alert("Pedido apagado com sucesso!!")
        getItensPedidos(itemPedido.idPedido) 
               
        }
    })
  }
  
  // Apaga o item de um pedido em situações em que o mesmo não foi criado, ou seja quando ele NÃO está cadastrado no backend:
  const deleteItemWithoutCreatedPedido = (item) =>{
    itensPedido.splice(itensPedido.indexOf(item), 1)
    setValorTotal(getValorTotal(itensPedido))
  	setValorFinal(getItensPromoAndMecanismo(descontoComercial, ipi, percIpi, itensPedido))
    toggleDeleteItemPedido()
    // alert("Pedido apagado com sucesso!!")
  }

  // const getDisabledSelectStatusPedido = () => {
  //   console.log(hasCreatedPedido)
  //   if (hasCreatedPedido==true) {
  //     setHasItemEspecial(itensPedido.some(item => item.padronagem === true))
  //   } else {
  //     setHasItemEspecial(itensPedido.some(item => item.isEspecial === true))
  //   } 
  //   if (condicaoPagamento=="Especial") {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // Lógica de checagem dos campos obrigatório do item pedido:
  const checkValuesItemPedido = () => {
  
    if (
      idProduto=="" ||
      quantidade=="" ||
      idRevestimento==" " ||
      idRevestimento=="Selecione o Revestimento" ||
      quantidade=="" ||
      valorUnidade==""
    ) {
      return true
    } else {
      if (
        quantidade < 1
      ) {
        return true
      } else {
        return false 
      }
    }
  } 

  // Limpa os estados ao clickar no botão adicionar item Pedido
  const clearStateItemPedido = () => { 
    setProduto("")
    setNomeProduto("")
    setLargura("")
    setAltura("")
    setTecido("")
    setProfundidade("")
    setIdRevestimento("")
    setAcabamentoPrim("")
    setAcabamentoSec("")
    setAcabamentoTer("")
    setQuantidade("")
    setValorUnidade("")
    setValorBase("")
    setImagem("")
    setPadronagem("")
    setObservacoesItemPedido("")
    setIdxItemPedido("")
    setIsEspecial(false)
    setIsPromo(false)
    setIsMecanismo(false)
    // setIsCorpProduto("")
  }

  // Seta o produto selecionado e suas medidas quando determina que é especial
  const getProdutoSelected = () => {
    setQuantidade("")
    setIdRevestimento("")
    setNomeRevestimento("")
    setValorUnidade("")

    if (produto!=''){
      setNomeProduto(produto.nome)
      setLargura(produto.largura)
      setProfundidade(produto.profundidade)
      setAltura(produto.altura)
    }
  }
 
  
  return (
    <>

			{/* Modal para adicionar produto */}
      <MDBModal 
        isOpen={modalItemPedido} 
        toggle={toggleItemPedido} 
        size="lg" 
        disableBackdrop
      >
        <MDBRow center>    
          <MDBModalHeader className="modal-header">Adicione novo item do pedido</MDBModalHeader>
        </MDBRow>

        {/* Selecionando o item/produto */}
        <MDBModalBody>
          <div className="info-form">
            <GridContainer >
                <GridItem xs={12} sm={12} md={3}>
                <p className="p-form">Item do Pedido</p>
                </GridItem>
            </GridContainer>

            <GridItem center
              className="title-subinfo"
              style={{
                backgroundColor: '#ADC5B4',
                color: 'white',
                fontSize: '22px',
                marginLeft: '0',
                marginRight: '0',
                fontWeight: '600',
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Filtre por Produtos
            </GridItem>
            <div className='actions-pedido'>
              <GridItem xs={12} sm={12} md={3}>
                <Card>
                  {<ModalFilterProdutoByField
                    produtosService={produtosService}
                    setProdutos={setProdutos}
                    setIsLoading={setIsLoadingProduto}
                    setIsViewTable={setIsViewTableProduto}
                  />}
                </Card>
              </GridItem>
            </div>
            
            {isLoadingProduto ? (
              <MDBRow center>
                <ReactLoading type="bars" color="black" height={400} width={150} />
              </MDBRow>
              ) : (null)}
            {isViewTableProduto ? (
              <>

              <GridItem center
                className="title-subinfo"
                style={{
                  backgroundColor: '#ADC5B4',
                  color: 'white',
                  fontSize: '22px',
                  marginLeft: '0',
                  marginRight: '0',
                  fontWeight: '600',
                  marginBottom: '10px',
                  marginTop: '10px'
                }}>
                Lista por Produtos
              </GridItem>
              
              <TableProdutos
                modelos={produtos}
                setModelo={setProduto}
                handleChange={handleChangeProduto}
                getRevestimentos={getRevestimentos}

              />
              </>
            ) : (null)}  
            
            {/* div para uma melhor visualização do item selecionado */}
            <div 
              id="produto" 
              style={isUpdateItemPedido ? (null) : ({display: "none"})}
              > 
                <GridItem center 
                  className="title-subinfo"
                  style={{ 
                    backgroundColor: '#ADC5B4', 
                    color: 'white', 
                    fontSize: '22px', 
                    marginLeft: '0', 
                    marginRight: '0', 
                    fontWeight: '600', 
                    marginBottom: '10px'
                  }}>
                  Item Selecionado
                </GridItem>
              
                <GridContainer>
                  <GridItem className="grid-item" xs={12} sm={12} md={9}>
                
                  <MDBInput 
                    label="Nome" 
                    value={nomeProduto}
                    disabled={isEspecial || padronagem=='especial' ? (false) : (true)} 
                    onChange={(e) => setNomeProduto(mask.charAndText(e.target.value))}        
                  />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3} id="special" style={itemPedido.padronagem=="especial" ? ({display: "none"}) : (null)}>
                    <label style={{marginRight: '15px', marginTop: '30px'}}>
                      Item é Especial?
                    </label>

                    <input type="checkbox"
                      checked={isEspecial}
                      onChange={() => {
                        getProdutoSelected()
                        setIsEspecial(!isEspecial)}
                      }
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem className="grid-item" xs={12} sm={12} md={3}>
                    <MDBInput 
                      label="Tecido" 
                      value={tecido}
                      disabled
                    />
                  </GridItem>

                  <GridItem className="grid-item" xs={12} sm={12} md={3}>
                      <MDBInput
                        type="number"  
                        min="0.1"
                        step="0.1" 
                        label="Profundidade" 
                        value={profundidade}
                        disabled={isEspecial || padronagem=='especial' ? (false) : (true)}
                        onChange={(e) => setProfundidade(mask.formatDescontoAndComissao(e.target.value))}
                      />
                    </GridItem>

                  <GridItem className="grid-item" xs={12} sm={12} md={3}>
                    <MDBInput 
                      type="number"  
                      min="0.10"
                      step="0.10"
                      label="Largura" 
                      value={largura}
                      disabled={isEspecial || padronagem=='especial' ? (false) : (true)}
                      onChange={(e) => setLargura(mask.formatDescontoAndComissao(e.target.value))}
                    />
                  </GridItem>

                  <GridItem className="grid-item" xs={12} sm={12} md={3}>
                    <MDBInput 
                      type="number"  
                      min="0.1"
                      step="0.1"
                      label="Altura" 
                      value={altura}
                      disabled={isEspecial || padronagem=='especial' ? (false) : (true)}
                      onChange={(e) => setAltura(mask.formatDescontoAndComissao(e.target.value))}
                    />
                  </GridItem>

                  <GridItem className="grid-item" xs={12} sm={12} md={5}>
                    <img width="150px" heigth="150px" src={imagem}/>
                  </GridItem>
                </GridContainer>             
            </div>
            <GridItem center 
              className="title-subinfo"
              style={{ 
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px'
              }}>
              Especificações do Item
            </GridItem>

            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={isEspecial ? (3) : (4)}>
                <MDBInput 
                  label="REVESTIMENTO" 
                  type="text"  
                  error="wrong" success="right"
                  value={acabamentoPrim}
                  onChange={input => setAcabamentoPrim(mask.charAndText(input.target.value))}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={isEspecial ? (3) : (4)}>
                <MDBInput 
                  label="PINTURA / LAMINAÇÃO" 
                  type="text"  
                  error="wrong" success="right"
                  value={acabamentoSec}
                  onChange={input => setAcabamentoSec(mask.charAndText(input.target.value))}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={isEspecial ? (3) : (4)}>
                <MDBInput 
                  label="COUROS / RECOUROS" 
                  type="text"  
                  error="wrong" success="right"
                  value={acabamentoTer}
                  onChange={input => setAcabamentoTer(mask.charAndText(input.target.value))}
                />
              </GridItem>    

              {isEspecial ? (
                <GridItem xs={12} sm={12} md={3}>
                  <MDBInput 
                    label="Valor Base" 
                    value={valorBase}
                    disabled
                  />
                </GridItem>
              ) : (null)}
                       
            </GridContainer>
            
            <GridContainer>

              <GridItem xs={12} sm={12} md={4} >
                <InputLabel shrink className="mt-2 mb-2">
                  Revestimento *
                </InputLabel>
                <NativeSelect
                  defaultValue={idRevestimento}
                  value={idRevestimento}
                  onChange={e => {
                    setIdRevestimento(e.target.value)
                    getValorUnidade(e.target.options.selectedIndex, e.target.value)                    
                  }}
                > 
                  <option>Selecione o Revestimento</option>
                  {showRevestimentos}
                </NativeSelect>
              </GridItem>

              <GridItem className="grid-item" 
                xs={12} 
                sm={12} 
                md={4} 
              >
                <MDBInput 
                  id="valor-unidade"
                  type="number" 
                  min="0.1"
                  step="0.1"
                  label="Valor Unitário (em R$)*" 
                  value={valorUnidade} 
                  disabled={isEspecial || padronagem=='especial' ? (false) : (true)} 
                  onChange={(e) => setValorUnidade(e.target.value)}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={4} >
                <MDBInput 
                  label="Quantidade *" 
                  required
                  type="number"  
                  min="1"
                  step="1"
                  error="wrong" success="right"
                  value={quantidade}
                  onChange={(e) => setQuantidade(mask.number(e.target.value))}
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <MDBInput
                  validate
                  label="Observações"
                  hint={observacoesItemPedido}
                  type="textarea"
                  value={observacoesItemPedido}
                  onChange={e => setObservacoesItemPedido(mask.charAndText(e.target.value))}
                />
              </GridItem>
            </GridContainer>   
          </div>           

          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={() => {
              toggleItemPedido()
              }}>Cancelar
            </Button>
          </MDBModalFooter>
          
          <MDBModalFooter>
            {isUpdateItemPedido ? 
              (hasCreatedPedido ? 
                (
                  <Button variant="contained"
                  disabled={checkValuesItemPedido()} 
                  onClick={() => {
                  confirmUpdateItemPedidoWithPedidoCreated(id)
                  }}>
                    Atualizar Item
                  </Button> 
                ) 
                : 
                (
                  <Button 
                    variant="contained" 
                    disabled={checkValuesItemPedido()}
                    onClick={() => {
                      confirmUpdateItemPedido()
                  }}
                  >
                    Atualizar Item
                  </Button> 
                )
              ) : 
              (hasCreatedPedido ? 
                (
                  <Button variant="contained"
                  disabled={checkValuesItemPedido()} 
                  onClick={() => {
                  handleSubmitItemPedidoWithCreatedPedido(id)
                  toggleItemPedido()
                  }}>
                    Adicionar Item
                  </Button>
                ) 
               : 
                (
                  <Button 
                  variant="contained" 
                  disabled={checkValuesItemPedido()}
                  onClick={() => {
                    setItensPedidos(handleAddItemPedido())
                    toggleItemPedido()
                }}
                  >
                    Adicionar Item
                  </Button> 
                )
              )
            } 
          </MDBModalFooter>
        </MDBRow>

      </MDBModal>

      {/* Modal de verificação do delete item Pedido */}   
      <MDBModal
          isOpen={modalDeleteItemPedido}
          toggle={toggleDeleteItemPedido}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
              <span style={{color:'black', fontWeight: 'bolder'}}>
                Você deseja excluir esse item do pedido?
              </span>
            </MDBRow>
            <MDBRow className="mt-2" center>
              <span>
                Nome do Produto: {itemPedido.nome}
                </span>
            </MDBRow>   
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={() => {
                toggleDeleteItemPedido()
              }}>Cancelar</Button>
            </MDBModalFooter>
            {hasCreatedPedido ? (
              <MDBModalFooter>
                <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => handleDeleteItemPedido(itemPedido)}>Apagar</Button>
              </MDBModalFooter>
            ) : (
              <MDBModalFooter>
                <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => deleteItemWithoutCreatedPedido(itemPedido)}>Apagar</Button>
              </MDBModalFooter>
            )} 
          </MDBRow>
        </MDBModal>

      <GridItem center 
        className="title-subinfo"
        style={{ 
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          backgroundColor: '#ADC5B4', 
          color: 'white', 
          fontSize: '22px', 
          marginLeft: '0', 
          marginRight: '0', 
          fontWeight: '600', 
          marginBottom: '10px',
          marginTop: '10px'
        }}>
          <div>
            <>Produtos*</>
            {user.is_superuser && (
              <Button
                style={{ 
                  backgroundColor: '#45704D', 
                  color: '#ADC5B4', 
                  fontSize: '12px',
                  marginLeft: '10px', 
                  marginTop: '5px', 
                  marginBottom: '5px',
                  width: '15px'  
                }}
                variant="contained"
                onClick={() => {
                  redirectToProduct()
                }}
              >
                <ImInfo 
                  style={{ height: '20px', width: '20px' }}
                />
              </Button>
            )}
          </div>

					<BiSolidAddToQueue 
						title='Adicionar Produto'
						disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
						className="btn-manage"
						onClick={() => {
							setIsViewTableProduto(false)
							setProdutos([])
							setIsUpdateItemPedido(false)
							setRevestimentos([])
							clearStateItemPedido()
							toggleItemPedido()   
						}}
						style={{ height: '24px', color: '#F6EDD2', width: '24px', marginTop: '10px' }}
					/>
          
        </GridItem>
        
        {itensPedido.length > 0 && (
          <>
            <MDBContainer>
              <MDBRow className="mx-5">
                <MDBCol md={6} className="d-flex justify-content-start">
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>PRODUTOS</span>
                </MDBCol>
                <MDBCol md={2} className="d-flex justify-content-start">
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>QUANT</span>
                </MDBCol>
                <MDBCol md={2} className="d-flex justify-content-start">
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>VALOR</span>
                </MDBCol>
                <MDBCol md={2} className="d-flex justify-content-start">
                  <span style={{ fontSize: "12px", fontWeight: "bold" }}>VALOR TOTAL</span>
                </MDBCol>
              </MDBRow>
            </MDBContainer>  
            <MDBContainer>
              {itensPedido.map((item, idx) => (
                <div key={idx}>
                  <MDBRow className="pt-4 pb-3" style={{marginTop: '-30px'}}>
                    {item.imagem ? (
                      <MDBCol md={2} className="px-0">
                        <img src={item.imagem} width="100px" height="100px" alt="Produto"/>
                      </MDBCol>
                    ) : (
                      <MDBCol md={1}></MDBCol>
                    )}

                    <MDBCol md={4}>
                      <div className="row">
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>{item.nome}</span>
                      </div>
                      <div className="row">
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Largura: {formatMedidas(parseFloat(item.largura))}</span>
                      </div>
                      <div className="row">
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Altura: {formatMedidas(parseFloat(item.altura))}</span>
                      </div>
                      <div className="row">
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Profundidade: {formatMedidas(parseFloat(item.profundidade))}</span>
                      </div>
                      <div className="row">
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Tecido: {item.tecido}</span>
                      </div>
                      <div className="row">
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Revestimento: {item.nomeRevestimento}</span>
                      </div>
                      <div className="row">
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Acabamento: {item.acabamento1} {item.acabamento2} {item.acabamento3}</span>
                      </div>
                      <div className="row">
                        <span style={{ fontSize: "14px", fontWeight: "bold" }}>Especial? {isUpdate ? getEspecialByPadr(item.padronagem) : getEspecialByIsEspecial(item.isEspecial)}</span>
                      </div>
                    </MDBCol>

                    <MDBCol md={1}>
                      <span style={{ fontSize: "14px", fontWeight: "bold", marginRight: "70px" }}>{item.quantidade}</span>
                    </MDBCol>

                    <MDBCol md={2}>
                      <span style={{ fontSize: "14px", fontWeight: "bold", marginLeft: "20px" }}>{isUpdate ? (formatMonetaryValue(item.valor)) : (formatMonetaryValue(item.valorUnidade))}</span>
                    </MDBCol>

                    <MDBCol md={2}>
                      <span style={{ fontSize: "14px", fontWeight: "bold", marginLeft: "20px" }}>{isUpdate ? (formatMonetaryValue(item.valor * item.quantidade)) : (formatMonetaryValue(item.valorUnidade * item.quantidade))}</span>
                    </MDBCol>
										<MDBCol md={1} style={{marginLeft: '-20px'}}>
										{hasCreatedPedido ? (
												<Button 
													color='info'
													style={{marginBottom: '10px', backgroundColor:'#B19B80' }}
													disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)} 
													variant="contained"
													onClick={() => {
														setItemPedido(item)
														setIsUpdateItemPedido(true)
														handleUpdateItemPedidoWithPedidoCreated(item)                            
														}}
													>      
													<CiEdit 
														style={{height: '24px', color: '#F6EDD2', width: '24px'}}
													/>
												</Button>
											) : (
												<Button 
													color='info'
													style={{marginBottom: '10px', backgroundColor:'#B19B80'}}
													disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)} 
													variant="contained"
													onClick={() => {
														setItemPedido(item)
														setIsUpdateItemPedido(true)
														handleUpdateItemPedido(item, idx)     
														}}
													>
													<CiEdit 
														style={{height: '24px', color: '#F6EDD2', width: '24px'}}
													/>
												</Button>
											)}           
										<Button 
                     style={{backgroundColor:'#B19B80' }}
											disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)} 
											variant="contained"
											color='warning' 
											onClick={() => {
												setItemPedido(item)
												toggleDeleteItemPedido()   
												}} 
											>
											<FaTrashAlt  
												style={{height: '22px', color: '#F6EDD2', width: '22px'}}
											/>
										</Button>  
									</MDBCol>
                   
                  </MDBRow>
                  <hr style={{ color: "black", border: "1px solid black" }} />
                </div>
              ))}
            </MDBContainer>
          </>
        )}
    </>
  );
}
