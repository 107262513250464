import React from 'react';

export const GetAllPrincipalComponent = (modelos, erros, service, loading) => {
    service.getAll()
      .then(response => {
        if (response.status === 200) {
          modelos(response.data)
          loading(false)
        } else {
          erros('Erro aos listar!!')
        }
      })
      .catch(error => {
        console.log(error)
        erros(error)
      })
}

export const GetLastPrincipalComponents = (modelos, erros, service, loading, table) => {
    service.getLast()
      .then(response => {
        if (response.status === 200) {
          modelos(response.data)
          loading(false)
          table(true)
        } else {
          erros('Erro aos listar!!')
        }
      })
      .catch(error => {
        console.log(error)
        erros(error)
      })
}

export const GetAll = (modelos, error, service) => {
    service.getAll()
      .then(response => {
        if (response.status === 200) {
          modelos(response.data)
        } else {
          error('Erro aos listar!!')
        }
      })
      .catch(error => {
        console.log(error)
        error(error)
      })
}

export const GetPedidosByOrcamento = (modelos, error, service, loading, table) => {
  service.getByOrcamentos()
    .then(response => {
      if (response.status === 200) {
        modelos(response.data)
        loading(false)
        table(true)
      } else {
        error('Erro ao listar pedidos por orçamento!!')
      }
    })
    .catch(error => {
      console.log(error)
      error(error)
    })
}

export const GetPedidosByAguardandoAprovacao = (modelos, error, service, loading, table) => {
  service.getByAguardandoAprovacao()
    .then(response => {
      if (response.status === 200) {
        modelos(response.data)
        loading(false)
        table(true)
      } else {
        error('Erro ao listar pedidos por orçamento!!')
      }
    })
    .catch(error => {
      console.log(error)
      error(error)
    })
}

export const GetPedidosByUser = (modelos, error, service, loading, user, table) => {
  service.getPedidosByUser(user)
    .then(response => {
      if (response.status === 200) {
        modelos(response.data)
        loading(false)
        table(true)
      } else {
        error('Erro ao listar pedidos por orçamento!!')
      }
    })
    .catch(error => {
      console.log(error)
      error(error)
    })
}

export const GetLastsOrcamentos = (modelos, error, service, loading) => {
  service.getLastsOrcamentos()
    .then(response => {
      if (response.status === 200) {
        modelos(response.data)
        loading(false)
      } else {
        error('Erro ao listar orçamentos!!')
      }
    })
    .catch(error => {
      console.log(error)
      error(error)
    })
}