import React, { useState } from 'react';
import { 
    MDBModal,
    MDBRow,
    MDBModalBody,
    MDBModalFooter
} from 'mdbreact';


import { BiSolidAddToQueue } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import { Button } from '@mui/material';

import GridItem from '../../../Grid/GridItem';
import GridContainer from '../../../Grid/GridContainer';
import NativeSelect from '@mui/material/NativeSelect';
import { GetAll } from '../../../GetAll';
import { useAuthDataContext } from '../../../../services/auth/AuthContext';

import FornecedoresService from '../../../../services/fornecedores/fornecedores';
import '../../styles.css';

export default function ModalAddFornecedorPedido(props) {
	// Dados retornados para o pedido
	const { 
		idFornecedor,
		setIdFornecedor,
		cnpjFornecedor,
		razaoSocialFornecedor,
		frete,
		ipi,
		percIpi,
		statusPedido,
		setStatusPedido,
		isUpdate,
		togglePedido,
		idPrazoEntrega,
		setIdPrazoEntrega,
		prazoEntregaDias,
		prazoEntregaObs
	} = props;

	// Services
	const fornecedoresService = new FornecedoresService()
	// User
	const { user, userGroup } = useAuthDataContext()
	// Errors	
	const [error, setError] = useState('')

	// Variáveis de controle do Modal
	const [modal, setModal] = useState(false);
	const toggleFornecedor = () => { 
			setModal(!modal) 
	}
	// Campos de fornecedores
	const [prazosEntrega, setPrazosEntrega] = useState([])
	const [fornecedor, setFornecedor] = useState('')
  const [fornecedores, setFornecedores] = useState([])

	// Determina opcoes de fornecedores
	const showFornecedores = fornecedores.map((data, idx) => {
    return (
      <option key={idx} value={data.id}>{data.razao_social}</option>
    )
  })

	// Pegando Frete:
  const getFrete = (frete) => {
    if (frete=='cif') {
      return "CIF"
    }
    if (frete=='fob') {
      return "FOB"
    }
  }

	// Pegando se possui ou não o ipi
  const getIpi = (value) => {
    if (value==='sim'){
      return 'Sim'
    } else {
      return 'Não'
    }
  }

	// Quando altera o fornecedor:
	const handleChangeFornecedor = (idx, value) => {
    if (value!="Selecione o Fornecedor") {
      const selectedFornecedor = fornecedores[idx - 1]
      setIdFornecedor(selectedFornecedor.id)
      cnpjFornecedor(selectedFornecedor.cnpj)
      razaoSocialFornecedor(selectedFornecedor.razao_social)
      frete(getFrete(selectedFornecedor.frete_total.nome))
      ipi(getIpi(selectedFornecedor.imposto_produto_ind))
      percIpi(selectedFornecedor.percentual_ipi)  
			
			getPrazosEntrega(selectedFornecedor.id)
    }   
  } 
	
	const getPrazosEntrega = (idFornecedor) => {
    fornecedoresService.getPrazosEntregaByFornecedor(idFornecedor)
    .then(response => {
      if (response.status === 200) {
        setPrazosEntrega(response.data) 
      } else {
        setError('Erro aos listar prazos de Entrega!!')
      }
    })
    .catch(error => {
      setError(error)
    }) 
  }

	// Quando altera o prazo
	const handleChangePrazoEntrega = (idx, value) => {
		prazoEntregaDias("")
		prazoEntregaObs("")
		if (value!="Selecione o Prazo de Entrega") {    
			const selectedPrazoEntrega = prazosEntrega[idx - 1]
			prazoEntregaDias(selectedPrazoEntrega.prazo)
			prazoEntregaObs(selectedPrazoEntrega.obs)
		} 
	}

			// Pega prazos
			const showPrazosEntrega = prazosEntrega.map((prazo, idx) => {
				return (
					<option key={idx} value={prazo.id}>Prazo: {prazo.prazo}</option>
				)
			})

	// Valida os campos antes de permitir o filtro
	const checkValues = () => {

		if (
			idFornecedor == "" ||
			idPrazoEntrega == "" ||
			prazosEntrega == []
		) {
				return true
		} else {
				return false
		}
    }

   
    // Limpa os campos
    const clearState = () => {
			setFornecedor('')
			setFornecedores([])
			setPrazosEntrega([])
    }

    return (
			<>
				<MDBModal
					isOpen={modal}
					toggle={toggleFornecedor}
					size='lg'
					disableBackdrop
				>
					<MDBModalBody>
						<GridItem center 
							className="title-subinfo"
							style={{ 
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'row',
								alignItems: 'center',
								backgroundColor: '#ADC5B4', 
								color: 'white', 
								fontSize: '22px', 
								marginLeft: '0', 
								marginRight: '0', 
								fontWeight: '600', 
								marginBottom: '10px',
								marginTop: '10px'
							}}>
							{userGroup == 'Orcamento' ? (
								<>
									Fornecedor do Orçamento 
								</>
							):(
								<>
									Fornecedor do Pedido
								</>
							)}
						</GridItem>
						
						{fornecedores.length > 0 ? (
							<GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <NativeSelect
                  disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
                  value={fornecedor}
                  onChange={e => {
                    setFornecedor(e.target.value)
                    handleChangeFornecedor(e.target.options.selectedIndex, e.target.value)}}
                >
                  <option>Selecione o Fornecedor</option>
                  {showFornecedores}
                </NativeSelect>
              </GridItem>
							<GridItem xs={12} sm={12} md={6}>
									<NativeSelect
										disabled={statusPedido==="3" || statusPedido==="4" ? (true) : (false)}
										value={idPrazoEntrega}
										onChange={e => {
											setIdPrazoEntrega(e.target.value)
											handleChangePrazoEntrega(e.target.options.selectedIndex, e.target.value)
										}}
									>
										<option>Selecione o Prazo de Entrega</option>
										{showPrazosEntrega}
									</NativeSelect>
								</GridItem>
            </GridContainer>
			):(null)}
			</MDBModalBody>

			<MDBRow end style={{marginRight: '0px'}}>
				<MDBModalFooter>
					<Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleFornecedor}>Cancelar</Button>
				</MDBModalFooter>

				<MDBModalFooter>
					<Button disabled={checkValues()} variant="contained" 
					onClick={() => {
						toggleFornecedor()
						// togglePedido()
					}}
					>
						{isUpdate ? (
							<>
								Alterar
							</>
						):(
							<>
								Adicionar
							</>
						)}
					</Button>
				</MDBModalFooter>
			</MDBRow>
		</MDBModal>

				{/* Botão para filtro */}
				{isUpdate || idFornecedor != '' ? (
				<CiEdit 
					title='Adicionar Fornecedor'
						className="btn-manage"
						onClick={() => {
							toggleFornecedor()
							clearState()
							GetAll(setFornecedores, setError, fornecedoresService)
						}}
						style={{ height: '24px', color: '#F6EDD2', width: '24px' }}
				/>
				):(
					<BiSolidAddToQueue 
					title='Adicionar Fornecedor'
						className="btn-manage"
						onClick={() => {
							toggleFornecedor()
							clearState()
							GetAll(setFornecedores, setError, fornecedoresService)
						}}
						style={{ height: '24px', color: '#F6EDD2', width: '24px' }}
				/>
				)}				
		</>
	)
}