import React from 'react';

// Elemento Read:
import CrudPermissoes from '../../components/Permissoes/index';

export default function Permissoes() {
  return (
    <div>
      <CrudPermissoes />
    </div>
  )
};
