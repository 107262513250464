import React from 'react';

// Elemento Read:
import CrudPedidos from '../../components/Pedidos';

export default function Pedidos() {
  const tipo = "default"
  return (
    <div>
      <CrudPedidos tipo={tipo} />
    </div>
  )
};
