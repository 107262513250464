import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { MDBCol } from "mdbreact";

import NavbarDashboard from "../../components/Navbar/NavbarDashboard";
import Clientes from '../../pages/Clientes/Clientes';
import Consultores from '../../pages/Consultores/Consultores';
import Fornecedores from '../../pages/Fornecedores/Fornecedores';
import Pedidos from '../../pages/Pedidos/Pedidos';
import Permissoes from "../../pages/Permissoes/Permissoes";
import PedidosEspecial from "../Permissoes/Especial/Especial.js";
// import Orcamentos from "../../pages/Permissoes/Orcamentos/Orcamentos";
import Produtos from '../../pages/Produtos/Produtos';
import Familias from '../../pages/Produtos/Familia/Familia.js'
import Grupos from '../../pages/Produtos/Grupo/Grupo.js'
import SubGrupos from "../../pages/Produtos/SubGrupo/SubGrupo.js";
import Comissoes from "../Comissoes/Comissoes.js";
import DashboardPage from '../../components/Dashboard/index';
import Arquivos from "../Produtos/Arquivos/index.js";

import SidebarData from "../../components/Sidebar/SidebarData";
import Sidebar from "../../components/Sidebar/Sidebar";

import { useAuthDataContext } from '../../services/auth/AuthContext';

import './styles.css'


export default function Dashboard() {
  const datas = new SidebarData()

  const { userGroup } = useAuthDataContext()
  
  const switchRoutes = (
    <Switch>
      <Redirect exact from="/dashboard" to="/dashboard/dashboard" />  
      <Route path="/dashboard/dashboard" component={DashboardPage} />
      <Route exact path="/dashboard/clientes" component={Clientes} />
      <Route exact path="/dashboard/comissoes" component={Comissoes} />
      <Route exact path="/dashboard/consultores" component={Consultores} />
      <Route exact path="/dashboard/fornecedores" component={Fornecedores} />
      <Route exact path="/dashboard/pedidos" component={Pedidos} />
      <Route exact path="/dashboard/permissoes" component={Permissoes} />
      <Route path="/dashboard/permissoes/pedido_especial" component={PedidosEspecial} />
      {/* <Route path="/dashboard/permissoes/orcamentos" component={Orcamentos} /> */}
      <Route exact path="/dashboard/produtos" component={Produtos} />
      <Route path="/dashboard/produtos/arquivos" component={Arquivos} />
      <Route path="/dashboard/produtos/familias" component={Familias} />
      <Route path="/dashboard/produtos/grupos" component={Grupos} />
      <Route path="/dashboard/produtos/subgrupos" component={SubGrupos} />
    </Switch>
  )
  
  useEffect(() => {
    console.log(userGroup[0])
  }, [userGroup])
  console.log()
  return (
    <div className="wrapper">
      <MDBCol xs={6}>
        <Sidebar />
      </MDBCol>

      <MDBCol xs={8} className="content">
        <div className="container">
          <div>
          
          {userGroup=="Orcamento" ? (
            <NavbarDashboard
              data={datas.dataOrcamentos}
            />
            ) : ( 
            <NavbarDashboard
              data={datas.dataDefault}
            />
          )}
          </div>
          
          {switchRoutes}
        </div>
      </MDBCol>  
    </div>
  );
}
