import React, { useEffect, useState, useRef } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBIcon,
  MDBCol
} from 'mdbreact';
import { Button } from '@mui/material';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';
import { AiOutlineFileSync } from "react-icons/ai";
import { RiFileAddFill } from "react-icons/ri";
import ProdutosService from '../../../services/produtos/produtos';
import '../styles.css';

export default function FilesForProducts() {
  const produtosService = new ProdutosService();
  const [error, setError] = useState('');
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const getFilesForProducts = () => {
    produtosService.getFilesForProducts()
      .then(response => {
        if (response.status === 200) {
          // Adiciona a base URL aos arquivos
          const formattedFiles = response.data.map(file => ({
            ...file,
            anexo: `${file.anexo}`
          }));
          setFiles(formattedFiles);
        } else {
          setError('Erro ao listar anexos!!');
        }
      })
      .catch(error => {
        setError(error.message || 'Erro ao listar anexos!!');
      });
  };

  const addFilesToProdutos = () => {
    let form_data = new FormData();
    form_data.append('file-0', file);
    produtosService.addFilesToProdutos(form_data)
      .then(response => {
        if (response.status === 200) {
          getFilesForProducts();
          setFile("");
        } else {
          setError('Erro ao adicionar anexo!!');
        }
      })
      .catch(error => {
        setError(error.message || 'Erro ao adicionar anexo!!');
      });
  };

  const handleDelFile = (file) => {
    produtosService.deleteFile(file['id'])
      .then(res => {
        if (res.status === 200) {
          getFilesForProducts();
        }
      })
      .catch(error => {
        setError(error.message || 'Erro ao deletar anexo!!');
      });
  };

  const [file, setFile] = useState('');
  const hiddenFileInput = useRef(null);

  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <CardHeader 
        className="header-table" 
        style={{ backgroundColor: '#B19B80', color: 'white' }}>
        <h4 className="title-table">Ações de Arquivos Auxiliares sobre Produtos</h4>
      </CardHeader>

      <div className='actions-produto'>
        <GridItem xs={12} sm={12} md={3}>
          <Card>
            <Button onClick={handleClick}
              variant="contained"
              style={{ backgroundColor: '#F6EDD2', color: '#B19B80', fontSize: '12px', height: '55px'}}> 
              Adicionar Arquivo
              <RiFileAddFill  style={{ height: '40px', width: '40px', paddingLeft: '20px' }}/>
              <input 
              ref={hiddenFileInput}
              style={{ display: 'none' }}
              id='input-file' 
              type='file' 
              name='myfile'
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}  
            />  
            </Button>
            
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card>
            <Button
              style={{ backgroundColor: '#F6EDD2', color: '#B19B80', fontSize: '12px', height: '55px' }}
              variant="contained"
              onClick={getFilesForProducts}
            >
              Carregar Arquivos
              <AiOutlineFileSync 
                style={{ height: '40px', width: '40px', paddingLeft: '20px' }}
              />
            </Button>
          </Card>
        </GridItem>
      </div>

      {file !== "" ? (
        <GridContainer>
          <Card>
            <CardHeader className="header-table" style={{ backgroundColor: '#B19B80', color: 'white' }}>
              <h4 className="title-table">Arquivo a ser Adicionado</h4>
            </CardHeader>
          </Card>
          <GridItem className="grid-item" xs={12} sm={12} md={6} style={{ marginTop: '12px', marginRight: '50px' }}>
            <span id='file-name'>{file.name}</span>
          </GridItem>
          <GridItem className="grid-item" xs={12} sm={12} md={1} style={{ marginTop: '-14px' }}>
            <Button 
              className='btn-email'
              variant="contained" 
              size="sm" 
              disabled={file === ''} 
              style={{ backgroundColor: '#F6EDD2', color: '#B19B80', fontSize: '12px', height: '25px', marginBottom: '-45px' }}
              onClick={addFilesToProdutos}
            >
              <MDBIcon icon="plus" className='icon-email' />
            </Button> 
          </GridItem>   
        </GridContainer> 
      ) : null}

      <Card>
        <CardHeader className="header-table" style={{ backgroundColor: '#ADC5B4', color: 'white' }}>
          <h4 className="title-table">Lista de Arquivos</h4>
          <p className="p">Visualize e apague os Arquivos.</p>
        </CardHeader>
      </Card>

      {files.length > 0 ? (
        <>
          <MDBContainer>
            <MDBRow>
              <MDBCol md={9} className="d-flex justify-content-start">
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>Arquivo</span>
              </MDBCol>
              <MDBCol md={3} className="d-flex justify-content-start">
                <span style={{ fontSize: "12px", fontWeight: "bold" }}>Ação</span>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer>
            {files.map((file, idx) => (
              <ul key={idx} style={{ marginLeft: '20px' }}>       
                <li>
                  <MDBRow>
                    <MDBCol md={9} className="d-flex justify-content-start">
                      <div>
                        <a 
                          className='link-anexo' 
                          href={file.anexo} 
                          target='_blank'
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedFile(file.anexo);
                          }}
                        >
                          {file.filename}
                        </a>
                      </div>
                    </MDBCol>
                    <MDBCol md={3} className="d-flex justify-content-start">
                      <Button 
                        size="sm" 
                        variant="contained" 
                        style={{ backgroundColor: 'orangered' }}
                        onClick={() => handleDelFile(file)}
                      >
                        <MDBIcon icon="trash" />
                      </Button>                      
                    </MDBCol>
                  </MDBRow>
                </li>
              </ul>
            ))}
          </MDBContainer>
        </>
      ) : (
        <>Arquivos indisponíveis ou ainda não carregados! Favor tentar novamente!</>
      )}
      {selectedFile && (
        <div style={{ marginTop: '20px', alignItems: 'center' }}>
          <Card>
            <CardHeader className="header-table" style={{ backgroundColor: '#ADC5B4', color: 'white' }}>
              <h4 className="title-table">Visualização de Arquivo</h4>
            </CardHeader>
            <iframe 
              src={selectedFile} 
              style={{ width: '100%', height: '500px', border: 'none' }}
              title="File Preview"
            />
          </Card>
        </div>
      )}
    </>
  );
}
