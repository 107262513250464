import React, { useEffect, useState } from 'react';
import { MDBDataTableV5, MDBIcon } from 'mdbreact';

import './TableStyles.css'

export default function Table(props) {

  // Definindo modelo que virá do component Read de cada modelo:
  const { modelos } = props;
 
  // Definindo linhas e colunas para a tabela:  
  const [columns, setColumns] = useState('');
  const [rows, setRows] = useState('');
  

  // Definindo map para se obter as colunas referentes a tabela:
  const getColumns = () => {
    var dataHeader = 
    [ {
      label: 'Data Pedido',
      field: 'dataPedido',
      width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'ID',
        },
      },
      {
        label: "Nº Pedido",
        field: 'nPedido',
        width: 150,
      },
      {
        label: "OC Cliente",
        field: 'ocCliente',
        width: 150,
      },
      {
        label: "Taxa",
        field: 'taxa',
        width: 150,
      },
      {
        label: 'Valor',
        field: 'valor',
        width: 200,
      }, 
    ]
    setColumns(dataHeader)     
  }

  const getTaxa = (taxa) => {
    if (taxa=="0") {
        return "7%"
    } else if (taxa=="1"){
        return "5%"
    }
  }
  // Definindo map para se obter as linhas referentes a tabela:
  const getRows = () => {
  
    let dataBody = modelos.map((modelo, key) => {
      return {
        dataPedido: modelo.data_pedido,
        nPedido: modelo.n_pedido,
        ocCliente: modelo.oc_pedido,
        taxa: getTaxa(modelo.taxa_comissao),
        valor: modelo.valor_comissao,       
    }})
    setRows(dataBody)  
  }
  
  // Definindo useEffect para se obter as informações referentes a tabela,
  //    sejam linhas ou colunas, e que tem como dependência o modelo referenciado
  //    em props;
  useEffect(() => {
    getColumns();
    getRows();        
  }, [modelos]);

  // Retornando tabela
  return (
    <MDBDataTableV5 responsive
      hover
      entriesOptions={[5, 20, 25]}
      entries={5}
      pagesAmount={4}
      data={{ columns: columns, rows: rows }}
      pagingTop
      searchTop
      searchBottom={false}
      barReverse
    />
  );
}