import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthDataContext } from '../services/auth/AuthContext';


export default function PrivateRoute({ component: Component, ...otherProps }) {
    const { hasAuthenticated, isAuthenticating } = useAuthDataContext()
    
    return (
        <Route
            {...otherProps}
            render={props => (
                !isAuthenticating
                    ?
                    (
                        hasAuthenticated
                            ?
                            <Component {...props} />
                            :
                            <Redirect to="/"/>
                    )
                    :
                    null
            )}
        />
    )
}