import axiosInstance from '../../auth/AuthUserService.js'

export default class FamiliasService {

  getAll() {
    const url = '/produtos/familias/listar';
    return axiosInstance.get(url);
  }

  get(id) {
    const url = `/produtos/familias/${id}`;
    return axiosInstance.get(url);
  }

  create(data) {
    const url = '/produtos/familias/adicionar';
    return axiosInstance.post(url, data);
  }

  update(id, data) {
    const url = `/produtos/familias/atualizar/${id}`;
    return axiosInstance.put(url, data);
  }

  delete(id) {
    const url = `/produtos/familias/excluir/${id}`;
    return axiosInstance.delete(url);
  }

}