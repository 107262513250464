import React from 'react';

// Elemento Read:
import CrudSubGrupos from '../../../components/Produtos/SubGrupo/SubGrupo';

export default function SubGrupos() {
  return (
    <div>
      <CrudSubGrupos />
    </div>
  )
};
