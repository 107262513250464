import React from 'react';

// Elemento Read:
import CrudPedidos from '../../Pedidos';

export default function CrudPedidosEspecial() {
  const tipo = "especial"
  console.log("tipoooo especial")
  return (
    <div>
      <CrudPedidos tipo={tipo} />
    </div>
  )
};