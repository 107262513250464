import React, { useState } from 'react';
import { 
    MDBModal,
    MDBRow,
    MDBModalBody,
    MDBModalHeader,
    MDBInput,
    MDBModalFooter
} from 'mdbreact';
import { ImSearch } from "react-icons/im";

import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';
import { useAuthDataContext } from '../../../services/auth/AuthContext';


export default function ModalFilterPedidoByField(props) {
    const { user, userGroup, userEmail } = useAuthDataContext()
    // Variáveis para comunicação com pedido:
    const { pedidosService, setPedidos, setIsLoading, setIsViewTable } = props;
    // Variáveis de controle do Modal
    const [modal, setModal] = useState(false);
    const toggle = () => { 
        setModal(!modal) 
    }
    // Variáveis para filtro
    const [nameField, setNameField] = useState('')
    const [valueField, setValuefield] = useState('')

    // Valida os campos antes de permitir o filtro
    const checkValuesFilterPedido = () => {
        if (
            nameField == "" ||
            nameField == "Selecione o Campo para Pesquisa" ||
            valueField == ""
        ) {
            return true
        } else {
            return false
        }
    }

    // Realiza o filtro por pedidos na api
    const handleSubmit = () => {
        setIsLoading(true)
        const fieldToFilter = {
            nameField: nameField,
            valueField: valueField,
            userGroup: userGroup,
            userEmail: userEmail
        }
        try {
            console.log(pedidosService.getPedidoByField)
            pedidosService.getPedidoByField(fieldToFilter)
            .then(res => {
                if (res.status === 200) {
                    console.log(res.data)
                    setPedidos(res.data)
                    toggle()
                    setIsLoading(false)
                    setIsViewTable(true)
                }
            })
        } catch(error) {
            alert("Ocorreu algum erro: "+error+", verifique e tente novamente!!")
        }
    }
    // Limpa os campos
    const clearState = () => {
        setValuefield("")
        setNameField("")
    }


    const getTitle = () => {
    if (userGroup == 'Orcamento') {
        return 'Filtrar Orçamento'
    } else {
        return 'Filtrar Pedido'
    }
    }

    return (
        <>
            {/* Modal de Filtro */}
            <MDBModal
                isOpen={modal}
                toggle={toggle}
                size='lg'
                disableBackdrop
            >   
            {userGroup == 'Orcamento' ? (
                <MDBRow center>
                    <MDBModalHeader className="modal-header">Dados para filtrar Orçamento</MDBModalHeader>
                </MDBRow>

            ):(
                <MDBRow center>
                    <MDBModalHeader className="modal-header">Dados para filtrar Pedido</MDBModalHeader>
                </MDBRow>

            )}
                
                <MDBModalBody>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <NativeSelect
                                value={nameField}
                                onChange={e => {
                                    setNameField(e.target.value)
                                    setValuefield("")
                                }}
                                >
                                {userGroup == 'Orcamento' ? (
                                    <>
                                    <option>Selecione o Campo para Pesquisa</option>
                                    <option value="4">Status do Orçamento</option>
                                    <option value="1">Nome do Cliente</option>
                                    <option value="2">Número do Orçamento</option>
                                    <option value="3">Número de Confirmação</option>
                                    </>
                                
                                ):(
                                    <>
                                    <option>Selecione o Campo para Pesquisa</option>
                                    <option value="4">Status do Pedido</option>
                                    <option value="1">Nome do Cliente</option>
                                    <option value="2">Número do Pedido</option>
                                    <option value="3">Número de Confirmação</option>
                                    </>                                
                                )}
                                
                            </NativeSelect>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            {nameField === "4" ? (
                                <NativeSelect
                                    value={valueField}
                                    onChange={e => setValuefield(e.target.value)}
                                    style={{marginTop: '-24px'}}
                                >   
                                    {userGroup == 'Orcamento' ? (
                                        <>
                                        <option>Selecione o status</option>
                                        <option value="0">Orçamento</option>
                                        <option value="7">Aguardando Informações</option>
                                        <option value="2">Aguardando envio</option>
                                        <option value="3">Em processamento</option>
                                        <option value="4">Em lote</option>
                                        <option value="5">Finalizado</option>
                                        <option value="6">Cancelado</option>
                                        </>
                                    ):(
                                        <>
                                        <option>Selecione o status</option>
                                        <option value="1">Aguardando aprovação</option>
                                        <option value="0">Orçamento</option>
                                        <option value="7">Aguardando Informações</option>
                                        <option value="2">Aguardando envio</option>
                                        <option value="3">Em processamento</option>
                                        <option value="4">Em lote</option>
                                        <option value="5">Finalizado</option>
                                        <option value="6">Cancelado</option>
                                        </>
                                    )
                                        
                                }
                                </NativeSelect>
                            ) : (
                                <MDBInput 
                                    validate
                                    required
                                    label="Valor do campo de pesquisa (ex. OC 123)"
                                    style={{marginTop: '-24px'}}
                                    type="text"  
                                    hint={valueField}
                                    value={valueField}
                                    onChange={input => setValuefield(input.target.value)}
                                />
                            )}
                        </GridItem>
                    </GridContainer>
                </MDBModalBody>

                <MDBRow end style={{marginRight: '0px'}}>
                    <MDBModalFooter>
                        <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
                    </MDBModalFooter>
                            
                    {userGroup == 'Orcamento' ? (
                        <MDBModalFooter>
                            <Button disabled={checkValuesFilterPedido()} variant="contained" onClick={handleSubmit} >
                                Filtrar Orçamento
                            </Button>
                        </MDBModalFooter>
                    ):(
                        <MDBModalFooter>
                            <Button disabled={checkValuesFilterPedido()} variant="contained" onClick={handleSubmit} >
                                Filtrar Pedido
                            </Button>
                        </MDBModalFooter>
                    )}
                    
                </MDBRow>
            </MDBModal>

            
            {/* Botão para filtro */}
    
            <ImSearch 
                className="btn-manage"
                title={getTitle()}
                onClick={() => {
                    toggle()
                    clearState()
                }}
                style={{ height: '24px', color: '#F6EDD2', width: '24px', marginLeft: '10px', cursor: 'pointer' }}
            />
        </>
    )
}