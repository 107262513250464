import React from 'react';

// Elemento Read:
import CrudFamilias from '../../../components/Produtos/Familia/Familia.js';

export default function Familias() {
  return (
    <div>
      <CrudFamilias />
    </div>
  )
};
