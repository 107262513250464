import styled from "styled-components"; 
import { Link } from "react-router-dom";

export const Nav = styled.div`
  background: #15171c;
  height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 960px) {
    height: 50px;
    display: flex;
    justify-content: flex-start;

  }
`;

export const Navh1 = styled.h1`
    font-size: 30px;
    text-align: center;
    margin-left: 200px;
    color: white;
    
    @media (max-width: 960px) {
        font-size: 20px;
        text-align: center; 
        margin-left: 50px; 
        color: white;
    }


`;

export const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 960px) {
    font-size: 1rem;
    margin-left: 0.5 rem;
    align-items: center;
  }
`;

export const SidebarNav = styled.nav`
background: #15171c;
width: 250px;
height: 100vh;
display: flex;
justify-content: center;
position: fixed;
top: 0;
transition: 350ms;
z-index: 10;

@media (max-width: 960px) {
  height: 100%;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;

}
`;

export const SidebarWrap = styled.div`
width: 100%;
@media (max-width: 960px) {
    width: 100%;
}
`;
