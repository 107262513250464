import React, { useEffect, useState } from 'react';
import { MDBDataTableV5, MDBIcon } from 'mdbreact';


export default function Table(props) {

  // Definindo modelo que virá do component Read de cada modelo:
  const { modelos, handleUpdate, toggleDelete, setModelo} = props;
 
  // Definindo linhas e colunas para a tabela:  
  const [columns, setColumns] = useState('');
  const [rows, setRows] = useState('');
  

  // Definindo map para se obter as colunas referentes a tabela:
  const getColumns = () => {
    var dataHeader = 
    [ {
      label: 'ID',
      field: 'id',
      width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'ID',
        },
      },
      {
        label: 'Nome',
        field: 'nome',
        width: 150,
      },
      {
        label: "Família",
        field: 'familia',
        width: 150,
      },
      {
        label: "Ações",
        field: 'acoes',
        width: 150,
      }
    ]
    setColumns(dataHeader)     
  }    
    
  // Definindo map para se obter as linhas referentes a tabela:
  const getRows = () => {
  
    let dataBody = modelos.map((modelo, key)=>{      
      return {
        id: modelo.id,
        nome: modelo.nome,
        familia:<div key={key}> 
                  <div className="acoes">
                     {modelo.familia.nome}
                  </div>
                </div>,
        acoes:<div key={key}>
                <div className="acoes">
                  <MDBIcon onClick={() =>
                  {handleUpdate(modelo)}} 
                  icon="pen"/> 
                </div> 

                <div className="acoes">
                  <MDBIcon onClick={() => {
                    toggleDelete() 
                    setModelo(modelo)
                  }} 
                  icon="trash"/>
                </div>     
              </div>  

      }
      
    })
    setRows(dataBody)
    }
  
  // Definindo useEffect para se obter as informações referentes a tabela,
  //    sejam linhas ou colunas, e que tem como dependência o modelo referenciado
  //    em props;
  useEffect(() => {
    getColumns();
    getRows();        
  }, [modelos]);

  // Retornando tabela
  return (
    <MDBDataTableV5 responsive
      hover
      entriesOptions={[5, 10, 15]}
      entries={5}
      pagesAmount={4}
      data={{ columns: columns, rows: rows }}
      pagingTop
      searchTop
      searchBottom={false}
      barReverse
    />
  );
}