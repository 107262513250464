import React from 'react';

export const getConsultorInfo = (consultores) => {   
    return (
        <>
            {consultores.map((consultor, index) => (
                <p key={index}>{consultor.label}</p>
            ))}
        </>
    );
}