import React, { useEffect, useState } from 'react';
import {
  MDBModal,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
} from 'mdbreact'
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';

import Table from './Table'
import Masks from "../Masks/Masks";
import ConsultoresService from '../../services/consultores/consultores';
import { useAuthDataContext } from '../../services/auth/AuthContext';
import { GetAllPrincipalComponent } from '../GetAll';

import ReactLoading from 'react-loading';

import './styles.css'

export default function CrudConsultores() {
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAuthDataContext()
  const mask = new Masks()
  // Variável do serviço consultor:
  const consultorService = new ConsultoresService()
  // Variáveis gerais de funcionalidade:
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [consultores, setConsultores] = useState([])
  const [consultor, setConsultor] = useState(null)
  const [error, setError] = useState('')   
  const [isUpdate, setIsUpdate] = useState(false)
  // Bloco de variáveis para consultores
  const [id, setId] = useState('')
  const [nome, setNome] = useState('')
  const [cpfAndCnpj, setCpfAndCnpj] = useState('')
  const [rg, setRg] = useState('')
  const [cnh, setCnh] = useState('')
  const [email, setEmail] = useState('')
  const [telefone, setTelefone] = useState('')
  const [comissao, setComissao] = useState('')
  const [feedback, setFeedback] = useState('')
  const [viewByOrcamento, setViewByOrcamento] = useState(false)
  // Variáveis do modelo endereço em consultor:
  const [idEndereco, setIdEndereco] = useState('')
  const [logradouro, setLogradouro] = useState('')
  const [numero, setNumero] = useState('')
  const [bairro, setBairro] = useState('')
  const [municipio, setMunicipio] = useState('')
  const [complemento, setComplemento] = useState('')
  const [cep, setCep] = useState('')
  const [uf, setUf] = useState('')

  // Setando toggle e toggleDelete
  const toggle = () => { 
    setModal(!modal) 
  }

  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  // Create
  const handleSubmit = () => {  
    
    var checkComissao = ''
    if (comissao=='1'){
      checkComissao='50'
    } 
    if (comissao=='2'){
      checkComissao='100'
    }
    if (comissao==''){
      checkComissao=null
    }
    const endereco = {
      logradouro: logradouro,
      numero: numero,
      bairro: bairro,
      municipio: municipio,
      complemento: complemento,
      cep: cep,
      uf: uf
    }
    const consultor = {
      nome: nome,
      cpf_cnpj: cpfAndCnpj,
      rg: rg,
      cnh: cnh,
      email: email,
      telefone: telefone,
      comissao: checkComissao,
      obs_feedback: feedback,
      endereco: endereco,
      is_viewing_by_orcamento: viewByOrcamento
    }
    try {
      consultorService.create(consultor)
      .then(res => {
        if (res.status === 201) {
            alert("Consultor criado com sucesso!!") 
            GetAllPrincipalComponent(setConsultores, setError, consultorService, setIsLoading)
            toggle() 
          } else if (res.status === 200) {
            console.log("Erro: ", res.data.empty_fields)
            setError(res.data.empty_fields)
          }      
      })
    } catch (error) {
      console.log("Erro: ", error)
      setError(error)
    }
  }

  // Update/View
  const handleUpdate = (consultor) => {

    setIsUpdate(true)

    if (consultor.comissao=="50") {
      setComissao(1) 
    } 
    if (consultor.comissao=="100") {
      setComissao(2)
    } 
    setError("")
    setId(consultor.id)
    setNome(consultor.nome)
    setCpfAndCnpj(consultor.cpf_cnpj)
    setRg(consultor.rg)
    setCnh(consultor.cnh)
    setEmail(consultor.email)
    setTelefone(consultor.telefone)
    setFeedback(consultor.obs_feedback)
    setViewByOrcamento(consultor.is_viewing_by_orcamento)

    setIdEndereco(consultor.endereco.id)
    setLogradouro(consultor.endereco.logradouro)
    setNumero(consultor.endereco.numero)
    setBairro(consultor.endereco.bairro)
    setMunicipio(consultor.endereco.municipio)
    setComplemento(consultor.endereco.complemento)
    setCep(consultor.endereco.cep)
    setUf(consultor.endereco.uf)

    toggle()  
  }

  // Verificando o update
  const confirmUpdate = () => {
    var checkComissao = ''
    if (comissao=='1'){
      checkComissao='50'
    } 
    if (comissao=='2'){
      checkComissao='100'
    }
    if (comissao==''){
      checkComissao=null
    }
    const endereco = {
      id: idEndereco,
      logradouro: logradouro,
      numero: numero,
      bairro: bairro,
      municipio: municipio,
      complemento: complemento,
      cep: cep,
      uf: uf
    }
    const consultorData = {
      id: id,
      nome: nome,
      cpf_cnpj: cpfAndCnpj,
      rg: rg,
      cnh: cnh,
      email: email,
      telefone: telefone,
      comissao: checkComissao,
      obs_feedback: feedback,
      endereco: endereco,
      is_viewing_by_orcamento: viewByOrcamento
    }   
    try {
      consultorService.update(id, consultorData)
      .then(res => {
        console.log("s")
        console.log(res.status)
        if (res.status === 201) {
            alert("Consultor atualizado com sucesso!!") 
            GetAllPrincipalComponent(setConsultores, setError, consultorService, setIsLoading)
            toggle() 
          } else if (res.status === 200) {
            console.log("Erro: ", res.data.empty_fields)
            setError(res.data.empty_fields)
          }      
      })
      } catch (error) {
        console.log("Erro: ", error)
        setError(error)
      }
  }

  // Delete
  const handleDelete = (idConsultor) => {
    consultorService.delete(idConsultor)
    .then(res =>{
      if (res.status === 200) {
        GetAllPrincipalComponent(setConsultores, setError, consultorService, setIsLoading)
        toggleDelete()
        }
    })
  }

  const checkValuesConsultor = () => {
    if (
      nome=="" || 
      cpfAndCnpj=="" ||
      email=="" ||
      telefone==""  ||
      logradouro=="" ||
      numero==""  ||
      bairro=="" ||
      municipio=="" ||
      cep=="" ||
      uf==""

    ) {
      return true
    } else {
      if (cpfAndCnpj.length != 14) {
        if (
          cpfAndCnpj.length != 18 ||
          cep.length < 10 ||
          uf.length != 2 ||
          telefone.length < 14
        ) {
          return true
        } else {
          return false
        } 
      }
      if (cpfAndCnpj.length != 18) {
        if (
          cpfAndCnpj.length != 14 ||
          cep.length < 10 ||
          uf.length != 2 ||
          telefone.length < 14
        ) {
          return true
        } else {
          return false
        } 
      }
    }
  } 
  

  // Variável para limpar os estados, após atualizar o consultor
  const clearState = () => {
    // Campos do consultor
    setNome("")
    setCpfAndCnpj("")
    setRg("")
    setCnh("")
    setEmail("")
    setComissao("")
    setFeedback("")
    setViewByOrcamento(false)
    setError("")

    // Campos de endereco:
    setTelefone("")    
    setLogradouro("")
    setNumero("")
    setBairro("")
    setMunicipio("")
    setComplemento("")
    setCep("")
    setUf("")
  }

  // Aqui, usa-se o useEffect de forma a pegar todos os consultores, na inicialização:
  useEffect(() => {
    GetAllPrincipalComponent(setConsultores, setError, consultorService, setIsLoading)
  }, [])

  return (
    <div>
      {/* Container de inserção de informação (Create/Update/Read Individual)*/}
      <MDBModal 
        isOpen={modal} 
        toggle={toggle} 
        size="lg" d
        disableBackdrop
      >  
        
        <MDBRow center>
          {isUpdate ? (
             <MDBModalHeader className="modal-header">Informações sobre o consultor</MDBModalHeader>
          ): (        
            <MDBModalHeader className="modal-header">Cadastre novo Consultor</MDBModalHeader>
          )} 
        </MDBRow>

        <MDBModalBody >        
          <div className="info-form">
            <GridContainer >
              <GridItem xs={12} sm={12} md={3}>
                <p className="p-form">Consultor</p>
              </GridItem>
            </GridContainer>
        
            <GridContainer >
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput
                  validate
                  required
                  label="Nome *"
                  hint={nome}
                  type="text"
                  name="nome"
                  value={nome}
                  onChange={e => setNome(mask.charAndText(e.target.value))}
                />
                {error.indexOf('nome') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
              </GridItem>
              <GridItem xs={6} sm={12} md={3}>
                
                <MDBInput
                  validate
                  required
                  label="CPF/CNPJ *"
                  hint={cpfAndCnpj}
                  type="text"
                  name="cpf"
                  value={cpfAndCnpj}
                  onChange={e => setCpfAndCnpj(mask.cpfAndCnpj(e.target.value))}
                />
                {error.indexOf('cpf_cnpj') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  validate
                  required
                  label="RG"
                  hint={rg}
                  type="text"
                  value={rg}
                  onChange={e => setRg(e.target.value)}
                />
                {error.indexOf('rg') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
              </GridItem>
            </GridContainer>
            
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <MDBInput
                  validate
                  required
                  label="Email *"
                  hint={email}
                  type="email"
                  name="email"
                  value={email}
                  onChange={e => setEmail(mask.email(e.target.value))}
                />
                {error.indexOf('email') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
              </GridItem>

              <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  label="Celular *"
                  hint={telefone}
                  type="text"
                  name="phone"
                  value={telefone}
                  onChange={e => setTelefone(mask.celular(e.target.value))}
                />
                {error.indexOf('telefone') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
            </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  label="CNH"
                  hint={cnh}
                  type="text"
                  name="cnh"
                  value={cnh}
                  onChange={e => setCnh(e.target.value)}
                />
                {error.indexOf('cnh') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
              </GridItem>
            </GridContainer>

            <GridContainer>
            
            <GridItem xs={12} sm={12} md={12}>
                <MDBInput
                  label="Feedback"
                  hint={feedback}
                  type="textarea"
                  name="feedback"
                  value={feedback}
                  onChange={e => setFeedback(mask.charAndText(e.target.value))}
                />
                {error.indexOf('obs_feedback') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
              </GridItem>
            </GridContainer>
          

            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel>
                  Comissão
                </InputLabel>
                <NativeSelect
                  value={comissao}
                  disabled={user.is_superuser ? (false):(true)}
                  onChange={e => setComissao(e.target.value)}
                >
                  <option>Selecione o percentual de comissão</option>
                  <option value="1">50%</option>
                  <option value="2">100%</option>
                </NativeSelect>
                {error.indexOf('comissao') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <label style={{marginRight: '15px', marginTop: '30px'}}>
                  Visível para Orçamento?
                </label>

                <input type="checkbox"
                  checked={viewByOrcamento}
                  onChange={() => {
                    setViewByOrcamento(!viewByOrcamento)}
                  }
                />
              </GridItem>
              {error.indexOf('is_viewing_by_orcamento') !== -1 ? (
              <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
            </GridContainer>
            
        </div>

        {/* <hr className="line"/> */}
        <div className="info-form">
          <GridContainer >
            <GridItem xs={12} sm={12} md={4}>
              <p className="p-form">Endereço do Consultor</p>
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <MDBInput
                validate
                required
                label="Logradouro *"
                hint={logradouro}
                type="text"
                name="logradouro"
                value={logradouro}
                onChange={e => setLogradouro(mask.charAndText(e.target.value))}
              />
              {error.indexOf('logradouro') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
              
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <MDBInput
                validate
                required
                label="Número *"
                hint={numero}
                type="text"
                name="numero"
                value={numero}
                onChange={e => setNumero(e.target.value)}
              />
              {error.indexOf('numero') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <MDBInput
                validate
                required
                label="Bairro *"
                hint={bairro}
                type="text"
                value={bairro}
                onChange={e => setBairro(mask.charAndText(e.target.value))}
              />
              {error.indexOf('bairro') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <MDBInput
                validate
                required
                label="Município *"
                hint={municipio}
                type="text"
                name="municipio"
                value={municipio}
                onChange={e => setMunicipio(mask.charAndText(e.target.value))}
              />
              {error.indexOf('municipio') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
              <MDBInput
                validate
                required
                label="CEP *"
                hint={cep}
                type="text"
                name="cep"
                value={cep}
                onChange={e => setCep(mask.cep(e.target.value))}
              />
              {error.indexOf('cep') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <MDBInput
                  validate
                  required
                  label="UF *"
                  hint={uf}
                  type="text"
                  name="uf"
                  value={uf}
                  onChange={e => setUf(mask.uf(e.target.value))}
                />
                {error.indexOf('uf') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
            </GridItem>
          </GridContainer>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <MDBInput
                label="Complementos"
                hint={complemento}
                type="textarea"
                value={complemento}
                onChange={e => setComplemento(mask.charAndText(e.target.value))}
              />
              {error.indexOf('complemento') !== -1 ? (
                  <span style={{marginTop: "-25px", overflow: "auto", display: "flex", fontSize: "10px",
                color: "rgb(255, 162, 26)", fontWeight: "bolder", justifyContent: "center"}}>* Este campo é obrigatório.</span>
               ) : null}
            </GridItem>
          </GridContainer>
        </div>
         
        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>

          <MDBModalFooter>
            {isUpdate ? (
              <Button disabled={checkValuesConsultor()} variant="contained" onClick={confirmUpdate}>
                Atualizar Consultor
                </Button>
            ): (        
              <Button disabled={checkValuesConsultor()} variant="contained" onClick={handleSubmit}>
                Registrar Consultor
              </Button>
            )} 
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>
           
      {/* Modal de veificação do delete */}
      {modalDelete ? (
        <MDBModal
          isOpen={modalDelete}
          toggle={toggleDelete}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

            <MDBModalBody>
                <MDBRow center>
                    <span style={{color:'black', fontWeight: 'bolder'}}>
                      Você deseja excluir esse consultor?
                    </span>
                </MDBRow>
                <MDBRow className="mt-4" center>
                    <span>
                      Nome do Consultor: {consultor.nome}
                    </span>
                </MDBRow>

                <MDBRow className="mt-4" center>
                    <span>
                      CPF/CNPJ do Consultor: {consultor.cpf_cnpj}
                    </span>
                </MDBRow>
            </MDBModalBody>

            <MDBRow end style={{marginRight: '0px'}}>
              <MDBModalFooter>
                <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDelete}>Cancelar</Button>
              </MDBModalFooter>

              <MDBModalFooter>
                <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelete(consultor.id)}}>Apagar</Button>
              </MDBModalFooter>
            </MDBRow>
          </MDBModal>             

      ):(null)}

      {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ):(
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className="header-table" color="success">
                <h4 className="title-table">Lista de Consultores</h4>
                <p className="p-table">
                    Adicione e edite os consultores.
                </p>
              </CardHeader>

              <CardBody>
                <Table 
                  modelos={consultores}
                  handleUpdate={handleUpdate}
                  toggleDelete={toggleDelete}
                  setModelo={setConsultor}
                />
                <Button 
                  variant="contained"
                  style={{marginTop: '-80px'}}
                  onClick={() => {
                  setIsUpdate(false)
                  clearState()
                  toggle()}}>
                  Adicionar Consultor
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
    </div>
  )
}
