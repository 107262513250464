import React, {useEffect, useState} from "react";

import {
  MDBNavbarNav,
  MDBNavbarToggler,
  MDBCollapse,
  MDBIcon,
} from 'mdbreact';


import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/styles";
import Toolbar from "@material-ui/core/Toolbar";

import { useAuthDataContext } from "../../services/auth/AuthContext";

import "./NavbarDashboard.css"

import styles from "../../assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(styles);

export default function NavbarDashboard(props) {
  
  const classes = useStyles();

  function makeBrand() {
    var name;
    props.data.map(prop => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = prop.title
      }
      return null
    })
    return name
  }
  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color
  })

  const {
    user_id,
    user,
    onLogout,
    getSession,
  } = useAuthDataContext()
  
  const [collapse, setCollapse] = useState(true)

  useEffect(() => {
    getSession()
  }, []);

  const handleCollapse = () => {
    setCollapse(!collapse)
  }

  return (   
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <div className="title-page">
          {makeBrand()}
          </div>
        </div>
                  
        <MDBNavbarToggler onClick={handleCollapse} />
      
        <MDBCollapse id="navbarCollapse3" isOpen={collapse} navbar>
          <MDBNavbarNav right className="options">
          <div class="navigation">
            <a class="button-user" onClick={() => {onLogout()}}>
              <MDBIcon icon="user" className="mr-2 icon-user" />           
              {user.username} | Sair 
            </a>  
          </div>
          </MDBNavbarNav>
        </MDBCollapse>
      </Toolbar>

  )
}

NavbarDashboard.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object)
}
