import React from 'react';

// Elemento Read:
import CrudGrupos from '../../../components/Produtos/Grupo/Grupo';

export default function Grupos() {
  return (
    <div>
      <CrudGrupos />
    </div>
  )
};
