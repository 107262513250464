import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuthDataContext } from '../services/auth/AuthContext';

export default function PublicRoute({component: Component, restricted, ...rest}) {
    const { hasAuthenticated } = useAuthDataContext()

    return (
        <Route {...rest} render={props => (
            hasAuthenticated && restricted ?
                <Redirect to="/dashboard" />
            : <Component {...props} />
        )} />
    );
};

