import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter
} from 'mdbreact'

import { Button } from '@mui/material'
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';

import { GetAllPrincipalComponent } from '../../GetAll';

import Table from './Table';
import FamiliasService from '../../../services/produtos/familias/familias';

import Masks from "../../Masks/Masks";

import ReactLoading from 'react-loading';

import '../styles.css';

export default function CrudFamilias() {
  const [isLoading, setIsLoading] = useState(true)
  const mask = new Masks()
  const familiasService = new FamiliasService()
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [familias, setFamilias] = useState([])
  const [familia, setFamilia] = useState(null)
  const [error, setError] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)

  // Bloco de variáveis para familia
  const [id, setId] = useState('')
  const [nome, setNome] = useState('')

  // Toggles
  const toggle = () => { 
    setModal(!modal) 
  }

  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  // Create
  const handleSubmit = () => {
    const familia = {
      nome: nome,
    }
    try {
      familiasService.create(familia)
      .then(res => {
        if (res.status === 200) {
          console.log(res)
          alert("Familia criado com suceso!!")
          toggle()
          GetAllPrincipalComponent(setFamilias, setError, familiasService, setIsLoading)
        }
      })
    } catch (erro) {
      console.log(erro)
      setError(erro)
    }
  }    
  
  // Update
  const handleUpdate = (familia) => {
    setIsUpdate(true)
    setId(familia.id)
    setNome(familia.nome)

    toggle()

  }

  // Verificando o update:
  const confirmUpdate = () => {
    const familia = {
      id: id,
      nome: nome,
    }
    try {
      familiasService.update(familia.id, familia)
      .then(res => {
        if (res.status === 200) {
          alert("Família atualizado com sucesso!!") 
          toggle()
          GetAllPrincipalComponent(setFamilias, setError, familiasService, setIsLoading)
        }
      })
    } catch(error) {
      console.log(error)
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }
  }

  // Delete:
  const handleDelete = (idFamilia) => {
    console.log(idFamilia)
    familiasService.delete(idFamilia)
    .then(res =>{
      console.log(res)
      if (res.status === 200) {
        GetAllPrincipalComponent(setFamilias, setError, familiasService, setIsLoading)
        toggleDelete()
        alert("Família apagado com sucesso!!")
        }
    })
  }

  const checkValuesFamilia = () => {

    if (
      nome==""
    ) {
      return true
    } else {
      return false
    }
  } 

  const clearState = () => {
    setNome("")
  }

  useEffect(() => {
    GetAllPrincipalComponent(setFamilias, setError, familiasService, setIsLoading)
  }, [])


  return(
    <div>

      <MDBModal 
        isOpen={modal} 
        toggle={toggle}
        disableBackdrop
      >

        <MDBRow center>
          {isUpdate ? (
            <MDBModalHeader className="modal-header">Informações sobre a família</MDBModalHeader>
          ) : (
            <MDBModalHeader className="modal-header">Cadastre nova família</MDBModalHeader>
          )}
        </MDBRow>

        <MDBModalBody>
          
          <div className="info-form">
         
            <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
                <p className="p-form">Família</p>
              </GridItem>
            </GridContainer>
          
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={12}>
                <MDBInput 
                  label="Nome da Família *" 
                  type="text" validate 
                  error="wrong" success="right"
                  value={nome}
                  onChange={input => setNome(mask.charAndText(input.target.value))}
                />
              </GridItem>

            </GridContainer>
          </div>
        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>

          <MDBModalFooter>
            {isUpdate ? (
              <Button disabled={checkValuesFamilia()} variant="contained" onClick={confirmUpdate}>
                Atualizar Família
              </Button>
            ): (        
              <Button disabled={checkValuesFamilia()} variant="contained" onClick={handleSubmit}>
                Registrar Família
              </Button>
            )} 
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>
      
      {/* Modal de verificação do delete */}
      {modalDelete ? (
        <MDBModal
          isOpen={modalDelete}
          toggle={toggleDelete}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir essa família?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Nome da Familia: {familia.nome}
                  </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDelete}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelete(familia.id)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}

      {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ) : ( 
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className="header-table" color="primary">
                <h4 className="title-table">Lista de Famílias dos produtos</h4>
                <p className="p">
                    Adicione e edite as famílias dos produtos.
                </p>
              </CardHeader>

              <CardBody>
                <Table
                modelos={familias}
                handleUpdate={handleUpdate}
                toggleDelete={toggleDelete}
                setModelo={setFamilia}
                />

                <Button 
                  variant="contained"
                  style={{marginTop: '-80px'}}
                  onClick={() => {
                  setIsUpdate(false)
                  clearState()
                  toggle()}}>
                  Adicionar Família
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      )}
  </div>
       
  )
}