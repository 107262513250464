import axiosInstance from '../auth/AuthUserService.js'

export default class ProdutosService {

  getAll() {
    const url = '/produtos/listar';
    return axiosInstance.get(url);
  }

  getLast() {
    const url = '/produtos/listar/last';
    return axiosInstance.get(url);
  }

  getFilesForProducts () {
    const url = '/files/by/produtos/listar';
    return axiosInstance.get(url);
  }

  addFilesToProdutos(data) {
    const url = '/files/produtos/adicionar';
    return axiosInstance.post(url, data);
  }

  deleteFile(id, data) {
    const url = `/file/produto/excluir/${id}`;
    return axiosInstance.delete(url, data);
  }


  getAllByPage(page) {
    const url = `/produtos/listar/?page=${page}`;
    console.log(url)
    return axiosInstance.get(url);
  }

  getProdutoByField(data) {
    const url = '/produtos/filter/by/field';
    return axiosInstance.post(url, data);
  }

  get(id) {
    const url = `/produtos/${id}`;
    return axiosInstance.get(url);
  }

  getForIdFornecedor(data) {
    const url = 'produtos/by-fornecedor/listar';
    return axiosInstance.post(url, data);
  }

  create(data) {
    const url = '/produtos/adicionar';
    return axiosInstance.post(url, data, {'Content-Type': 'multipart/form-data', 
    });
  }

  update(id, data) {
    const url = `/produtos/atualizar/${id}`;
    return axiosInstance.put(url, data, {'Content-Type': 'multipart/form-data', 
  });
  }

  delete(id) {
    const url = `/produtos/excluir/${id}`;
    return axiosInstance.delete(url);
  }

}