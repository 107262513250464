import React, { useEffect, useState } from 'react';
import { MDBRow } from 'mdbreact';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader'
import CardBody from '../Card/CardBody';

import ReactLoading from 'react-loading'

import * as AiIcons from 'react-icons/ai';

import PedidosService from '../../services/pedidos/pedidos';

import Table from './Table';
import { GetLastsOrcamentos } from '../GetAll';


export default function CrudPermissoes() {
	const [error, setError] = useState('')
	const [isLoading, setIsLoading] = useState(true)

	const pedidosService = new PedidosService()
	const [orcamentos, setOrcamentos] = useState([])


	// Aqui, usa-se o useEffect de forma a pegar todos os consultores, na inicialização:
	useEffect(() => {
		GetLastsOrcamentos(setOrcamentos, setError, pedidosService, setIsLoading)
}, [])
	
return (
	<div>
		{isLoading ? (
				<MDBRow center>
					<ReactLoading  type="bars" color="black" height={400} width={150}/>   
				</MDBRow>
			):(
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader className="header-table" color="info">
							<h4 className="title-table">
								<div>
									<AiIcons.AiOutlineFileText />
								</div>
								<h4 style={{marginTop: "5px"}}>
									Últimos orçamentos
								</h4>
							</h4>
						</CardHeader>
						<CardBody className="card-body">
							<Table
								modelos={orcamentos}
							/>
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		)}
	</div>
	)
}