import React, { useEffect, useState } from 'react';
import { MDBDataTableV5 } from 'mdbreact';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

import './TableStyles.css'
export default function TableProdutos(props) {

  // Definindo modelo que virá do component Read de cada modelo:
  const { modelos, setModelo, handleChange, getRevestimentos} = props;
 
  // Definindo linhas e colunas para a tabela:  
  const [columns, setColumns] = useState('');
  const [rows, setRows] = useState('');
  
  // Definindo map para se obter as colunas referentes a tabela:
  const getColumns = () => {
    var dataHeader = 
    [  {
        // label: 'Check',
        field: 'check',
        width: 40,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'Check',
        },
      },
      {
        label: 'Nome',
        field: 'nome',
        width: 100,
      },
      {
        label: 'Imagem',
        field: 'imagem',
        width: 100,
      },
      {
        label: 'Tecido',
        field: 'tecido',
        width: 80,
      },
      {
        label: 'Largura',
        field: 'largura',
        width: 80,
      },
      {
        label: 'Altura',
        field: 'altura',
        width: 80,
      },
      {
        label: 'Profundidade',
        field: 'profundidade',
        width: 80,
      },
      {
        label: 'Especial?',
        field: 'especial',
        width: 80,
      },
     
    ]
    setColumns(dataHeader)     
  }  
  
  const formatMedidas = (medida) => {
    if (medida!=null){
      return medida.toFixed(2)
    }
  }
  const getEspecial = (padr) => {
    if (padr!=="especial") {
      return  <CancelIcon />
    }
    if (padr==="especial") {
      return  <CheckCircleIcon />
    }
  }  
  // Definindo map para se obter as linhas referentes a tabela:
  const getRows = () => {
  
    let dataBody = modelos.map((modelo, key)=>{ 
      if (modelo.imagem) {
        return {
          check: <input 
                    key={key} 
                    type="radio" 
                    name="flexRadioDefault" 
                    id={modelo.id}
                    onClick={() => {
                    setModelo(modelo)
                    handleChange(modelo)
                    getRevestimentos(modelo.id)
                  }}  
                  />,
          nome: modelo.nome,
          tecido: formatMedidas(parseFloat(modelo.tecido)),
          largura: formatMedidas(modelo.largura),
          profundidade: formatMedidas(modelo.profundidade),
          altura: formatMedidas(modelo.altura),
          especial: getEspecial(modelo.padronagem),
          imagem: <>
                    <img className="logo-a" src={modelo.imagem} />
                  </>,
        }
      } else {
        return {
          check: <input 
                    key={key} 
                    type="radio" 
                    name="flexRadioDefault" 
                    id={modelo.id}
                    onClick={() => {
                    setModelo(modelo)
                    handleChange(modelo)
                    getRevestimentos(modelo.id)
                  }}  
                  />,
          nome: modelo.nome,
          tecido: formatMedidas(parseFloat(modelo.tecido)),
          largura: formatMedidas(modelo.largura),
          profundidade: formatMedidas(modelo.profundidade),
          altura: formatMedidas(modelo.altura),
          especial: getEspecial(modelo.padronagem)
        }
      }
      
      
    })
    setRows(dataBody)
    } 
  
  // Definindo useEffect para se obter as informações referentes a tabela,
  //    sejam linhas ou colunas, e que tem como dependência o modelo referenciado
  //    em props;
  useEffect(() => {
    getColumns();
    getRows();        
  }, [modelos]);

  // Retornando tabela
  return (
    <MDBDataTableV5 responsive
      hover
      small
      entriesOptions={[5,10,15]}
      entries={5}
      pagesAmount={4}
      data={{ columns: columns, rows: rows }}
      pagingTop
      searchTop
      searchBottom={false}
      barReverse
    />
  );
}