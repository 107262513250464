import React from 'react';

// Elemento Read:
import CrudClientes from '../../components/Clientes/index';

export default function Clientes() {
  return (
    <div>
      <CrudClientes />
    </div>
  )
};
