import React, { useStat, useEffect, useState } from 'react';
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader'
import CardBody from '../Card/CardBody';

// Import Icons
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as RiIcons from 'react-icons/ri';
import * as BsIcons from 'react-icons/bs'
import * as HiIcons from 'react-icons/hi';
import * as FaIcons from 'react-icons/fa';

// Import Services
import ClientesService from '../../services/clientes/clientes';
import ConsultoresService from '../../services/consultores/consultores';
import FornecedoresService from '../../services/fornecedores/fornecedores';
import PedidosService from '../../services/pedidos/pedidos';
import ProdutosService from '../../services/produtos/produtos';
import FamiliasService from '../../services/produtos/familias/familias';
import GruposService from '../../services/produtos/grupos/grupos';
import SubGruposService from '../../services/produtos/subgrupos/subgrupos';

import GetAll from './GetAll';
import './styles.css'
import Grupos from '../../pages/Produtos/Grupo/Grupo';

export default function DashboardPage() {

  const [error, setError] = useState('')

//   // Clientes:
//   const clienteService = new ClientesService()
//   const [clientes, setClientes] = useState([''])

//   const getQuantidadeClientes = () => {
//     const quantidadeClientes = clientes.length
//     return quantidadeClientes
//   }
 
//   // Consultores
//   const consultorService = new ConsultoresService()
//   const [consultores, setConsultores] = useState([])
  
//   const getQuantidadeConsultores = () => {
//     const quantidadeConsultores = consultores.length
//     return quantidadeConsultores
//   }

//   // Fornecedores
//   const fornecedorService = new FornecedoresService()
//   const [fornecedores, setFornecedores] = useState([])
  
//   const getQuantidadeFornecedores = () => {
//     const quantidadeFornecedores = fornecedores.length
//     return quantidadeFornecedores
//   }

//   // Pedidos
//   const pedidoService = new PedidosService()
//   const [pedidos, setPedidos] = useState([])
  
//   const getQuantidadePedidos = () => {
//     const quantidadePedidos = pedidos.length
//     return quantidadePedidos
//   }

//   // Produtos
//   const produtoService = new ProdutosService()
//   const [produtos, setProdutos] = useState([])
  
//   const getQuantidadeProdutos = () => {
//     const quantidadeProdutos = produtos.length
//     return quantidadeProdutos
//   }

//   // SubItens dos Produtos
//   const familiaService = new FamiliasService()
//   const [familias, setFamilias] = useState([])

//   const getQuantidadeFamilias = () => {
//     const quantidadeFamilia = familias.length
//     return quantidadeFamilia
//   }

//   const grupoService = new GruposService()
//   const [grupos, setGrupos] = useState([])

//   const getQuantidadeGrupos = () => {
//     const quantidadeGrupo = grupos.length
//     return quantidadeGrupo
//   }

//   const subGrupoService = new SubGruposService()
//   const [subGrupos, setSubGruposService] = useState([])

//   const getQuantidadeSubGrupo = () => {
//     const quantidadeSubGrupo = subGrupos.length
//     return quantidadeSubGrupo
//   }


  // Aqui, usa-se o useEffect de forma a pegar todos os consultores, na inicialização:
//   useEffect(() => {
//     GetAll(setConsultores, setError, consultorService)
//     GetAll(setClientes, setError, clienteService)
//     GetAll(setFornecedores, setError, fornecedorService)


//     GetAll(setProdutos, setError, produtoService)
//     GetAll(setPedidos, setError, pedidoService)
//     GetAll(setFamilias, setError, familiaService)
//     GetAll(setGrupos, setError, grupoService)
//     GetAll(setSubGruposService, setError, subGrupoService)
//   }, [])
    
  return (
    <div>
      <GridContainer>
        Bem-Vindo!
        {/* <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader className="header-table" color="rose">
              <h4 className="title-table">
                <div>
                  <AiIcons.AiFillHome />
                </div>
                <div style={{marginTop: "5px", fontSize: "20px"}}>
                  Clientes
                </div>
              </h4>
            </CardHeader>
            <CardBody className="card-body">
              Quantidade de Clientes: {getQuantidadeClientes()}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader className="header-table" color="success">
      
              <h4 className="title-table">
                <div>
                  <BsIcons.BsPersonFill />
                </div>
                <div style={{marginTop: "5px", fontSize: "20px"}}>
                  Consultores
                </div>
              </h4>
              
            </CardHeader>
            <CardBody className="card-body">
              Quantidade de Consultores: {getQuantidadeConsultores()}
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <Card>
            <CardHeader className="header-table" color="danger">
              <h4 className="title-table">
                <div>
                  <BsIcons.BsBuilding />
                </div>
                <div style={{marginTop: "5px", fontSize: "20px"}}>
                  Fornecedores
                </div>
              </h4>
            </CardHeader>

            <CardBody className="card-body">
              Quantidade de Fornecedores: {getQuantidadeFornecedores()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader className="header-table" color="primary">
              <h4 className="title-table">
                <div>
                  <BsIcons.BsCart2 />
                </div>
                <div style={{marginTop: "5px", fontSize: "20px"}}>
                  Produtos
                </div>
              </h4>
            </CardHeader>

            <CardBody className="card-body">
              <div>
                <div className="item-prod">
                  Produtos:
                </div>
                <div>
                  Quantidade de Produtos: {getQuantidadeProdutos()}
                </div>

              </div>
              
              <div>
                <div className="item-prod">
                  Famílias:
                </div>
                <div className="info-prod">
                Quantidade de Famílias: {getQuantidadeFamilias()}
                </div>
              </div>

              <div>
                <div className="item-prod">
                  Grupos:
                </div>
                <div className="info-prod">
                  Quantidade de Grupos: {getQuantidadeGrupos()}
                </div>
              </div>
              
              <div>
                <div className="item-prod">
                  Subgrupos:
                </div>
                <div className="info-prod">
                  Quantidade de Subgrupos: {getQuantidadeSubGrupo()}
                </div>
              </div>
            </CardBody>

          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader className="header-table" color="info">
              <h4 className="title-table">
                <div>
                  <BsIcons.BsClipboard />
                </div>
                <div style={{marginTop: "5px", fontSize: "20px"}}>
                  Pedidos
                </div>  
              </h4>
            </CardHeader>

            <CardBody className="card-body">
              Quantidade de Pedidos: {getQuantidadePedidos()}
            </CardBody>
          </Card>
        </GridItem> */}
      </GridContainer>
    </div>
    )
};
