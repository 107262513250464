import React, { useEffect, useState } from "react";
import {
  MDBModal,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBIcon
} from 'mdbreact'
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';
import Card from '../../Card/Card';
import CardHeader from '../../Card/CardHeader';
import CardBody from '../../Card/CardBody';

// Informações para select da família
import FamiliasService from "../../../services/produtos/familias/familias";

// Informações para crud grupos:
import { GetAll, GetAllPrincipalComponent } from '../../GetAll';
import Table from './Table';
import GruposService from "../../../services/produtos/grupos/grupos";

import ReactLoading from 'react-loading';

import Masks from "../../Masks/Masks";

import '../styles.css';

export default function CrudGrupos() {
  const [isLoading, setIsLoading] = useState(true)
  const gruposService = new GruposService()
  const familiasService = new FamiliasService()
  const mask = new Masks()
  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [modalCreateFamilia, setModalCreateFamilia] = useState(false)
  const [grupos, setGrupos] = useState([])
  const [grupo, setGrupo] = useState(null)
  const [error, setError] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  // Bloco de variáveis para grupo
  const [id, setId] = useState('')
  const [nome, setNome] = useState('') 
  // Informações para select da família
  const [nomeFamilia, setNomeFamilia] = useState('')
  const [idFamilia, setIdFamilia] = useState('')
  const [familias, setFamilias] = useState([])
  const showFamilias = familias.map((data, idx) => {
    return (
      <>
      <option key={idx} value={data.id}>{data.nome}</option>
      </>
    )
  })

  // Toggles
  const toggle = () => { 
    setModal(!modal) 
  }

  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  const toggleCreateFamilia = () => {
    setModalCreateFamilia(!modalCreateFamilia)
  }

  // Create Familia
  const handleSubmitFamilia = () => {
    const familia = {
      idFamilia: idFamilia,
      nome: nomeFamilia,
    }
    try {
      familiasService.create(familia)
      .then(res => {
        if (res.status === 200) {
          console.log(res)
          alert("Familia criado com suceso!!")
          GetAll(setFamilias, setError, familiasService)
          toggleCreateFamilia()
          toggle()
        }
      })
    } catch (erro) {
      console.log(erro)
      setError(erro)
    }
  }

  // Create
  const handleSubmit = () => {
    const grupo = {
      nome: nome,
      idFamilia: idFamilia,
      nomeFamilia: nomeFamilia
    }
    try {
      gruposService.create(grupo)
      .then(res => {
        console.log(res)
        alert("Grupos criado com suceso!!")
        toggle()
        GetAllPrincipalComponent(setGrupos, setError, gruposService, setIsLoading) 
      })
    } catch (erro) {
      console.log(erro)
      setError(erro)
    }
  }
  
  // Update
  const handleUpdate = (grupo) => {
    setIsUpdate(true)

    setId(grupo.id)
    setNome(grupo.nome)
    setIdFamilia(grupo.familia.id)
    setNomeFamilia(grupo.familia.nome)

    toggle()

  }

  // Verificando o update:
  const confirmUpdate = () => {
    const grupo = {
      id: id,
      nome: nome,
      idFamilia: idFamilia,
      nomeFamilia: nomeFamilia,
    }
    try {
      gruposService.update(grupo.id, grupo)
      .then(res => {
        if (res.status === 200) {
          alert("Grupo atualizado com sucesso!!") 
          toggle()
          GetAllPrincipalComponent(setGrupos, setError, gruposService, setIsLoading) 
        }
      })
    } catch(error) {
      console.log(error)
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }
  }

  // Delete:
  const handleDelete = (idGrupo) => {
    console.log(idGrupo)
    gruposService.delete(idGrupo)
    .then(res =>{
      console.log(res)
      if (res.status === 200) {
        GetAllPrincipalComponent(setGrupos, setError, gruposService, setIsLoading) 
        toggleDelete()
        alert("Grupo apagado com sucesso!!")
        }
    })
  }

  const checkValuesGrupo = () => {

    if (
      nome=="" || 
      idFamilia=="" ||
      idFamilia=="Selecione a Família"
    ) {
      return true
    } else {
      return false
    }
  } 
  const clearState = () => {
    setNome("")
    setNomeFamilia("")
    setIdFamilia("")
  }

  const checkValuesFamilia = () => {

    if (
      nomeFamilia=="" 
    ) {
      return true
    } else {
      return false
    }
  } 

  const clearStateFamilia = () => {
    setNomeFamilia("")
    setIdFamilia("")
  }

  useEffect(() => {   
    GetAllPrincipalComponent(setGrupos, setError, gruposService, setIsLoading) 
    GetAll(setFamilias, setError, familiasService)
  }, [])


  return(
    <div>

      <MDBModal 
        isOpen={modal} 
        toggle={toggle}
        disableBackdrop
      >

        <MDBRow center>
          {isUpdate ? (
            <MDBModalHeader className="modal-header">Informações sobre o grupo</MDBModalHeader>
          ) : (
            <MDBModalHeader className="modal-header">Cadastre novo grupo</MDBModalHeader>
          )}
        </MDBRow>

        <MDBModalBody>
          
          <div className="info-form">
            <GridContainer >
              <GridItem xs={12} sm={12} md={4}>
                <p className="p-form">Grupo</p>
              </GridItem>
            </GridContainer>
                    
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={12}>
                <MDBInput 
                  label="Nome do Grupo *" 
                  type="text" validate 
                  error="wrong" success="right"
                  value={nome}
                  onChange={input => setNome(mask.charAndText(input.target.value))}
                />
              </GridItem>
            </GridContainer>
          </div>

          <div className="info-form">
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <InputLabel shrink className="mt-2 mb-2">
                  Família
                </InputLabel>
                <NativeSelect
                  defaultValue={idFamilia}
                  value={idFamilia}
                  onChange={e => setIdFamilia(e.target.value)}
                >
                  <option>Selecione a Família</option>
                  {showFamilias}
                </NativeSelect>
              </GridItem>

              <GridItem xs={12} sm={12} md={6}>
                <div className="icon-add">
                  Nova Família
                  <MDBIcon className="icon" icon="plus" onClick={() => { 
                    clearStateFamilia()
                    toggle()
                    toggleCreateFamilia()}} />
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>

          <MDBModalFooter>
            {isUpdate ? (
              <Button disabled={checkValuesGrupo()} variant="contained" onClick={confirmUpdate}>
                Atualizar Grupo
              </Button>
            ): (        
              <Button disabled={checkValuesGrupo()} variant="contained" onClick={handleSubmit}>
                Registrar Grupo
              </Button>
            )} 
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>


      {/* Modal de criar família */}
      {modalCreateFamilia ? (
        <MDBModal 
          isOpen={modalCreateFamilia}
          toggle={toggleCreateFamilia}
          disableBackdrop
        >
          <MDBModalBody>
            <div className="info-form">
              <GridContainer >
                <GridItem xs={12} sm={12} md={4}>
                  <p className="p-form">Família</p>
                </GridItem>
              </GridContainer>

              <GridContainer>
                <GridItem className="grid-item" xs={12} sm={12} md={12}>
                  <MDBInput 
                    label="Nome da Família *" 
                    type="text" validate 
                    error="wrong" success="right"
                    value={nomeFamilia}
                    onChange={input => setNomeFamilia(mask.charAndText(input.target.value))}
                  />
                </GridItem>
              </GridContainer>
            </div>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={() => {
                toggleCreateFamilia()
                toggle()}}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>     
              <Button disabled={checkValuesFamilia()} variant="contained" onClick={handleSubmitFamilia}>
                Registrar Família
              </Button> 
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>
        

      ) : (null)}
      
      {/* Modal de verificação do delete */}
      {modalDelete ? (
        <MDBModal
          isOpen={modalDelete}
          toggle={toggleDelete}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir esse grupo?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Nome do Grupo: {grupo.nome}
                  </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDelete}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelete(grupo.id)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}

      {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ) : (  
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className="header-table" color="primary">
                <h4 className="title-table">Lista de Grupos dos produtos</h4>
                <p className="p">
                    Adicione e edite os grupos dos produtos.
                </p>
              </CardHeader>
              <CardBody>
                <Table
                modelos={grupos}
                handleUpdate={handleUpdate}
                toggleDelete={toggleDelete}
                setModelo={setGrupo}
                />
                <Button 
                  variant="contained"
                  style={{marginTop: '-80px'}}
                  onClick={() => {
                  setIsUpdate(false)
                  clearState()
                  toggle()}}>
                  Adicionar Grupo
                </Button>
              </CardBody>        
            </Card>
          </GridItem>
        </GridContainer>
      )}
  </div>
       
  )
}