import React, { useEffect, useState, useRef } from "react";
import {
  MDBContainer,
  MDBModal,
  MDBRow,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBCol
} from 'mdbreact'
import InputLabel from '@mui/material/InputLabel';
import NativeSelect from '@mui/material/NativeSelect';
import { Button } from '@mui/material'
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import CardBody from '../Card/CardBody';

import { GetAll, GetLastPrincipalComponents } from '../GetAll';

import Table from './Table';
import ProdutosService from '../../services/produtos/produtos';
import FamiliasService from '../../services/produtos/familias/familias'
import GruposService from "../../services/produtos/grupos/grupos";
import SubGruposService from '../../services/produtos/subgrupos/subgrupos'
import FornecedoresService from '../../services/fornecedores/fornecedores'
// Import para Revestimentos:
import RevestimentosService from '../../services/revestimentos/revestimentos';
import Masks from "../Masks/Masks";
import { AiOutlineFileSync } from "react-icons/ai";
import ModalFilterProdutoByField from './Modals/filterProdutoByField';
import ReactLoading from 'react-loading';
 
import './styles.css';

export default function CrudProdutos() {
  const mask = new Masks()
  const [isLoading, setIsLoading] = useState(false)
	const [isViewTable, setIsViewTable] = useState(false)
  
  const fornecedorService = new FornecedoresService()
  const produtosService = new ProdutosService()
  const familiasService = new FamiliasService()
  const gruposService = new GruposService()
  const subGruposService = new SubGruposService()
  const revestimentosService = new RevestimentosService()

  const [modal, setModal] = useState(false)
  const [modalDelete, setModalDelete] = useState(false)
  const [produtos, setProdutos] = useState([])
  const [produto, setProduto] = useState(null)
  const [error, setError] = useState('')
  const [isUpdate, setIsUpdate] = useState(false)
  const [previewImagem, setPreviewImagem] = useState('')

  // Bloco de variáveis para produtos
  const [id, setId] = useState('')
  const [nome, setNome] = useState('')
  const [isActive, setIsActive] = useState(true)
  const [isCorp, setIsCorp] = useState(false)
  const [designAss, setDesignAss] = useState('')
  const [tecido, setTecido] = useState('') 
  const [royaltes, setRoyaltes] = useState('')
  const [padronagem, setPadronagem] = useState('')
  const [descrModelo, setDescrModelo] = useState('')
  const [largura, setLargura] = useState('')
  const [profundidade, setProfundidade] = useState('')
  const [profundidadeAberta, setProfundidadeAberta] = useState('')
  const [altura, setAltura] = useState('')
  const [projecaoTributo, setProjecaoTributo] = useState('')
  const [anexos, setAnexos] = useState('')
  const [imagem, setImagem] = useState('')
  const [isPromo, setIsPromo] = useState(false)
  const [isMecanismo, setIsMecanismo] = useState(false)
  // Informações para select de fornecedor:
  const [idFornecedor, setIdFornecedor] = useState('')
  const [fornecedores, setFornecedores] = useState([])
  const showFornecedores = fornecedores.map((data, idx) => {
    return (
      <>
      <option key={idx} value={data.id}>{data.cnpj} - {data.razao_social}</option>
      </>
    )
  })
  // Informações para select de subgrupo, grupo e familia:
  const [idFamilia, setIdFamilia] = useState('')
  const [familias, setFamilias] = useState([])
  const showFamilias = familias.map((data, idx) => {
    return (
      <>
      <option key={idx} value={data.id}>{data.nome}</option>
      </>
    )
  })
  const [grupos, setGrupos] = useState([])
  const [idGrupo, setIdGrupo] = useState('')
  const getGrupos = (id) => {  

    let form_data = new FormData()
    form_data.append('id', id)

    gruposService.getForIdFamilias(form_data)
      .then(res => {
        setGrupos(res.data)
      })
  }

  const showGrupos = grupos.map((data, idx) => {
    return (
      <option key={idx} value={data.ID}>{data.Nome}</option>
    )
  })

  const [subGrupos, setSubGrupos] = useState([])
  const [idSubGrupo, setIdSubGrupo] = useState('')
  const getSubGrupos = (id) => {  

    let form_data = new FormData()
    form_data.append('id', id)

    subGruposService.getForIdGrupos(form_data)
      .then(res => {
        setSubGrupos(res.data)
      })
  }

  const showSubGrupos = subGrupos.map((data, idx) => {
    return (
      <option key={idx} value={data.ID}>{data.Nome}</option>
    )
  })

  // Toggles
  const toggle = () => { 
    setModal(!modal) 
  }

  const toggleDelete = () => {
    setModalDelete(!modalDelete)
  }

  // Conexão entre produtos e seus revestimentos:
  const [revestimentos, setRevestimentos] = useState([])
  const [nomeRevestimento, setNomeRevestimento] = useState('')
  const getRevestimentos = (id) => {  
    let form_data = new FormData()
    form_data.append('id', id)
    revestimentosService.getForIdProdutos(form_data)
      .then(res => {
        setRevestimentos(res.data)
      })
  }

  // Create
  const handleSubmit = () => {
   
    let form_data = new FormData()
    form_data.append('image', imagem)
    form_data.append('nome', nome)
    form_data.append('isActive', isActive)
    form_data.append('isCorp', isCorp)
    form_data.append('designAss', designAss)
    form_data.append('tecido', tecido)
    form_data.append('royaltes', royaltes)
    form_data.append('padronagem', padronagem)
    form_data.append('fornecedor', idFornecedor)
    form_data.append('subgrupo', idSubGrupo)
    form_data.append('descrModelo', descrModelo)
    form_data.append('largura', largura)
    form_data.append('profundidade', profundidade)
    form_data.append('profundidadeAberta', profundidadeAberta)
    form_data.append('altura', altura)
    form_data.append('projecaoTributo', projecaoTributo)
    form_data.append('anexos', anexos)
    form_data.append('isPromo', isPromo)
    form_data.append('isMecanismo', isMecanismo)
    
    try {
      produtosService.create(form_data)
      .then(res => {
        alert("Produto criado com suceso!!")
        toggle()
        setIsLoading(true)
        GetLastPrincipalComponents(setProdutos, setError, produtosService, setIsLoading)
      })
    } catch (erro) {
      setError(erro)
    }
  }

  
  // Update
  const handleUpdate = (produto) => {
    setIsUpdate(true)

    if (produto.padronagem==='fabrica') {
      setPadronagem(1)
    }
    if (produto.padronagem==='especial') {
      setPadronagem(2)
    }
    if (produto.padronagem==='complementos') {
      setPadronagem(3)
    }

    setId(produto.id)
    setNome(produto.nome)
    setIsActive(produto.is_active)
    setIsCorp(produto.is_corp)
    setTecido(produto.tecido)
    setRoyaltes(produto.royaltes)
    setDescrModelo(produto.descr_modelo)
    setLargura(produto.largura)
    setProfundidade(produto.profundidade)
    setProfundidadeAberta(produto.profundidade_aberta)
    setAltura(produto.altura)
    setProjecaoTributo(produto.projecao_tributo)
    setAnexos(produto.anexos)
    if (produto.imagem != null) {
      setImagem(produto.imagem)
      setPreviewImagem(produto.imagem)
    } else {
      setImagem("")
      setPreviewImagem("")
    }
    setIsPromo(produto.is_promo)
    setIsMecanismo(produto.is_mecanismo)  
    setIdFornecedor(produto.id_fornecedor)
  
    if (produto.id_subgrupo) {
      setIdFamilia(produto.subgrupo.grupo.familia.id)
      getGrupos(produto.subgrupo.grupo.familia.id)
      setIdGrupo(produto.subgrupo.grupo.id)
      getSubGrupos(produto.subgrupo.id_grupo)
      setIdSubGrupo(produto.subgrupo.id)
    } 

    toggle()

  }
 
  // Verificando o update:
  const confirmUpdate = () => {
  
    let form_data = new FormData()
    form_data.append('id', id)
    form_data.append('nome', nome)
    form_data.append('isActive', isActive)
    form_data.append('isCorp', isCorp)
    form_data.append('designAss', designAss)
    form_data.append('tecido', tecido)
    form_data.append('royaltes', royaltes)
    form_data.append('padronagem', padronagem)
    form_data.append('descrModelo', descrModelo)
    form_data.append('largura', largura)
    form_data.append('profundidade', profundidade)
    form_data.append('profundidadeAberta', profundidadeAberta)
    form_data.append('altura', altura)
    form_data.append('projecaoTributo', projecaoTributo)
    form_data.append('image', imagem)
    form_data.append('anexos', anexos)
    form_data.append('isPromo', isPromo)
    form_data.append('isMecanismo', isMecanismo)
    form_data.append('fornecedor', idFornecedor)
    form_data.append('subgrupo', idSubGrupo)

    try {
      produtosService.update(id, form_data)
      .then(res => {
        if (res.status === 200) {
          alert("Produto atualizado com sucesso!!") 
          toggle()
          setIsLoading(true)
          GetLastPrincipalComponents(setProdutos, setError, produtosService, setIsLoading)
        }
      })
    } catch(error) {
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }
  }

  // Delete:
  const handleDelete = (idProduto) => {
    produtosService.delete(idProduto)
    .then(res =>{
      if (res.status === 200) {
        setIsLoading(true)
        GetLastPrincipalComponents(setProdutos, setError, produtosService, setIsLoading)
        toggleDelete()
        // alert("Produto apagado com sucesso!!")
        }
    })
  }

  const checkValuesProduto = () => {
    console.log(padronagem)
    if (padronagem=='3') {
      if (
        nome=="" || 
        padronagem=="" ||
        padronagem=="Selecione a padronagem" ||
        idFornecedor=="" ||
        idFornecedor=="Selecione o Fornecedor" 
      ) {
        return true
      } else {
        return false
      }
    } else {
      if (
        nome=="" || 
        padronagem=="" ||
        padronagem=="Selecione a padronagem" ||
        largura=="" ||
        profundidade=="" ||
        imagem=="" ||
        idFornecedor=="" ||
        idFornecedor=="Selecione o Fornecedor" 
      ) {
        return true
      } else {
        return false
      }
    }
    
  } 


  const clearState = () => {
    setNome("")
    setIsActive(true)
    setIsCorp(false)
    setDesignAss("")
    setTecido("")
    setRoyaltes("")
    setPadronagem("")
    setDescrModelo("")
    setLargura("")
    setProfundidade("")
    setProfundidadeAberta("")
    setAltura("")
    setProjecaoTributo("")
    setAnexos("")
    setImagem("")
    setPreviewImagem("")
    setIdFornecedor("")
    setIdFamilia("")
    setIdGrupo("")
    setIdSubGrupo("")
    setIsPromo(false)
    setIsMecanismo(false)
    
  }
  // Logica button
  const hiddenFileInput = useRef(null)

  const handleClick = event => {
    hiddenFileInput.current.click()
  }

  useEffect(() => {
    GetAll(setFamilias, setError, familiasService)
    GetAll(setFornecedores, setError, fornecedorService)
  }, [])


  return(
    <div>

      <MDBModal 
        isOpen={modal} 
        toggle={toggle} 
        size="lg"
        disableBackdrop
      >

        <MDBRow center>
          {isUpdate ? (
            <MDBModalHeader className="modal-header">Informações sobre o produto</MDBModalHeader>
          ) : (
            <MDBModalHeader className="modal-header">Cadastre novo produto</MDBModalHeader>
          )}
        </MDBRow>

        <MDBModalBody>
          <div className="info-form">
            <GridItem center 
              className="title-subinfo"
              style={{ 
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px'
              }}>
              Categorias
            </GridItem>

            <GridContainer>   
             <GridItem xs={6} sm={6} md={2} style={{marginRight: '5px'}}>
                <label style={{marginRight: '5px'}}>
                  Item Ativo?
                </label>

                <input type="checkbox"
                  checked={isActive}
                  onChange={() => {
                    setIsActive(!isActive)}
                  }
                />
              </GridItem>

              <GridItem xs={6} sm={12} md={3} style={{marginRight: '5px'}}>
                <label style={{marginRight: '5px'}}>
                  Item Promocional?
                </label>
                <input type="checkbox"
                  checked={isPromo}
                  onChange={() => setIsPromo(!isPromo)}
                />
              </GridItem>
              <GridItem xs={6} sm={12} md={3} style={{marginRight: '5px'}}>
                <label style={{marginRight: '5px'}}>
                  Item é Mecanismo?
                </label>

                <input type="checkbox"
                  checked={isMecanismo}
                  onChange={() => setIsMecanismo(!isMecanismo)}
                />
              </GridItem>

              <GridItem xs={6} sm={12} md={3}>
                <label style={{marginRight: '5px'}}>
                  Item é Corporativo?
                </label>

                <input type="checkbox"
                  checked={isCorp}
                  onChange={() => setIsCorp(!isCorp)}
                />
              </GridItem>
            </GridContainer>          
            
            <GridItem center 
              className="title-subinfo"
              style={{ 
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px'
              }}>
              Dados Gerais
            </GridItem>
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="Nome do Produto *" 
                  type="text" 
                  validate 
                  error="wrong" 
                  success="right"
                  name='nome' 
                  value={nome}
                  onChange={input => setNome(mask.charAndText(input.target.value))}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <InputLabel shrink className="mt-2 mb-2" >
                  Padronagem *
                </InputLabel>
                <NativeSelect
                  value={padronagem}
                  onChange={e => setPadronagem(e.target.value)}
                >
                  <option> Selecione a padronagem</option>
                  <option value="1">Fábrica</option>
                  <option value="2">Especial</option>
                  <option value="3">Complementos</option>
                </NativeSelect>
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Royaltes" 
                  type="number"  
                  name='royaltes' 
                  value={royaltes}
                  onChange={input => setRoyaltes(input.target.value)}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Descrição do modelo" 
                  type="text"  
                  name='descrModelo' 
                  value={descrModelo}
                  onChange={input => setDescrModelo(mask.charAndText(input.target.value))}
                />
              </GridItem>   

              <GridItem className="grid-item" xs={12} sm={12} md={3}>
                <MDBInput 
                  label="Design Assinado" 
                  type="text"  
                  name='designAss' 
                  value={designAss}
                  onChange={input => setDesignAss(mask.charAndText(input.target.value))}
                />
              </GridItem>     
            </GridContainer>

            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="Projeção de Tributo" 
                  type="text"  
                  name='projecaoTributo' 
                  value={projecaoTributo}
                  onChange={input => setProjecaoTributo(input.target.value)}
                />
              </GridItem>
              
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="Anexos" 
                  type="text"  
                  name='anexos' 
                  value={anexos}
                  onChange={input => setAnexos(input.target.value)}
                />
              </GridItem>

            </GridContainer>
            

            <GridItem center 
              className="title-subinfo"
              style={{ 
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px'
              }}>
              Medidas
            </GridItem>
            <GridContainer>
              <GridItem className="grid-item" xs={12} sm={12} md={4}>
                <MDBInput 
                  required
                  label="Largura " 
                  type="number"  
                  name='largura' 
                  value={largura}
                  onChange={input => setLargura(input.target.value)}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={4}>
                <MDBInput 
                  label="Prof *" 
                  type="number"  
                  name='profundidade' 
                  value={profundidade}
                  onChange={input => setProfundidade(input.target.value)}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={4}>
                <MDBInput 
                  required
                  label="Altura " 
                  type="number"  
                  name='altura' 
                  value={altura}
                  onChange={input => setAltura(input.target.value)}
                />
              </GridItem>
      
              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="Tecido" 
                  type="text"  
                  name='tecido' 
                  value={tecido}
                  onChange={input => setTecido(mask.charAndText(input.target.value))}
                />
              </GridItem>

              <GridItem className="grid-item" xs={12} sm={12} md={6}>
                <MDBInput 
                  label="Prof Aberta" 
                  type="number"  
                  name='profundidadeAberta' 
                  value={profundidadeAberta}
                  onChange={input => setProfundidadeAberta(input.target.value)}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={12}>
                <div>
                  <button className='btn' onClick={handleClick}> Selecionar Imagem *</button>
                  <input 
                    ref={hiddenFileInput}
                    style={{display: 'none'}}
                    type="file" name="myfile" 
                    onChange={e => {
                      setImagem(e.target.files[0])
                      setPreviewImagem(URL.createObjectURL(e.target.files[0]))
                    }}
                  />
                  {previewImagem ? (
                    <img src={previewImagem} width="200" height="130" style={{marginTop: '20px'}} />
                    ) : (
                    null)}
                </div>
              </GridItem>  
            </GridContainer>
          
            <GridItem center 
              className="title-subinfo"
              style={{ 
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Dados Sobre Relacionamentos
            </GridItem>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                  <InputLabel shrink className="mt-2 mb-2">
                    Fornecedor *
                  </InputLabel>
                  <NativeSelect
                    defaultValue={idFornecedor}
                    value={idFornecedor}
                    onChange={e => {                    
                      setIdFornecedor(e.target.value)  
                    }}
                  >
                  <option>Selecione o Fornecedor</option>
                  {showFornecedores}
                </NativeSelect>
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                  <InputLabel shrink className="mt-2 mb-2">
                    Família 
                  </InputLabel>
                  <NativeSelect
                    defaultValue={idFamilia}
                    value={idFamilia}
                    onChange={e => {                    
                      setIdFamilia(e.target.value)
                      getGrupos(e.target.value)    
                    }}
                  >
                  <option>Selecione a Família</option>
                  {showFamilias}
                </NativeSelect>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                  <InputLabel shrink className="mt-2 mb-2">
                    Grupo 
                  </InputLabel>
                  <NativeSelect
                    defaultValue={idGrupo}
                    value={idGrupo}
                    onChange={e => {
                      setIdGrupo(e.target.value)
                      getSubGrupos(e.target.value)
                    }}
                  >
                  <option>Selecione o Grupo</option>
                  {showGrupos}
                </NativeSelect>
              </GridItem>

              <GridItem xs={12} sm={12} md={4}>
                  <InputLabel shrink className="mt-2 mb-2">
                    SubGrupo 
                  </InputLabel>
                  <NativeSelect
                    defaultValue={idSubGrupo}
                    value={idSubGrupo}
                    onChange={e => {
                      setIdSubGrupo(e.target.value)
                    }}
                  >
                  <option>Selecione o SubGrupo</option>
                  {showSubGrupos}
                </NativeSelect>
              </GridItem>
            </GridContainer>   
            <GridItem center 
              className="title-subinfo"
              style={{ 
                backgroundColor: '#ADC5B4', 
                color: 'white', 
                fontSize: '22px', 
                marginLeft: '0', 
                marginRight: '0', 
                fontWeight: '600', 
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Revestimentos Relacionados
            </GridItem>   

            { revestimentos.length > 0 ? (
            <>
              <MDBContainer>
                <MDBRow className="mx-5">
                  <MDBCol md={6} className="d-flex justify-content-start" >
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>REVESTIMENTO</span>
                  </MDBCol>
                  <MDBCol md={6} className="d-flex justify-content-start">
                    <span style={{ fontSize: "12px", fontWeight: "bold" }}>VALOR</span>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>              
              <MDBContainer>
                {revestimentos.map((item, idx) => (
                  <div>
                    <MDBRow className="mx-5">
                    <MDBCol md={6} >
                      <span style={{ fontSize: "12px", fontWeight: "bold" }}>{item.nome}</span>
                    </MDBCol>
                    <MDBCol md={6}>
                      <span style={{ fontSize: "12px", fontWeight: "bold" }}>{item.valor}</span>
                    </MDBCol>
                    </MDBRow>
                  </div>
                ))}

              </MDBContainer>
            </>
          ):
          (<>Sem Revestimentos Cadastrados</>)
          }  
          </div>
        </MDBModalBody>

        <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggle}>Cancelar</Button>
          </MDBModalFooter>

          <MDBModalFooter>
            {isUpdate ? (
              <Button disabled={checkValuesProduto()} variant="contained" onClick={confirmUpdate}>
                Atualizar Produto
              </Button>
            ): (        
              <Button disabled={checkValuesProduto()} variant="contained" onClick={handleSubmit}>
                Registrar Produto
              </Button>
            )} 
          </MDBModalFooter>
        </MDBRow>
      </MDBModal>
      
      {/* Modal de verificação do delete */}
      {modalDelete ? (
        <MDBModal
          isOpen={modalDelete}
          toggle={toggleDelete}
          disableBackdrop
        >
          <MDBRow center>
            <MDBModalHeader>
              <span className="title-span" >
                ATENÇÃO!
              </span>
            </MDBModalHeader>
          </MDBRow>

          <MDBModalBody>
            <MDBRow center>
                <span style={{color:'black', fontWeight: 'bolder'}}>
                  Você deseja excluir esse produto?
                </span>
              </MDBRow>
              <MDBRow className="mt-4" center>
                  <span>
                    Nome do Produto: {produto.nome}
                  </span>
              </MDBRow>

              <MDBRow className="mt-4" center>
                  <span>
                    Descrição do modelo: {produto.descr_modelo}
                  </span>
              </MDBRow>
          </MDBModalBody>

          <MDBRow end style={{marginRight: '0px'}}>
            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleDelete}>Cancelar</Button>
            </MDBModalFooter>

            <MDBModalFooter>
              <Button variant="contained" style={{backgroundColor: 'red'}} onClick={() => {handleDelete(produto.id)}}>Apagar</Button>
            </MDBModalFooter>
          </MDBRow>
        </MDBModal>             

      ):(null)}

      <CardHeader 
        className="header-table" 
        style={{ backgroundColor: '#B19B80', color: 'white' }}>
        <h4 className="title-table">Ações de Produtos</h4>
      </CardHeader>
      <div className='actions-produto'>
        <GridItem xs={12} sm={12} md={3}>
          <Card>
            { <ModalFilterProdutoByField 
                produtosService={produtosService} 
                setProdutos={setProdutos}
                setIsLoading={setIsLoading}
                setIsViewTable={setIsViewTable}
              /> }
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <Card>
            <Button
              style={{ backgroundColor: '#F6EDD2', color: '#B19B80', fontSize: '12px', height: '55px'}}
              variant="contained"
              onClick={() => {
                GetLastPrincipalComponents(setProdutos, setError, produtosService, setIsLoading, setIsViewTable)
              }}
            >
              Carregar Produtos
              <AiOutlineFileSync 
                  style={{ height: '40px', width: '40px', paddingLeft: '20px'}}
              />
            </Button>
          </Card>
        </GridItem>
      </div>

      {isLoading ? (
        <MDBRow center>
          <ReactLoading  type="bars" color="black" height={400} width={150}/>   
        </MDBRow>
      ):(null)}
			{isViewTable ? (
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader className="header-table"  style={{ backgroundColor: '#ADC5B4', color: 'white' }}>
                <h4 className="title-table">Lista de Produtos</h4>
                <p className="p">
                    Adicione e edite os produtos.
                </p>
              </CardHeader>

              <CardBody>
                <Table
                modelos={produtos}
                handleUpdate={handleUpdate}
                toggleDelete={toggleDelete}
                setModelo={setProduto}
                produtosService={produtosService}
                getRevestimentos={getRevestimentos}
                />
                <Button 
                  variant="contained"
                  style={{marginTop: '-80px'}}
                  onClick={() => {
                  setIsUpdate(false)
                  clearState()
                  toggle()}}>
                  Adicionar Produto
                </Button>
              </CardBody>
              
            </Card>

          </GridItem>
        </GridContainer>
      ):(null)}
  </div>
       
  )
}