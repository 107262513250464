const dev = {
    baseUrl: 'http://localhost:8000/api/'
}

const prod = {
    baseUrl: 'https://sistema.perspectivaconsultoria.com/api/'
}

const config = process.env.NODE_ENV === 'development'
    ? dev : prod;

export default config;