import React, { useState, useEffect } from "react";
import { 
	MDBModal, 
	MDBRow ,
	MDBModalHeader,
	MDBModalBody,
	MDBModalFooter
} from "mdbreact";
import GridItem from '../Grid/GridItem';
import GridContainer from '../Grid/GridContainer';
import { Button } from '@mui/material';
import PDFFile from '../GeneratePDF';
import { useAuthDataContext } from '../../services/auth/AuthContext';
import { PDFDownloadLink, BlobProvider, PDFViewer, render } from '@react-pdf/renderer'
// import { MdFileDownload } from "react-icons/md";

import './styles.css'

export default function CreatePDFs(props){
	const { pedido, itens, togglePedido, pedidosService, hasCondPgto } = props;
	const { userGroup } = useAuthDataContext()
	const [modalPDF, setModalPDF] = useState(false)
	const togglePDF = () => { 
    setModalPDF(!modalPDF) 
  }

	const [viewPdfIntegral, setViewPdfIntegral] = useState(false)
	const toggleViewPdfIntegral = () => { 
    setViewPdfIntegral(!viewPdfIntegral) 
  }

	const [itensIntegral, setItensIntegral] = useState([])
	const [valorIntegral, setValorIntegral] = useState(0.0)
	const [valorDescontoIntegral, setValorDescontoIntegral] = useState(0.0)
	const [valorIpiIntegral, setValorIpiIntegral] = useState(0.0)
	const [valorFinalIntegralSeparado, setValorFinalIntegralSeparado] = useState(0.0)
	
	const [viewPdfLiquido, setViewPdfLiquido] = useState(false)
	const toggleViewPdfLiquido = () => { 
    setViewPdfLiquido(!viewPdfLiquido) 
  }

	const [itensLiquido, setItensLiquido] = useState([])
	const [valorLiquido, setValorLiquido] = useState(0.0)
	const [valorDescontoLiquido, setValorDescontoLiquido] = useState(0.0)
	const [valorIpiLiquido, setValorIpiLiquido] = useState(0.0)
	const [valorFinalLiquidoSeparado, setValorFinalLiquidoSeparado] = useState(0.0)


	function roundNumber (rnum) {
    return Math.round(rnum*Math.pow(10,2))/Math.pow(10,2);
  }

	function formatItens (itens) {
		// Funcao que formata itens, separando em duas listas (integral/liquida)
		var integral = []
		var valorIntegral = 0

		var liquido = []
		var valorLiquido = 0

		for (let i=0; i<itens.length; i++) {
			if (itens[i].isMecanismo === true ||
				itens[i].nomeRevestimento === "PROMO"
			)	{
				liquido.push(itens[i])
				valorLiquido += roundNumber(itens[i].valor * itens[i].quantidade)
			} else {
				integral.push(itens[i])
				valorIntegral += roundNumber(itens[i].valor * itens[i].quantidade)
			}
		}

		if (integral.length > 0) {
			// Se existirem itens integral, seta as informacoes de valores
			setItensIntegral(integral)
			setValorIntegral(valorIntegral)
			calculateDescontoAndIpi(valorIntegral, pedido.cond_pgto.desconto_comercial, pedido.ipi, pedido.percentual_ipi, "integral")
		}
		if (liquido.length > 0) {
			// Se existirem itens liquido, seta as informacoes de valores
			setItensLiquido(liquido)
			setValorLiquido(valorLiquido)

			calculateDescontoAndIpi(valorLiquido, pedido.cond_pgto.desconto_comercial, pedido.ipi, pedido.percentual_ipi, "liquido")
		}
	}

	const calculateDescontoAndIpi = (valor, desconto, ipi, percIpi, tipo) => {
		var descontoReal = 0.0 
		if (tipo==="integral") {
			// Valor desconto em percentual
			var descontoValueWithoutPerc = desconto.replace('%', '')
			var descontoPerc = descontoValueWithoutPerc/=100

			descontoReal = valor * descontoPerc
			descontoReal = descontoReal.toFixed(2)
		}
		
		var valorIpi = 0.0
		if (ipi=='sim') {
			var formatPercIpi = 0.0

			var getPercIpi = percIpi
			. replace('%', ' ')

			formatPercIpi = getPercIpi/=100  
			if (tipo==="integral") {
				var valorCalculoIpi = roundNumber(valor) - roundNumber(descontoReal)
				valorIpi = roundNumber(valorCalculoIpi * formatPercIpi)
				valorIpi = valorIpi.toFixed(2)
				setValorDescontoIntegral(descontoReal)
				setValorIpiIntegral(valorIpi)
				setValorFinalIntegralSeparado(roundNumber(roundNumber(valor) - roundNumber(descontoReal) + roundNumber(valorIpi)) )
			}

			if (tipo==="liquido") {
				setValorDescontoLiquido(descontoReal)
				valorIpi = roundNumber(valor * formatPercIpi)
				setValorIpiLiquido(valorIpi)
				setValorFinalLiquidoSeparado(roundNumber(roundNumber(valor) - roundNumber(descontoReal) + roundNumber(valorIpi)) )
			}
		}		
	}

	function createAnexo (blob, tipo, valor) {
		let nameDocument = "Pedido "+tipo+": "+pedido.n_pedido+" - OC "+pedido.oc_cliente+".pdf"
		if (pedido.status == 0) {
			nameDocument = "Orçamento "+tipo+": "+pedido.n_pedido+" - OC "+pedido.oc_cliente+".pdf"
		}
		let form_data = new FormData()
		form_data.append('nPedido', pedido.n_pedido)
		form_data.append('dataPedido', pedido.data_pedido)
		form_data.append('ocCliente', pedido.oc_cliente)
		form_data.append('valorFinal', valor)
		form_data.append('tipo', tipo)
		form_data.append('filenamePedido', nameDocument)
    form_data.append('urlPedido', blob)
		try {
			pedidosService.addAnexo(pedido.id, form_data)
			.then(res => {
				if (res.status === 200) {
					alert("Anexo "+tipo+" criado com sucesso!!") 
				}
			})
		}
		catch(error) {
			console.log(error)
      alert("Ocorreu algum erro, verifique e tente novamente!!")
    }    
	}

	return (
		<>
			<MDBModal
        isOpen={modalPDF}
				size="lg"
        toggle={togglePDF}
        disableBackdrop
      > 
      <MDBRow center>
	  {userGroup == 'Orcamento' ? (
		 <MDBModalHeader className="modal-header">Adicione PDF's do Orçamento</MDBModalHeader>
		):(
		 <MDBModalHeader className="modal-header">Adicione PDF's do Pedido</MDBModalHeader>
	  )}
       
      </MDBRow>
			<MDBModalBody>
				{itensIntegral.length > 0 ? (
					<div className="info-form">
						<GridItem center 
							xs={12} sm={12} md={12}
							className="title-subinfo"
							style={{ 
							backgroundColor: '#ADC5B4', 
							color: 'white', 
							fontSize: '22px', 
							marginLeft: '0', 
							marginRight: '0', 
							fontWeight: '600', 
							marginBottom: '10px',
							marginTop: '10px'
							}}>
							
								PDF INTEGRAL
								
						</GridItem>
						<MDBRow end style={{color:'black', fontWeight: 'bolder', marginBottom: '30px'}}>
							<MDBModalFooter>
								<Button variant="contained" 
									color="secondary"
									onClick={() => {
										toggleViewPdfIntegral()
									}} >
										{viewPdfIntegral ? (<>Fechar PDF Integral</>):(<>Ver PDF Integral</>)}
										
								</Button>
							</MDBModalFooter>
							<MDBModalFooter>
								<BlobProvider document={<PDFFile
								pedido={pedido} 
								itens={itensIntegral}
								valor={valorIntegral}
								valorDesconto={valorDescontoIntegral}
								valorIpi={valorIpiIntegral}
								valorFinalSeparado={valorFinalIntegralSeparado}
								tipo="integral"
								userGroup={userGroup}
									/>}>
									{({ blob, url, loading, error }) => {
										return(
											<Button variant="contained" 
												disabled={loading ? (true):(false)}
												onClick={() => {
													createAnexo(blob, "Integral", valorFinalIntegralSeparado)
												}} >
													Criar PDF Integral
											</Button>
										)
									}}
								</BlobProvider> 
							</MDBModalFooter>       
						</MDBRow>
					<MDBRow>

					{viewPdfIntegral ? (
						<PDFViewer width={700} height={600} showToolbar={false} >
							<PDFFile 
							pedido={pedido} 
							itens={itensIntegral}
							valor={valorIntegral}
							valorDesconto={valorDescontoIntegral}
							valorIpi={valorIpiIntegral}
							valorFinalSeparado={valorFinalIntegralSeparado}
							tipo="integral"
							userGroup={userGroup}
							/>
						</PDFViewer>
					):(null)}
					</MDBRow>
				</div>
			):(null)}
			{itensLiquido.length > 0 ? (
				<div className="info-form">
					<GridItem center 
							xs={12} sm={12} md={12}
						className="title-subinfo"
						style={{ 
						backgroundColor: '#ADC5B4', 
						color: 'white', 
						fontSize: '22px', 
						marginLeft: '0', 
						marginRight: '0', 
						fontWeight: '600', 
						marginBottom: '10px',
						marginTop: '10px'
						}}>
				
							PDF LIQUIDO
						
					</GridItem>
						
							<MDBRow end style={{color:'black', fontWeight: 'bolder', marginBottom: '30px'}}>
							<MDBModalFooter>
								<Button variant="contained" 
									color="secondary"
									onClick={() => {
										toggleViewPdfLiquido()
									}} >
										{viewPdfLiquido ? (<>Fechar PDF Liquido</>):(<>Ver PDF Liquido</>)}
										
								</Button>
							</MDBModalFooter>
							<MDBModalFooter>
								<BlobProvider document={<PDFFile
								pedido={pedido} 
								itens={itensLiquido}
								valor={valorLiquido}
								valorDesconto={valorDescontoLiquido}
								valorIpi={valorIpiLiquido}
								valorFinalSeparado={valorFinalLiquidoSeparado}
								tipo="liquido"
								userGroup={userGroup}
								/>}>
								{({ blob, url, loading, error }) => {
									return(
										<Button variant="contained" 
											disabled={loading ? (true):(false)}
											onClick={() => {
												createAnexo(blob, "Liquido", valorFinalLiquidoSeparado)
											}} >
												Criar PDF Liquido
										</Button>
									)
								}}
							</BlobProvider>
							</MDBModalFooter>
							</MDBRow>
					
						<MDBRow>
						{viewPdfLiquido ? (
							<PDFViewer width={700} height={600} showToolbar={false} >
								<PDFFile 
								pedido={pedido} 
								itens={itensLiquido}
								valor={valorLiquido}
								valorDesconto={valorDescontoLiquido}
								valorIpi={valorIpiLiquido}
								valorFinalSeparado={valorFinalLiquidoSeparado}
								tipo="liquido"
								userGroup={userGroup}
								/>
							</PDFViewer>
						):(null)}
					</MDBRow>
      	</div>
			):(null)}
			</MDBModalBody>

      <MDBRow end style={{marginRight: '0px'}}>
          <MDBModalFooter>
            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={togglePDF}>Fechar</Button>
          </MDBModalFooter>
      </MDBRow>
    </MDBModal>

			<Button variant="contained"
				disabled={hasCondPgto ? (false):(true)}
				onClick={() => {
					formatItens(itens)
					togglePDF()	
				}}>
				GERAR PDFS's
			</Button> 
		</>
	)
}