import axiosInstance from '../auth/AuthUserService.js'

export default class ClientesService {

  getAll() {
    const url = '/clientes/listar';
    return axiosInstance.get(url);
  }

  getLast() {
    const url = '/clientes/listar/last';
    return axiosInstance.get(url);
  }

  getByField(data) {
    const url = '/clientes/filter/by/field';
    return axiosInstance.post(url, data);
  }

  get(id) {
    const url = `/clientes/${id}`;
    return axiosInstance.get(url);
  }

  create(data) {
    const url = '/clientes/adicionar';
    return axiosInstance.post(url, data);
  }

  update(id, data) {
    const url = `/clientes/atualizar/${id}`;
    return axiosInstance.put(url, data);
  }

  delete(id) {
    const url = `/clientes/excluir/${id}`;
    return axiosInstance.delete(url);
  }

  getCondPgtoByCliente(id) {
    const url = `/clientes/cond-pgto/listar/${id}`;
    return axiosInstance.get(url)
  }

  createCondPgto(id, data) {
    const url = `/clientes/cond-pgto/adicionar/${id}`;
    return axiosInstance.post(url, data)
  }

  deleteCondPgto(id) {
    const url = `/clientes/cond-pgto/excluir/${id}`;
    return axiosInstance.delete(url)
  }

}