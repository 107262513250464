import React, { useState, useEffect } from "react";

import { MDBContainer, MDBInput, MDBIcon, MDBCard } from "mdbreact";
import Link from "@material-ui/core/Link";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Button, Grid, Paper } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UserService from '../../services/user/User';
import Navbar from '../../components/Navbar/Navbar';
import { useAuthDataContext } from '../../services/auth/AuthContext.js';

import "./LoginScreen.css";

const userService = new UserService();
const eye = <FontAwesomeIcon icon={faEye} />;

export default function LoginScreen(props) {

  const { getSession } = useAuthDataContext();

  const history = props.history;

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});

  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false: true);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const user = {
        username: username,
        password: password,
      };

      const res = await userService.login(user);

      if (res.status === 200) {
        if (history.location.state !== undefined) {
          history.push(history.location.state.from)
        } else { history.push('/dashboard') }
      } else { console.log('set errors'); setErrors(res)}
    } catch (error) {
      console.log(error);
      setErrors(error);
    }
  };

  useEffect(() => {
    getSession();

  }, []);

  return (
    <div className="div-fundo">

      {/* <Navbar /> */}

      <MDBContainer className="container">
        <MDBCard>
          <Grid container component="main" className="root" > 

            {/* <CssBaseline /> */}

            {/* <Grid item xs={false} sm={4} md={7} className="image" /> */}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              component={Paper}
              elevation={6}
              square 
              >
                <div className="paper">
                  
                  <Avatar className="avatar">
                    <LockOutlinedIcon />
                  </Avatar>

                  <Typography component="h1" variant="h5" style={{color: 'black'}}>
                    Entrar
                  </Typography>

                  <div className="white-text row">
                    <MDBIcon style={{color: 'black'}} icon="user-alt" size="2x" className="pt-4 pr-3" />
                    <MDBInput 
                      variant="outlined"
                      margin="normal"
                      required 
                      fullWidth
                      id="username"
                      label="Usuário"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      autoComplete="username"
                      autoFocus
                    />
                  </div>

                  <div className="white-text row">
                    <MDBIcon style={{color: 'black'}} icon="key" size="2x" className="pt-4 pr-3" />

                    <div>
                      <MDBInput
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="password"
                        label="Senha"
                        value={password}
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        type={passwordShown ? "text" : "password"}
                        autoComplete="current-password"
                      />

                      <i className="errspan" onClick={togglePasswordVisiblity} style={{color: 'black'}}>{eye}</i>
                    </div>
                  </div>

                  <Button
                    className="submit"
                    type="button"
                    fullWidthvariant="contained"
                    variant="contained"
                    style={{backgroundColor: "#00c851", color: "white"}}     
                    onClick={handleSubmit}                    
                  >                  
                    Entrar
                  </Button>

                  {/* <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2">
                        Esqueceu sua senha?
                      </Link>
                    </Grid>
                  </Grid> */}

                </div>
            </Grid>
          </Grid> 
          
        </MDBCard>
      </MDBContainer>
    </div>
  );
}