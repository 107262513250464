import React, { useEffect, useState } from 'react';
import { MDBDataTableV5, MDBIcon } from 'mdbreact';

import { getCorp } from '../Formatter/clientes';

import './TableStyles.css'

export default function Table(props) {

  // Definindo modelo que virá do component Read de cada modelo:
  const { modelos, handleUpdate, toggleDelete, setModelo } = props;
 
  // Definindo linhas e colunas para a tabela:  
  const [columns, setColumns] = useState('');
  const [rows, setRows] = useState('');
  
  // Definindo map para se obter as colunas referentes a tabela:
  const getColumns = () => {
    var dataHeader = 
    [ 
      {
        label: 'ID',
        field: 'id',
        width: 150,
        attributes: {
          'aria-controls': 'DataTable',
          'aria-label': 'ID',
        },
      },
      {
        label: 'CNPJ',
        field: 'cnpj',
        width: 200,
      },
      {
        label: 'Corp?',
        field: 'corp',
        width: 50,
      },
      {
        label: 'Razão Social',
        field: 'razaoSocial',
        width: 100,
      },
      {
        label: 'Nome fantasia',
        field: 'nomeFantasia',
        width: 100,
      },
      {
        label: 'IE',
        field: 'ie',
        width: 150,
      },
      {
        label: "Ações",
        field: 'acoes',
        width: 150,
      }
    ]
    setColumns(dataHeader)     
  }    
    
  // Definindo map para se obter as linhas referentes a tabela:
  const getRows = () => {

    let dataBody = modelos.map((modelo, key)=>{      
      return {
        id: modelo.id,
        cnpj: modelo.cnpj,
        corp: getCorp(modelo.is_corp),
        razaoSocial: modelo.razao_social,
        nomeFantasia: modelo.nome_fantasia,
        ie: modelo.inscricao_estadual,
        acoes:<div key={key}>
                <div className="acoes">
                  <MDBIcon onClick={() =>
                  {handleUpdate(modelo)}} 
                  icon="pen"/> 
                </div> 

                <div className="acoes">
                  <MDBIcon onClick={() => {
                    toggleDelete() 
                    setModelo(modelo)
                  }} 
                  icon="trash"/>
                </div>     
               </div>         
      }
      
    })
    setRows(dataBody)
    }
  
  // Definindo useEffect para se obter as informações referentes a tabela,
  //    sejam linhas ou colunas, e que tem como dependência o modelo referenciado
  //    em props;
  useEffect(() => {
    getColumns();
    getRows();        
  }, [modelos]);

  // Retornando tabela
  return (
    <MDBDataTableV5 responsive
      hover
      entriesOptions={[5, 20, 25]}
      entries={5}
      pagesAmount={4}
      data={{ columns: columns, rows: rows }}
      pagingTop
      searchTop
      searchBottom={false}
      barReverse
    />
  );
}