import React from 'react';

// Elemento Read:
import CrudPedidosEspecial from '../../../components/Permissoes/Especial';

export default function PedidosEspecial() {
  return (
    <div>
      <CrudPedidosEspecial />
    </div>
  )
};