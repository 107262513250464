import axiosInstance from '../auth/AuthUserService.js'

export default class RevestimentosService {

  getForIdProdutos(data) {
    const url = 'revestimentos/by-produtos/listar';
    return axiosInstance.post(url, data);
  }

  create(data) {
    const url = 'revestimentos/adicionar';
    return axiosInstance.post(url, data);
  }

}