import React, { useState, useEffect } from 'react';
import { 
    MDBModal,
    MDBRow,
    MDBModalBody,
    MDBModalFooter
} from 'mdbreact';
import { MdPrint } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa6";

import { Button, CircularProgress, TextField } from '@mui/material';
import GridItem from '../../Grid/GridItem';
import { PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer';
import ReportPDFFile from '../../GeneratePDF/Report/';
import PedidosService from '../../../services/pedidos/pedidos';
import '../styles.css';

export default function ReportOrderPrint() {
    const pedidosService = new PedidosService();

    // Variáveis para comunicação com pedido:
    const [error, setError] = useState('');
    const [pedidos, setPedidos] = useState([]);
    const [modalReportViewPDF, setReportViewPDF] = useState(false);
    const [loaded, setLoaded] = useState(false); // Estado para controlar se os pedidos estão carregados
    const [pdfDocument, setPdfDocument] = useState(null); // Estado para armazenar o PDF gerado

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const toggleReportViewPDF = () => { 
        setReportViewPDF(!modalReportViewPDF);
    }

    const GetPedidosForReport = () => {
        document.getElementById('viewer-document').style.display = '';
        pedidosService.getAll(startDate, endDate)
        .then(response => {
            if (response.status === 200) {
                setPedidos(response.data);
                setLoaded(true); // Marca os pedidos como carregados
            } else {
                setError('Erro ao listar pedidos!');
            }
        })
        .catch(error => {
            console.log(error);
            setError('Erro ao buscar pedidos!');
        });
    }

    useEffect(() => {
        if (loaded && !pdfDocument) {
            const generatePdf = async () => {
                const doc = <ReportPDFFile pedidos={pedidos} />;
                const asPdf = pdf([]);
                asPdf.updateContainer(doc);
                const blob = await asPdf.toBlob();
                setPdfDocument(blob);
            };

            generatePdf();
        }
    }, [loaded, pedidos, pdfDocument]);

    return (
        <div>   
            {/* MODAL DE VISUALIZAÇÃO DO RELATÓRIO */}
            <MDBModal
                isOpen={modalReportViewPDF}
                size="lg"
                toggle={toggleReportViewPDF}
                disableBackdrop
            >   
                       
                <MDBRow center style={{display: 'flex', flexDirection: 'column', width: '100%'}}>

                 {/* Inputs de Data */}
                   

                    <GridItem center 
                        className="title-subinfo"
                        style={{ 
                            backgroundColor: '#ADC5B4', 
                            color: 'white', 
                            fontSize: '22px', 
                            marginLeft: '0', 
                            marginRight: '0', 
                            fontWeight: '600', 
                            marginBottom: '10px',
                            marginTop: '10px',
                            height: '35px',
                            width: '95%',
                            marginLeft: '5%',
                        }}>
                        Relatório de Pedidos
                    </GridItem>
                    <MDBModalBody>
                        <GridItem style={{ marginBottom: '20px' }}>
                            <TextField
                                label="Data Inicial"
                                type="date"
                                value={startDate}
                                onChange={(e) => setStartDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField
                                label="Data Final"
                                type="date"
                                value={endDate}
                                onChange={(e) => setEndDate(e.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ marginLeft: '20px' }}
                            />
                            <Button variant="contained" onClick={GetPedidosForReport} style={{ marginLeft: '20px' }}>
                                Filtrar Pedidos
                            </Button>
                        </GridItem>
                        <GridItem id='viewer-document' style={{display: 'none'}}>
                        {loaded && pdfDocument ? (
                            <PDFViewer width={700} height={600} showToolbar={false}>
                                <ReportPDFFile pedidos={pedidos} />
                            </PDFViewer>
                        ) : (
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                                <CircularProgress color="primary" />
                            </div>
                        )}
                        </GridItem>
                        
                    </MDBModalBody>
                    <MDBRow end style={{marginRight: '0px'}}>
                        <MDBModalFooter>
                            {loaded ? (
                            <PDFDownloadLink document={<ReportPDFFile pedidos={pedidos} />} 
                                fileName="relatorio_pedidos.pdf">
                                {({ loading }) =>
                                    loading ? 
                                    <CircularProgress color="inherit" size={20} /> : 
                                    (<Button 
                                        style={{ backgroundColor: '#F6EDD2', color: '#B19B80', fontSize: '12px'}}
                                        variant="contained"
                                    >
                                        <MdPrint style={{ height: '25px', width: '40px', paddingLeft: '0px' }} />
                                    </Button>
                                    )
                                }
                            </PDFDownloadLink>
                            ):(null)}
                        </MDBModalFooter>                       

                        <MDBModalFooter>
                            <Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleReportViewPDF}>Fechar</Button>
                        </MDBModalFooter>
                    </MDBRow>
                </MDBRow>
            </MDBModal>
             
            <FaRegFilePdf 
                title='Relatório de Pedidos'
                className="btn-manage"
                onClick={() => {
                    toggleReportViewPDF();
                }}
                style={{ height: '20px', color: '#F6EDD2', width: '20px', marginLeft: '20px'}}
            />
        </div>
    )
}
