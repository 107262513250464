import React, { useState, useEffect } from 'react';
import { 
    MDBModal,
    MDBRow,
    MDBModalBody,
    MDBModalHeader,
    MDBInput,
    MDBModalFooter
} from 'mdbreact';
import Select from 'react-select';


import Card from '../../../Card/Card';

import { BiSolidAddToQueue } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import { Button } from '@mui/material'
import GridItem from '../../../Grid/GridItem';
import GridContainer from '../../../Grid/GridContainer';
import NativeSelect from '@mui/material/NativeSelect';

import { useAuthDataContext } from '../../../../services/auth/AuthContext';

import { GetAll } from '../../../GetAll';

import ConsultoresService from '../../../../services/consultores/consultores';

import Masks from "../../../Masks/Masks";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    // Estilos para o controle (caixa de seleção)
    color: 'black',
    backgroundColor: 'white',
  }),
  menu: (provided, state) => ({
    ...provided,
    // Estilos para o menu (dropdown)
    color: 'black',
		fontSize: '12px',
		fontWeight: '400',
    backgroundColor: '#fff', // Fundo branco para o menu
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black', // Texto branco se selecionado, preto se não
    backgroundColor: state.isSelected ? '#000' : '#fff', // Fundo preto se selecionado, branco se não
    '&:hover': {
      backgroundColor: '#f1f1f1', // Fundo cinza claro ao passar o mouse
			fontSize: '12px',
			fontWeight: '400'
    },
  }),
	multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#B19B80',
    color: '#fff',
  }),
	multiValueLabel: (provided) => ({
    ...provided,
    color: 'white', // Cor do texto dos itens selecionados
		fontSize: '12px',
		fontWeight: '400'
  }),
};

export default function ModalAddConsultorPedido(props) {
	// Dados retornados para o pedido
	const { 
		listaConsultores,
		setListaConsultores,
		isUpdate,
		quantidade,
		setQuantidade,
		idConsultores
	} = props;


	const mask = new Masks()

	// Services
	const consultoresService = new ConsultoresService()
	// User
	const { user, userGroup, user_id } = useAuthDataContext()
	// Errors	, 
	const [error, setError] = useState('')

	// Variáveis de controle do Modal
	const [modal, setModal] = useState(false);
	const toggleConsultor = () => { 
			setModal(!modal) 
	}

	useEffect(() => {
    const consultoresFormat = listaConsultores.map(consultor => ({
      value: consultor.value,
      label: consultor.label,
      email: consultor.email,
      isFixed: consultor.isFixed || false,
      isSelected: true
    }));
    setSelectedOptions(consultoresFormat);
  }, [listaConsultores]);

	// Campos de clientes
	const [consultor, setConsultor] = useState('')
	const [consultores, setConsultores] = useState([])
	// Determina opcoes de consultores
	const showConsultores = consultores.map((data, idx) => {  
		let isFixed = false
	
		if ( userGroup == 'Orcamento') {
			if (idx == 0) {
				isFixed = true
			}
			
		}
    return (
			{
				value: data.id, 
				label: data.nome,
				email: data.email,
				isFixed: isFixed,
			}
    )
  })

	
    // Valida os campos antes de permitir o filtro
    const checkValues = () => {
			if (
				quantidade <= 0 ||
				listaConsultores.length != quantidade ||
				listaConsultores.length <= 0
			) {
					return true
			} else {
					return false
			}
    }

		const getConsultores = () => {

			if (user.is_superuser) {
				GetAll(setConsultores, setError, consultoresService)
			}
			if (userGroup == 'Orcamento') {
	
				consultoresService.getConsultorByUser(user)
					.then(response => {
						if (response.status === 200) {
							 	const { default: defaultConsultor, others } = response.data;
								setConsultores([defaultConsultor, ...others]);
								if (listaConsultores.length > 0) {
									const consultoresFormat = listaConsultores.map(consultor => ({
										value: consultor.value,
										label: consultor.label,
										email: consultor.email,
										isFixed: consultor.value === user_id ? true : false,
										isSelected: true
								}));
									setSelectedOptions(consultoresFormat);
								
								} else {	
									setSelectedOptions([{ value: defaultConsultor.id, label: defaultConsultor.nome, email: defaultConsultor.email, isFixed: true }]);
									setListaConsultores([{ value: defaultConsultor.id, label: defaultConsultor.nome, email: defaultConsultor.email, isFixed: true  }]);         
								}
					} else {
							error('Erro ao listar consultor por usuário!!')
						}
					})
					.catch(error => {
						console.log(error)
						error(error)
					})
			}
			
		}
   
    // Limpa os campos
    const clearState = () => {
		setConsultores([])
		setQuantidade('')
    }

		const [selectedOptions, setSelectedOptions] = React.useState([]);

		const handleChangeConsultor = (e, option) => {
			if (option.removedValue && option.removedValue.isFixed) return;
			if (userGroup === 'Orcamento' || option.action == 'clear') {
				getConsultores();		
			} else {
				setSelectedOptions(e);
				setListaConsultores(e);
			}
		}
    return (
			<>
				<MDBModal
					isOpen={modal}
					toggle={toggleConsultor}
					size='lg'
					disableBackdrop
				>
					<MDBModalBody>
						<GridItem center 
							className="title-subinfo"
							style={{ 
								display: 'flex',
								justifyContent: 'space-between',
								flexDirection: 'row',
								alignItems: 'center',
								backgroundColor: '#ADC5B4', 
								color: 'white', 
								fontSize: '22px', 
								marginLeft: '0', 
								marginRight: '0', 
								fontWeight: '600', 
								marginBottom: '10px',
								marginTop: '10px'
							}}>
							{userGroup == 'Orcamento' ? (
								<>
									Consultor do Orçamento 
								</>
							):(
								<>
									Consultor do Pedido
								</>
							)}
							
						</GridItem>
						<GridContainer>
						<GridItem className="grid-item" xs={12} sm={12} md={3} >
                <MDBInput 
                  label="Quantidade *" 
                  required
                  type="number"  
                  min="1"
                  step="1"
                  error="wrong" success="right"
                  value={quantidade}
                  onChange={(e) => {
										setQuantidade(mask.number(e.target.value))
										getConsultores(mask.number(e.target.value))
									}}
                />
              </GridItem>
						{consultores.length > 0 && quantidade !="" ? (
						
						
							<GridItem className="grid-item" xs={12} sm={12} md={9} >
								<Select
								isMulti
								styles={customStyles}
								value={selectedOptions}
								onChange={(e, option) => {
									handleChangeConsultor(e, option)
									
									
								}}
								options={showConsultores}
								isOptionDisabled={() => selectedOptions.length >= quantidade}
								className="basic-multi-select"
								classNamePrefix="select"
								/>
							</GridItem>
						):(null)}
						</GridContainer>
					</MDBModalBody>

					<MDBRow end style={{marginRight: '0px'}}>
						<MDBModalFooter>
							<Button variant="contained" style={{backgroundColor: 'orangered'}} onClick={toggleConsultor}>Cancelar</Button>
						</MDBModalFooter>

						<MDBModalFooter>
							<Button disabled={checkValues()} variant="contained" 
							onClick={() => {
								toggleConsultor()
							}}
							>
								{isUpdate ? (
									<>
										Alterar
									</>
								):(
									<>
										Adicionar
									</>
								)}
							</Button>
						</MDBModalFooter>
					</MDBRow>
				</MDBModal>

        
				{/* Botão para filtro */}
				{isUpdate || listaConsultores.length > 0 ? (
				<CiEdit 
					title='Adicionar Consultor'
						className="btn-manage"
						onClick={() => {
							toggleConsultor()
							clearState()
						}}
						style={{ height: '24px', color: '#F6EDD2', width: '24px' }}
				/>
				):(
					<BiSolidAddToQueue 
					title='Adicionar Consultor'
						className="btn-manage"
						onClick={() => {
							clearState()
							toggleConsultor()
							
						}}
						style={{ height: '24px', color: '#F6EDD2', width: '24px' }}
					/>
				)}
				
		</>
	)
}