import React from 'react';

// Elemento Read:
import CrudProdutos from '../../components/Produtos/index';

export default function Produtos() {
  return (
    <div>
      <CrudProdutos />
    </div>
  )
};
